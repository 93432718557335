import {isExisting, isRequiredTextOfMaxLength, isValidMeasurementCode} from "../validations";
import {MeasurementType} from "../measurement-models";
import {InitiateMeasurementFormModel} from "./initiate-measurement-data";
import {MAX_CHARACTERS_IN_TEXT} from "../utils";
import {FormValidator} from "../../tools/form/form-validator";

export function createInitiateMeasurementValidationFunctionMap(): Map<keyof InitiateMeasurementFormModel, (value: any) => boolean> {
  return new Map<keyof InitiateMeasurementFormModel, (value: any) => boolean>()
    .set("code", (value: string) => isValidMeasurementCode(value))
    .set("reasonForStudy", (value: string) => isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, value))
    .set("type", (value: MeasurementType) => isExisting(value));
}

export class InitiateMeasurementValidator extends FormValidator<InitiateMeasurementFormModel>{

  constructor(hospitalInformation: InitiateMeasurementFormModel) {
    super(hospitalInformation, createInitiateMeasurementValidationFunctionMap());
  }

}

