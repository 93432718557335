import { Injectable } from "@angular/core";
import { MeasurementType } from "../measurement-models";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";


export interface PdfTemplate {
  data: string;
}

export interface PatientFormTemplateSelector {
  language: string;
  type: MeasurementType;
  isHome: boolean;
}

@Injectable()
export class PdfDownloaderService {
  constructor(private apiClient: ApiClient) {
  }

  getPdfTemplate(patientFormTemplateSelector: PatientFormTemplateSelector): Observable<PdfTemplate> {
    return this.apiClient
      .get<PdfTemplate>("/api/measurements/form",
      {
        type: patientFormTemplateSelector.type,
        language: patientFormTemplateSelector.language,
        isHome: `${patientFormTemplateSelector.isHome}`
      });
  }
}
