export class DownloaderService {

  download(summary: string) {
    const url = window.URL.createObjectURL(new Blob([summary]));
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = "summary.json";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
