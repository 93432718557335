<!-- TODO: fix this component up, adhering to bootstrap: https://getbootstrap.com/docs/4.6/content/tables/ -->
<table *ngIf="findings" class="w-100">
  <tr>
    <td></td>
    <td>Aktiiviaika</td>
    <td>Uniaika</td>
    <td>Koko vuorokausi</td>
  </tr>
  <tr>
    <td>Syst. keskiverenpaine</td>
    <td>{{ findings.avgSystolicBP[0] }}</td>
    <td>{{ findings.avgSystolicBP[1] }}</td>
    <td>{{ findings.avgSystolicBP[2] }}</td>
  </tr>
  <tr>
    <td>Diast. keskiverenpaine</td>
    <td>{{ findings.avgDiastolicBP[0] }}</td>
    <td>{{ findings.avgDiastolicBP[1] }}</td>
    <td>{{ findings.avgDiastolicBP[2] }}</td>
  </tr>
  <tr>
    <td>Syst. vaihteluväli</td>
    <td>{{ findings.avgSystolicRange[0] }}</td>
    <td>{{ findings.avgSystolicRange[1] }}</td>
    <td>{{ findings.avgSystolicRange[2] }}</td>
  </tr>
  <tr>
    <td>Diast. vaihteluväli</td>
    <td>{{ findings.avgDiastolicRange[0] }}</td>
    <td>{{ findings.avgDiastolicRange[1] }}</td>
    <td>{{ findings.avgDiastolicRange[2] }}</td>
  </tr>
  <tr>
    <td>Syst. verenpainekuorma</td>
    <td>{{ findings.systolicBPload[0] }}</td>
    <td>{{ findings.systolicBPload[1] }}</td>
    <td>{{ findings.systolicBPload[2] }}</td>
  </tr>
  <tr>
    <td>Diast. verenpainekuorma</td>
    <td>{{ findings.diastolicBPload[0] }}</td>
    <td>{{ findings.diastolicBPload[1] }}</td>
    <td>{{ findings.diastolicBPload[2] }}</td>
  </tr>
  <tr>
    <td>Keskipulssi</td>
    <td>{{ findings.avgPuls[0] }}</td>
    <td>{{ findings.avgPuls[1] }}</td>
    <td>{{ findings.avgPuls[2] }}</td>
  </tr>
</table>
