<select
  [attr.id]="id"
  [class]="getStyle()"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  [attr.data-testid]="testId"
>
  <option *ngFor="let option of options"
          [ngValue]="option.value">{{ option.localize ? (option.text | localize) : option.text }}</option>
</select>
