<div>
  <input type="file" accept=".pdf" (change)="onFileSelected(getFirstSelectedFile($event))" />
  <div class="pt-1">
    <action-button
      *ngIf="!!file"
      class="mr-1"
      buttonLabel="reportUploadButtonConfirmLabel"
      actionInProgressLabel="reportUploadButtonConfirmInProgressLabel"
      (buttonClick)="onUploadButtonClick()"
      [actionInProgress]="uploadInProgress$ | async"
    >
    </action-button>
    <button type="button" class="btn btn-secondary" (click)="onCancelButtonClick()">
      {{ "reportUploadButtonCancelLabel" | localize }}
    </button>
  </div>
</div>
