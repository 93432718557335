import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {localizationKey} from "../../../../../i18n/i18n-model";
import {AnalysisGenerationConfig} from "../../../analysis";
import {FormFieldConfig} from "../../../../../tools/form/form-field-config";
import {SelectionGeneratedField} from "../widgets/selection-generator/selection-generated.field";
import {joinSentences} from "../join-sentences";

interface PatientStatusComments {
  readonly reasonForStudy: SelectionGeneratedField;
  readonly rhythmAffectingMedication: SelectionGeneratedField;
  readonly symptomsDuringRecording: SelectionGeneratedField;
}


@Component({
  selector: "app-patient-status-comments-generator",
  templateUrl: "./patient-status-comments-generator.component.html",
})
export class PatientStatusCommentsGeneratorComponent implements OnInit {

  patientStatusComments?: PatientStatusComments;

  @Input()
    analysisGenerationConfig?: AnalysisGenerationConfig;

  @Input()
    config?: FormFieldConfig<any>;

  @Input()
    isInitiallyManualMode = false;

  @Output()
    onGenerate = new EventEmitter<string>();

  isManualMode = false;

  isWaitingForRegenerateConfirmation = false;

  @ViewChild("templateConfirmPatientStatusCommentsRegenerate")
    templateConfirmPatientStatusCommentsRegenerate!: TemplateRef<any>;

  preview = "";

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.isManualMode = this.isInitiallyManualMode;

    const analysisGenerationConfig = this.analysisGenerationConfig!;
    this.patientStatusComments = {
      reasonForStudy: new SelectionGeneratedField(
        [
          {
            optionLabel: "generator_patientStatusComments_reasonForStudy_arrhythmiaSensations",
            generatedText: analysisGenerationConfig["patientStatusComments_reasonForStudy_arrhythmiaSensations"]
          },
          {
            optionLabel: "generator_patientStatusComments_reasonForStudy_other",
            generatedText: analysisGenerationConfig["patientStatusComments_reasonForStudy_other"]
          },
        ]
      ),
      rhythmAffectingMedication: new SelectionGeneratedField(
        [
          {
            optionLabel: "generator_patientStatusComments_rhythmAffectingMedication_yes",
            generatedText: analysisGenerationConfig["patientStatusComments_rhythmAffectingMedication_yes"]
          },
          {
            optionLabel: "generator_patientStatusComments_rhythmAffectingMedication_no",
            generatedText: analysisGenerationConfig["patientStatusComments_rhythmAffectingMedication_no"]
          }
        ]
      ),
      symptomsDuringRecording: new SelectionGeneratedField(
        [
          {
            optionLabel: "generator_patientStatusComments_symptomsDuringRecording_yes",
            generatedText: analysisGenerationConfig["patientStatusComments_symptomsDuringRecording_yes"]
          },
          {
            optionLabel: "generator_patientStatusComments_symptomsDuringRecording_no",
            generatedText: analysisGenerationConfig["patientStatusComments_symptomsDuringRecording_no"]
          },
          {
            optionLabel: "generator_patientStatusComments_symptomsDuringRecording_noDiary",
            generatedText: analysisGenerationConfig["patientStatusComments_symptomsDuringRecording_noDiary"]
          },
        ]
      )
    };
  }

  commentGenerated(commentType: keyof PatientStatusComments, generatedText: string | undefined) {
    this.patientStatusComments![commentType].text = generatedText;
    const comments = this.generateComments();
    this.preview = comments;
    this.onGenerate.emit(comments);
  }

  private generateComments(): string {

    const patientStatusComments = this.patientStatusComments!;
    const comments: (string | undefined)[] = [
      patientStatusComments.reasonForStudy.text,
      patientStatusComments.rhythmAffectingMedication.text,
      patientStatusComments.symptomsDuringRecording.text,
    ];

    return joinSentences(comments);
  }

  regenerate() {
    this.isManualMode = false;
    this.onGenerate.emit(this.generateComments());
  }

  localizationKey = localizationKey;
}
