import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalizationStrings } from "../../../i18n/i18n-model";

@Component({
  selector: "app-measurement-details-button",
  templateUrl: "./measurement-details-button.component.html",
})
export class MeasurementDetailsButtonComponent {
  @Input()
    detailsVisible = false;

  @Output() readonly buttonClickHandler = new EventEmitter<any>();

  readonly buttonLabelShowDetails: keyof LocalizationStrings = "measurementsGridButtonLabelStudyDetailsShow";
  readonly buttonLabelHideDetails: keyof LocalizationStrings = "measurementsGridButtonLabelStudyDetailsHide";

  onClicked() {
    this.detailsVisible = !this.detailsVisible;
    this.buttonClickHandler.emit();
  }
}
