import {ChartData, ChartOptions, FontSpec, LegendItem} from "chart.js";

export function createBpChartOptions(largeFonts: boolean = false): ChartOptions {

  const font: FontSpec = {
    size: largeFonts ? 24 : 12,
    weight: null,
    style: "normal",
    lineHeight: 1.2,
    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
  };

  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        type: "time",
        grid: {
          display: false,
          color: "black",
          borderColor: "black"
        },
        ticks: {
          source: "auto",
          font,
          color: "black"
        },
        time: {
          unit: "minute",
          stepSize: 120,
          tooltipFormat: "dd.MM.yy HH:mm",
          displayFormats: {
            minute: "HH:mm",
          },
          round: "minute",
        },
      },
      y: {
        title: {
          display: true,
          text: "mmHg-bpm",
          font,
          color: "black"
        },
        suggestedMin: 40,
        suggestedMax: 240,
        grid: {
          display: false,
          color: "black",
          borderColor: "black"
        },
        ticks: {
          stepSize: 20,
          font,
          color: "black"
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          filter: (legendItem: LegendItem, chartData: ChartData) => !!legendItem.text,
          font,
          color: "black"
        },
      },
    },
  };
}


