import { Component, OnDestroy } from "@angular/core";
import { ColumnApi, FilterChangedEvent, GridApi, GridReadyEvent, RowDataChangedEvent, SortChangedEvent } from "ag-grid-community";
import { fromEvent } from "rxjs";
import { SubSink } from "subsink";
import {
  DateWithCopyRendererComponent,
} from "../../tools/ag-grid-custom-components/date-with-copy-renderer/date-with-copy-renderer.component";
import { MapCellRendererComponent } from "../../tools/ag-grid-custom-components/map-cell-renderer/map-cell-renderer.component";
import { ActionLogService } from "./action-log.service";
import { ActionApiParams, ActionItem, actionLogColumnDefs, actionLogDefaultColumnDef, AgGridFilterType } from "./actions";
import {DateTime} from "luxon";

@Component({
  selector: "app-actions-log",
  templateUrl: "./action-log.component.html",
})
export class ActionLogComponent implements OnDestroy {
  defaultColDef = actionLogDefaultColumnDef;
  columnDefs = actionLogColumnDefs;
  rowData: ActionItem[] = [];
  currentPage = 1;
  totalCount = 0;
  frameworkComponents = {
    mapCellRendererComponent: MapCellRendererComponent,
    dateWithCopyRendererComponent: DateWithCopyRendererComponent,
  };

  readonly itemsPerPage = 20;
  private readonly basicQueryParams: ActionApiParams = {limit: String(this.itemsPerPage), page: "1"};

  private gridApi!: GridApi;
  private columnApi!: ColumnApi;
  private filterModel: { [key: string]: any } = {};

  private lastFocusedFloatingFilterAriaLabel: string | null = null;

  private readonly subSinkFloatingFilters = new SubSink();

  constructor(private readonly actionsLogService: ActionLogService) {  }

  ngOnDestroy(): void {
    this.subSinkFloatingFilters.unsubscribe();
  }

  goToPage(event: { page: number; itemsPerPage: number }) {
    this.basicQueryParams.page = String(event.page);
    this.fetchData();
  }

  gridReadyHandler(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.fetchData();
  }

  filterChangedHandler(params: FilterChangedEvent): void {
    this.filterModel = params.api.getFilterModel();
    this.fetchData();
  }

  sortChangedHandler(params: SortChangedEvent): void {
    this.fetchData();
  }

  rowDataChangedHandler(params: RowDataChangedEvent): void {
    // after new data arrives the ag-grid table resets its floating filters.
    // to counter that effect the stored filter model is applied after data refresh.
    this.setTableFiltersWithoutRefresh(this.filterModel, params.api);
    this.focusFloatingFilter(this.lastFocusedFloatingFilterAriaLabel);
    this.addFloatingFilterFocusListeners();
  }

  /**
   * Sets table filters without triggering table refresh.
   *
   * @param filters - the ag-grid filter model.
   */
  private setTableFiltersWithoutRefresh(filters: { [key: string]: any }, gridApi: GridApi): void {
    Object.keys(filters).forEach((key) => {
      const filterInstance = gridApi.getFilterInstance(key);
      if (filterInstance) {
        const filterType: AgGridFilterType = filters[key].filterType;
        const type: string = filters[key].type;
        const model: any = {type, filterType};
        switch (filterType) {
          case "date":
            model.dateFrom = filters[key].dateFrom || null;
            model.dateTo = filters[key].dateTo || null;
            break;
          default:
            model.filter = filters[key].filter;
        }
        filterInstance.setModel(model);
      }
    });

    gridApi.refreshHeader();
  }

  private focusFloatingFilter(ariaLabel: string | null): void {
    if (!ariaLabel) {
      return;
    }

    const filterInput: any = document?.querySelector(`input[aria-label="${ariaLabel}"]`);
    filterInput.focus();
  }

  private addFloatingFilterFocusListeners(): void {
    this.subSinkFloatingFilters.unsubscribe();
    const filterInputs: NodeListOf<Element> = this.getFloatingFilterInputs();
    for (let i = 0; i < filterInputs.length; i++) {
      this.subSinkFloatingFilters.add(
        fromEvent<FocusEvent>(filterInputs[i], "focus").subscribe((event: FocusEvent) => {
          this.lastFocusedFloatingFilterAriaLabel = (event.target as HTMLElement).getAttribute("aria-label");
        })
      );
    }
  }

  private getFloatingFilterInputs(): NodeListOf<Element> {
    return document?.querySelectorAll(".ag-header-row-floating-filter input");
  }

  private fetchData() {
    this.gridApi.showLoadingOverlay();
    this.disableFloatingFilterInputs();
    const params = {...this.basicQueryParams, ...this.getQueryParamsFromFilterModel(), ...this.getQueryParamsFromSortModel()};
    this.actionsLogService.getActions(params).subscribe({
      next: (response) => {
        this.rowData = response.actionLogEntries || [];
        this.totalCount = response.totalCount;
      },
      error: (err) => {
        console.error(err);
        this.rowData = [];
        this.totalCount = 0;
      },
    });
  }

  private disableFloatingFilterInputs(): void {
    const filterInputs: NodeListOf<Element> = this.getFloatingFilterInputs();
    for (let i = 0; i < filterInputs.length; i++) {
      filterInputs[i].setAttribute("disabled", "");
    }
  }

  private getQueryParamsFromFilterModel(): { [key: string]: string } {
    const params: { [key: string]: string } = {};
    Object.keys(this.filterModel).forEach((key) => {
      const filterType: AgGridFilterType = this.filterModel[key].filterType;
      switch (filterType) {
        case "date":
          const dateFromString: string = this.filterModel[key].dateFrom;
          const dateToString: string = this.filterModel[key].dateTo;
          const agGridDateTimeFormat = "yyyy-MM-dd hh:mm:ss";
          if (dateFromString) {
            params.timeFrom = DateTime.fromFormat(dateToString, agGridDateTimeFormat).toISO()!!;
          }
          if (dateToString) {
            params.timeTo = DateTime.fromFormat(dateFromString, agGridDateTimeFormat).toISO()!!;
          }
          break;
        default:
          params[key] = this.filterModel[key].filter;
      }
    });

    return params;
  }

  private getQueryParamsFromSortModel(): null | { sort: string } {
    const sort = this.columnApi.getColumnState()
      .filter(state => state.sort)
      .map(state => `${state.colId}:${state.sort}`).join(",");

    return sort.length == 0 ? null : {
      sort: sort
    };
  }
}
