export class FormValidator<T> {

  private validationMap: Map<keyof T, boolean> = new Map<keyof T, boolean>;

  constructor(private readonly formModel: T, private readonly validationFunctions: Map<keyof T, (value: any) => boolean>) {

  }

  updateValidation(property: keyof T) {
    if (this.validationFunctions.has(property)) {
      this.validationMap.set(property, this.validationFunctions.get(property)!(this.formModel[property]));
    }
  }

  isValid(property: keyof T) {
    return this.validationMap.get(property);
  }

  updateAllValidations() {
    this.validationFunctions.forEach((_, key) => this.updateValidation(key));
  }

  isFormValid() {
    return Array.from(this.validationFunctions.keys()).every((key) => this.validationMap.get(key) !== false);
  }

  resetValidationStatuses() {
    this.validationMap = new Map<keyof T, boolean>();
  }

}
