import {endSentenceWithDot} from "./end-sentence-with-dot";

export function joinSentences(sentences: (string | undefined)[]) {
  return sentences.every(text => text === undefined || text === "")
    ? ""
    : sentences
      .filter(text => text)
      .map(text => endSentenceWithDot(text!))
      .join(" ")
  ;
}
