import {ExternalFindings} from "../../external-findings";

export function areExternalFindingsEqual(
  externalFindings1: ExternalFindings,
  externalFindings2: ExternalFindings
) {
  const keys1 = Object.keys(externalFindings1);
  const keys2 = Object.keys(externalFindings2);

  return keys1.length === keys2.length
    && keys1.every(key => externalFindings1[key] === externalFindings2[key]);

}
