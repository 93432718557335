<div class="container">
  <loading-indicator *ngIf="!printOrderModel"></loading-indicator>
  <form class="form-striped" *ngIf="printOrderModel">

    <form-field [config]="formConfig!.type"></form-field>
    <form-field [config]="formConfig!.orderingHospital"></form-field>
    <form-field [config]="formConfig!.operatorHospital"></form-field>
    <form-field [config]="formConfig!.initiatedTime"></form-field>
    <form-field [config]="formConfig!.name"></form-field>
    <form-field [config]="formConfig!.ssn"></form-field>
    <form-field [config]="formConfig!.code"></form-field>
    <form-field [config]="formConfig!.uniqueId"></form-field>

    <div class="row">
      <action-button
        buttonLabel="printOrderFormButtonLabel"
        actionInProgressLabel="printOrderFormButtonInProgressLabel"
        (buttonClick)="onPrintButtonClick()"
        [testId]="'submit-button'">
      </action-button>

      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ml-2">
        {{ errorMessage | localize }}
      </span>
    </div>

  </form>
</div>
