import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiClient} from "../../tools/api-client";
import {Analysis, DoctorFindings} from "./analysis";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";

@Injectable()
export class AnalysisService {
  constructor(private readonly apiClient: ApiClient) {
  }

  getAnalysis(measurementId: number): Observable<Analysis> {
    return this.apiClient
      .get<Analysis>(`/api/measurements/${measurementId}/analysis`)
      .pipe(map(analysis => recursiveNullToUndefined(analysis)));
  }

  saveAnalysis(measurementId: number, doctorFindings: DoctorFindings, ecgSamplesReportFile?: File): Observable<Analysis> {
    return this.apiClient
      .put<Analysis>(
      `/api/measurements/${measurementId}/analysis`,
      createMultipartRequest(doctorFindings, ecgSamplesReportFile)
    )
      .pipe(map(analysis => recursiveNullToUndefined(analysis)));
  }

  getSamplesReport(measurementId: number, isFinishAnalysis: boolean): Observable<string> {
    return this.apiClient
      .get<SamplesReportData>(`/api/measurements/${measurementId}/samples`, {isFinishAnalysis: `${isFinishAnalysis}`})
      .pipe(
        map(samplesReportData=> samplesReportData.data)
      );
  }
}

function createMultipartRequest(ecgFindings: DoctorFindings, ecgSamplesReportFile?: File): FormData {
  const formData = new FormData();
  if (!!ecgSamplesReportFile) {
    formData.append("ecgSamplesReportFile", ecgSamplesReportFile, ecgSamplesReportFile.name);
  }
  formData.append("doctorFindings", JSON.stringify(ecgFindings));
  return formData;
}

interface SamplesReportData{
  data: string;
}
