import {Component} from "@angular/core";
import {AgRendererComponent} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {ClipboardService} from "ngx-clipboard";
import {DateTime} from "luxon";
import {ClockService} from "../../time/clock.service";

@Component({
  selector: "app-date-with-copy-renderer",
  templateUrl: "./date-with-copy-renderer.component.html",
})
export class DateWithCopyRendererComponent implements AgRendererComponent {
  formattedDate = "";
  private readonly dateFormat = "yyyy-MM-dd HH:mm";

  constructor(readonly clipboardService: ClipboardService,
    readonly clockService: ClockService) {
  }

  agInit(params: ICellRendererParams): void {
    this.formattedDate = this.formatTime(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.formattedDate = this.formatTime(params);

    return true;
  }

  private formatTime(params: ICellRendererParams) {
    return params?.value
      ? DateTime.fromISO(params?.value).toFormat(this.dateFormat)
      : this.clockService.now().toFormat(this.dateFormat);
  }
}
