<div class="container-fluid container--available-viewport-height table-responsive">
  <div class="row justify-content-sm-center mb-3">
    <h5>{{ 'backgroundInformationTitle' | localize }}</h5>
  </div>
  <div class="row justify-content-sm-center">
    <div class="col-sm-auto list-group">
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/Ea3iYBxw3lhGkBbSmG4pk1YBPzcuztuhYtgST-42WrutgQ?download=1">
        Perustietokaavake YÖPOLYGRAFIA 1.5 (FI)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EZTFRJZSWKBFq-ZXngcpnDEBxkvdQtIXgbs2FbZ-J37spg?download=1">
        Perustietokaavake OIRE-HOLTER 1.5 (FI)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EQ7bLOrJ_FpKu-Y1zKWiLpABXMqOsQ1i0vMjCzqJWl3RGg?download=1">
        Perustietokaavake HOLTER-EKG 1.5 (FI)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EfeSW7b-BYlCocVIOQ71WdEBajyuXf8OixfEDcx6XfhjSA?download=1">
        Perustietokaavake YÖPOLYGRAFIA 1.5 (SE)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EduFh3SffGZLk52IK5GZRuwBGDbMzXfIzFOQAXgSg9pdHQ?download=1">
        Perustietokaavake OIRE-HOLTER 1.5 (SE)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/ERqwePhP-89BqLxRWmqAcxUBlhhiPlqkrvnJzl0H5aKASQ?download=1">
        Perustietokaavake HOLTER-EKG 1.5 (SE)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/ERFYM9DNWY5FpstW9rWnwroBJloFuTdfAdUx27aTpK-p1A?download=1">
        Perustietokaavake YÖPOLYGRAFIA 1.5 (EN)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/ERzcObDILGNLkskdPnep6JMB3XFQgfLClPqxeLGQ0PFGpg?download=1">
        Perustietokaavake OIRE-HOLTER 1.5 (EN)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/ETI_iBMFKlJOvVvWAmXjVt0BCXb9LUonnm_LVk6fUoU7uQ?download=1">
        Perustietokaavake HOLTER-EKG 1.5 (EN)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EVb0XFCqBlJKlphLjjywfwcBsYDQIlXJ3kVB1tyw-nGpRg?e=Blx836?download=1">
        Perustietokaavake YÖPOLYGRAFIA 1.5 (RU)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/EXR_gPyWkEJAuWMatXIprJABhTf6LLzFEZK4IijjcYabCQ?e=4uCGlf?download=1">
        Perustietokaavake VERENPAINESEURANTA 1.0 (FI)
      </a>
      <a
        class="list-group-item list-group-item-action"
        target="_blank"
        href="https://stpmedicalfi-my.sharepoint.com/:b:/g/personal/m3sys_stpmedical_fi/Ee7Zdi0ayXZMo3bSU6sJ-g4BxSbnVRcA7OWdwC_TpfkeWQ?e=llVyUP?download=1">
        Perustietokaavake ETEISVÄRINÄN SEULONTA 1.5 (FI)
      </a>
    </div>
  </div>
</div>
