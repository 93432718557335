<div class="row justify-content-center">
  <div class="col-6">
    <div class="row">
      <h5>{{ 'editHospitalAllowedTypes' | localize }}</h5>
    </div>
    <div class="row mb-3">
      <span>{{ state.hospitalName }} {{ localizationKey('editHospitalAllowedTypesText') | localize }} </span>
      <hr/>
    </div>

    <div *ngIf="feature311Released" class="grid grid-striped">

      <div class="row">
        <div class="col-8">

        </div>
        <div class="col-2 text-center">
          {{ localizationKey("editHospitalAllowedTypesHeaderNonHome") | localize }}
        </div>
        <div class="col-2 text-center">
          {{ localizationKey("editHospitalAllowedTypesHeaderHome") | localize }}
        </div>
      </div>

      <div *ngFor="let type of allMeasurementTypes" class="row">
        <div class="col-8">
          {{ getMeasurementTypeLocalizationKey(type) | localize }}
        </div>
        <div class="col-2 text-center">
          <label class="w-100" [attr.for]="'allowed-type-' + type.toString()">
            <input
              [attr.id]="'allowed-type-' + type.toString()"
              type="checkbox"
              [(ngModel)]="allowedTypes[type]"
              [attr.data-testid]="'allowed-type-' + type.toString()"/>
          </label>
        </div>
        <div class="col-2 text-center">
          <label class="w-100" [attr.for]="'allowed-home-type-' + type.toString()">
            <input
              [attr.id]="'allowed-home-type-' + type.toString()"
              type="checkbox"
              [(ngModel)]="allowedHomeTypes[type]"
              [attr.data-testid]="'allowed-home-type-' + type.toString()"/>
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="!feature311Released" class="grid grid-striped">
      <div *ngFor="let type of allMeasurementTypes" class="row">
        <div class="col-12">
          <label class="w-100" [attr.for]="'allowed-type-' + type.toString()">
            <input
              [attr.id]="'allowed-type-' + type.toString()"
              type="checkbox"
              [(ngModel)]="allowedTypes[type]"
              [attr.data-testid]="'allowed-type-' + type.toString()"/>
            {{ getMeasurementTypeLocalizationKey(type) | localize }}
          </label>
        </div>
      </div>
    </div>

  </div>
  <div>
    <button class="btn btn-sm btn-primary" data-testid="submit-allowed-types"
            (click)="submitAllowedTypes()">
      {{ localizationKey('editHospitalSubmitAllowedTypes') | localize }}
    </button>
  </div>
</div>
