import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticatedUser } from "./authenticated-user";
import { AuthenticatedUserStore } from "./authenticated-user-store.service";

// TODO see if AuthenticationService can be simplified: without using subject/observable & merge with AuthenticatedUserStore
@Injectable()
export class AuthenticationService {
  private readonly authenticatedUserSubject: BehaviorSubject<AuthenticatedUser | undefined>;
  readonly authenticatedUser$: Observable<AuthenticatedUser | undefined>;

  constructor(private readonly authenticatedUserStore: AuthenticatedUserStore) {
    this.authenticatedUserSubject = new BehaviorSubject(authenticatedUserStore.get());
    this.authenticatedUser$ = this.authenticatedUserSubject.asObservable();
  }

  getCurrentAuthenticatedUser(): AuthenticatedUser | undefined {
    return this.authenticatedUserSubject.value;
  }

  setCurrentAuthenticatedUser(user: AuthenticatedUser) {
    this.authenticatedUserStore.save(user);
    this.authenticatedUserSubject.next(user);
  }

  logout() {
    this.authenticatedUserStore.delete();
    this.authenticatedUserSubject.next(undefined);
  }
}
