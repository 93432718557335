import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrintOrderData } from "./print-order-data";
import { ApiClient } from "../../tools/api-client";

@Injectable()
export class PrintOrderService {

  constructor(private readonly apiClient: ApiClient) {}

  getPrintOrderData(measurementId: number): Observable<PrintOrderData> {
    return this.apiClient.get<PrintOrderData>(`/api/measurements/${measurementId}/print-order-data`);
  }

}
