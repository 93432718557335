<div class="row" *ngIf="responsiveLayout">
  <input
    *ngIf="responsiveLayout"
    name="date"
    type="text"
    bsDatepicker
    [placeholder]="bsDatePickerConfig.dateInputFormat"
    class="form-control"
    [bsConfig]="bsDatePickerConfig"
    [(ngModel)]="date"
    (ngModelChange)="onDateTimeChanged()"
    [disabled]="disabled"
    [attr.data-testid]="dataTestId + '-date'"
    ngDefaultControl
  />
  <timepicker
    *ngIf="enableTimePicker"
    name="time"
    [showMeridian]="false"
    [showSpinners]="false"
    [mousewheel]="false"
    [(ngModel)]="time"
    (ngModelChange)="onDateTimeChanged()"
    [disabled]="disabled"
    [attr.data-testid]="dataTestId + '-time'"
    ngDefaultControl
  >
  </timepicker>
</div>

<div *ngIf="!responsiveLayout">
  <div class="form-row">
    <div class="col-auto">
      <input
        name="date"
        type="text"
        bsDatepicker
        [placeholder]="bsDatePickerConfig.dateInputFormat"
        class="form-control"
        [bsConfig]="bsDatePickerConfig"
        [(ngModel)]="date"
        (ngModelChange)="onDateTimeChanged()"
        [disabled]="disabled"
        [attr.data-testid]="dataTestId + '-date'"
        ngDefaultControl
      />
    </div>
    <div class="col-auto" *ngIf="enableTimePicker">
      <timepicker
        name="time"
        [showMeridian]="false"
        [showSpinners]="false"
        [mousewheel]="false"
        [(ngModel)]="time"
        (ngModelChange)="onDateTimeChanged()"
        [disabled]="disabled"
        [attr.data-testid]="dataTestId + '-time'"
        ngDefaultControl
      >
      </timepicker>
    </div>
  </div>
</div>
