import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";

@Injectable()
export class ChangeLanguageService {
  constructor(private apiClient: ApiClient) {}

  changeLanguage(newLanguage: string): Observable<any> {
    return this.apiClient.post<any>("/api/account/changeLanguage", newLanguage);
  }
}
