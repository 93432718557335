import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {MeasurementType} from "../measurement-models";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";


export interface PrintFormDataRequest {
  measurementId: number;
}


export interface PrintFormData {
  availableLanguages: string[];
  type: MeasurementType;
  initiatedTime: string;
  uniqueId: string;
  code: string;
  eFormCode?: string;
  isHome: boolean;
  specifiedHomeHolterDuration?: number;
}

@Injectable()
export class PrintFormService {

  constructor(private apiClient: ApiClient) {
  }

  getPrintFormData(printFormDataRequest: PrintFormDataRequest) {
    return this
      .apiClient
      .get<PrintFormData>(`/api/measurements/form/data/${printFormDataRequest.measurementId}`)
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }

}
