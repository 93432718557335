import {Component, Input} from "@angular/core";

@Component({
  selector: "eform-header",
  templateUrl: "./e-form-header.component.html",
})
export class EFormHeaderComponent{

  @Input()
  isHomeMeasurement = false;
  @Input()
  isHomeMeasurementReleased = false;

}
