import {Component, OnInit} from "@angular/core";
import {ALL_MEASUREMENT_TYPES, getMeasurementTypeLocalizationKey, MeasurementType} from "../../../measurement-models";
import {localizationKey} from "../../../../i18n/i18n-model";
import {AllowedTypesSectionState} from "./allowed-types-section.state";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {HospitalMeasurementTypesService} from "../hospital-measurement-types.service";
import {ReleaseToggleState} from "../../../../tools/release-toggles/release-toggle-state";

@Component({
  selector: "app-allowed-types-section",
  templateUrl: "./allowed-types-section.component.html",
  styleUrls: ["./allowed-types-section.component.css"]
})
export class AllowedTypesSectionComponent implements OnInit {

  allMeasurementTypes: MeasurementType[] = ALL_MEASUREMENT_TYPES.map(it => it);
  feature311Released = ReleaseToggleState.getInstance().isReleased("ENABLE_HOME_MEASUREMENT_TYPES_311");

  protected readonly localizationKey = localizationKey;
  protected readonly getMeasurementTypeLocalizationKey = getMeasurementTypeLocalizationKey;

  allowedTypes: Record<MeasurementType, boolean> = {} as Record<MeasurementType, boolean>;
  allowedHomeTypes: Record<MeasurementType, boolean> = {} as Record<MeasurementType, boolean>;

  constructor(
    readonly state: AllowedTypesSectionState,
    private readonly hospitalMeasurementTypesService: HospitalMeasurementTypesService,
    private readonly notifications: Notifications
  ) {
  }

  ngOnInit(): void {
    ALL_MEASUREMENT_TYPES
      .forEach(type => {
        this.allowedTypes[type] = this.state.allowedHospitalMeasurementTypes!.types.includes(type);
        this.allowedHomeTypes[type] = this.state.allowedHospitalMeasurementTypes!.homeTypes.includes(type);
      });
  }

  submitAllowedTypes() {
    this.hospitalMeasurementTypesService.setAllowedTypes(
      {
        hospitalId: this.state.hospitalId!,
        types:
          ALL_MEASUREMENT_TYPES
            .filter(type => this.allowedTypes[type]),
        homeTypes:
          ALL_MEASUREMENT_TYPES
            .filter(type => this.allowedHomeTypes[type]),
      }
    )
      .subscribe({
        next: () => this.notifications.addNotification(
          NotificationType.OK,
          localizationKey("editHospitalSuccessfullyUpdatedMeasurementTypes")
        ),
        error: () => this.notifications.addNotification(
          NotificationType.WARNING,
          localizationKey("editHospitalFailedToUpdateHospitalMeasurementTypes")
        )
      });
  }
}

