import { Observable } from "rxjs";
import { Hospital } from "../new-users/new-users";
import { Injectable } from "@angular/core";
import { ApiClient } from "../../tools/api-client";

@Injectable()
export class HospitalsService {

  constructor(private readonly apiClient: ApiClient) {
  }

  getHospitals(): Observable<Hospital[]>{
    return this.apiClient.get<Hospital[]>("/api/hospitals");
  }
}
