<div class="container container--available-viewport-height">
  <div class="row mt-5">
    <div class="col-4 offset-4">

      <form  (submit)="submit()">
        <div class="row">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">{{"displayLanguageLabel"|localize }}</span>
              </div>
              <app-select [inForm]="true"
                          [value]="selectedLanguage"
                          [options]="langSelectionOptions"
                          [testId]="'change-language'"
                          (valueChanged)="handleLanguageChange($event)"
              ></app-select>
            </div>
          </div>
        </div>
        <div class="row">
          <button type="submit" class="btn btn-primary" [disabled]="changeInProgress$ | async">
            {{ "changeLanguageLabel" | localize }}
          </button>
        </div>
      </form>


    </div>
    <div class="col-2"></div>
  </div>
</div>
