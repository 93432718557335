<div class="form-field"
     *ngIf="responsiveLayout"
     [attr.data-testid]="dataTestId"
     [ngClass]="{
       'form-field-is-invalid': !isSymptomValid(symptom),
       '': isSymptomValid(symptom)
     }"
>
  <div class="form-group col">
    <span class="col-form-label font-weight-bold text-muted">
      {{ "uploadFormLabelSymptom" | localize }} {{ symptomNumber }}
    </span>
    <div class="row col-12">
      <app-date-time-picker
        [data-testid]="dataTestId + '-date-time-picker'"
        class="col-auto"
        [dateTime]="symptomDateTime()"
        (dateTimeChange)="onSymptomTimeChange($event)">
      </app-date-time-picker>

      <textarea
        [attr.data-testId]="dataTestId + '-input'"
        class="form-control"
        [(ngModel)]="symptom.description"
        (ngModelChange)="onSymptomValueChange()">
      </textarea>
      <div class="w-100">
        <app-character-counter
          [data-testId]="dataTestId + '-character-counter'"
          [maxCharacters]="maxCharacters"
          [text]="symptom.description"
        ></app-character-counter>
      </div>
      <div class="w-100 form-field-invalid-feedback"
           [attr.data-testId]="dataTestId + '-warning-label'"
           *ngIf="!this.isSymptomValid(symptom)"
      >
        <span>{{ localizationKey("eformSymptomWarningLabel") | localize }}</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!responsiveLayout" [attr.data-testid]="dataTestId">
  <div class="row">
    <div class="col-6 no-gutters">
      <div class="row">
        <div class="col-auto">
          <span
            class="form-control-plaintext font-weight-bold text-muted">
            {{ "uploadFormLabelSymptom" | localize }} {{ symptomNumber }}
          </span>
        </div>
        <app-date-time-picker
          [data-testid]="dataTestId + '-date-time-picker'"
          class="col-auto"
          [dateTime]="symptomDateTime()"
          (dateTimeChange)="onSymptomTimeChange($event)">
        </app-date-time-picker>
      </div>
    </div>
    <div
      class="row col-5">
      <textarea
        class="form-control"
        [(ngModel)]="symptom.description"
        (ngModelChange)="onSymptomValueChange()">
      </textarea>
      <app-character-counter
        *ngIf="showNurseCharacterCounter()"
        [data-testId]="dataTestId + '-character-counter'"
        [maxCharacters]="maxCharacters"
        [text]="symptom.description"
      ></app-character-counter>
    </div>
  </div>
</div>
