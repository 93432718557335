import { LocalizationStrings } from "../../../i18n/i18n-model";
import { FormConfigBase } from "../../../tools/form/form-config-base";
import { FormFieldConfig, PropertyChangeListener } from "../../../tools/form/form-field-config";
import { DoctorFindings } from "../analysis";

export class EcgFindingsFormConfig extends FormConfigBase<DoctorFindings> {
  constructor(
    model: DoctorFindings,
    propertyChangeListener?: PropertyChangeListener<DoctorFindings>,
    validationProvider?: (property: keyof DoctorFindings) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly patientStatusComments = this.createFindingField("patientStatusComments", "analysisLabelPatientStatusComment");
  readonly findings = this.createFindingField("findings", "analysisLabelFindings");
  readonly conclusion = this.createFindingField("conclusion", "analysisLabelConclusion");
  readonly diagnosis = this.createFindingField("diagnosis", "analysisLabelDiagnosis");

  private createFindingField(property: keyof DoctorFindings, label: keyof LocalizationStrings): FormFieldConfig<DoctorFindings> {
    return this.createField({
      property,
      label,
      type: "textarea",
      verticalLayout: true,
      invalidLabel: "analysisFormInvalidLabelRequired",
      autoexpand: true
    });
  }
}

export interface EcgSymptomFinding {
  finding?: string;
}

export class EcgSymptomFindingsFormConfig extends FormConfigBase<EcgSymptomFinding> {
  constructor(
    model: EcgSymptomFinding,
    propertyChangeListener?: PropertyChangeListener<EcgSymptomFinding>,
    validationProvider?: (property: keyof EcgSymptomFinding) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  finding(label: string): FormFieldConfig<EcgSymptomFinding> {
    return this.createField({
      property: "finding",
      type: "textarea",
      label: label as keyof LocalizationStrings,
      verticalLayout: true,
      invalidLabel: "analysisFormInvalidLabelRequired",
    });
  }
}
