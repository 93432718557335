<div class="row py-2" data-testid="measurement-item">
  <div class="col-sm" *ngIf="columnVisibility.code">
    <app-measurement-details-button
      *ngIf="canViewDetails()"
      class="mr-2"
      [detailsVisible]="item.isItemDetailsVisible"
      (buttonClickHandler)="toggleItemDetailsVisibility()"></app-measurement-details-button>
    <span data-testid="measurement-item-code">{{ item.foundMeasurement.code }}</span>
  </div>
  <div class="col-sm" data-testId="measurement-item-uniqueId"
       *ngIf="columnVisibility.uniqueId">{{ item.foundMeasurement.uniqueId }}
  </div>
  <div class="col-sm" data-testId="measurement-item-hospital"
       *ngIf="columnVisibility.hospitalName">{{ item.foundMeasurement.hospitalName }}
  </div>

  <div class="col-sm"
       *ngIf="columnVisibility.initiateTime">{{ item.foundMeasurement.initiateTime | appLocalDateTime }}
  </div>
  <div class="col-sm"
       *ngIf="columnVisibility.uploadTime">{{ item.foundMeasurement.uploadTime | appLocalDateTime }}
  </div>
  <div class="col-sm" *ngIf="columnVisibility.nurse">
    {{ item.foundMeasurement.nurse.firstName && item.foundMeasurement.nurse.lastName ? item.foundMeasurement.nurse.firstName + ' ' + item.foundMeasurement.nurse.lastName : item.foundMeasurement.nurse.email }}
  </div>
  <div class="col-sm"
       *ngIf="columnVisibility.deliveryTime">{{ item.foundMeasurement.deliveryTime | appLocalDateTime }}
  </div>
  <div
    data-testId="type-column"
    class="col-sm" *ngIf="columnVisibility.type"
  >{{ getItemTypeDisplayText() | localize }}</div>
  <div
    data-testId="status-column"
    class="col-sm" *ngIf="columnVisibility.status"
  >
    {{ statusLocalizationKeys.get(item.foundMeasurement.status)?.get(user.role) | localize }}
    <span *ngIf="canViewAsyncJobIndicator()">
          <i
            *ngIf="isJobPending()"
            data-testid="pending-job-indicator"
            class="text-warning fa fa-hourglass align-middle"
            [title]="'measurementsGridTitleJobPending' | localize"></i>
          <i
            *ngIf="isJobFailed()"
            data-testid="failed-job-indicator"
            class="text-danger fa fa-times-circle align-middle"
            [title]="'measurementsGridTitleJobFailed' | localize"></i>
          <i
            *ngIf="hasJobSucceeded()"
            data-testid="succeeded-job-indicator"
            class="text-success fa fa-check-circle align-middle"
            [title]="'measurementsGridTitleJobSucceeded' | localize"></i>
        </span>

    <app-download-report
      [item]="item"
    ></app-download-report>
    <button
      data-testId="upload-initiated-button"
      *ngIf="canUploadMeasurement()"
      class="btn btn-sm btn-primary ml-2 mr-2"
      (click)="uploadInitiated()"
      [title]="'measurementsGridButtonTitleUploadMeasurement' | localize">
      <i class="fa fa-upload"></i>
    </button>
    <button
      *ngIf="canPrintForm()"
      class="btn btn-sm btn-secondary ml-2 mr-2"
      (click)="printForm()"
      [title]="'measurementsGridButtonTitlePrintForm' | localize"
      data-testid="print-form-button"
    >
      <i class="fa fa-print"></i>
    </button>
    <button
      *ngIf="canPrintOrder()"
      class="btn btn-sm btn-secondary ml-2 mr-2"
      (click)="printOrder()"
      [title]="'measurementsGridButtonTitlePrintOrder' | localize"
      data-testid="print-order-button"
    >
      <i class="fa fa-print"></i>
    </button>
    <action-button
      *ngIf="shouldShowNurseDeleteButton()"
      buttonType="button"
      buttonClass="btn btn-sm btn-danger ml-2 mr-2"
      iconClass="fa fa-remove"
      toolTip="measurementsGridButtonLabelDeleteMeasurementTooltip"
      [actionInProgress]="item.isDeleteMeasurementInProgress"
      [testId]="'nurse-delete-measurement-button'"
      (buttonClick)="onDeleteMeasurementClick()"></action-button>
  </div>

  <div class="col-sm" *ngIf="columnVisibility.technician"
       data-testId="technician-column">{{ technicianName() }}</div>

  <div class="col-sm-2" *ngIf="columnVisibility.doctor">
    <doctor-selector
      *ngIf="isDoctorSelectable()"
      [currentlyAssignedDoctor]="item.foundMeasurement.doctor"
      [markCurrentDoctor]="shouldMarkCurrentDoctorInDoctorSelect()"
      (selectionChanged)="item.selectedDoctor = $event"
      [chainName]="item.foundMeasurement.chain?.name"
    ></doctor-selector>
    <span
      *ngIf="shouldDisplayAssignedLabel()"> {{ item.foundMeasurement.doctor?.firstName }} {{ item.foundMeasurement.doctor?.lastName }} </span>
  </div>

  <div class="col-sm" *ngIf="columnVisibility.deviceSerialNumber">{{ item.foundMeasurement.deviceSerialNumber }}</div>

  <div class="col-sm" *ngIf="columnVisibility.reportLanguage">
          <span *ngIf="item.foundMeasurement.reportLanguage"
                data-testId="report-language">{{ languageFlags[item.foundMeasurement.reportLanguage] }}</span>
  </div>

  <div class="col-sm-2" *ngIf="columnVisibility.action">
    <app-list-item-action-buttons
      [item]="item"
      (measurementChanged)="emitMeasurementChanged()"
      (deleteMeasurementClicked)="onDeleteMeasurementClick()"></app-list-item-action-buttons>

  </div>

  <div class="col-12" *ngIf="item.isItemDetailsVisible">
    <app-measurement-details
      [measurement]="item.foundMeasurement"
      (measurementChanged)="onMeasurementEdited($event)"
    ></app-measurement-details>
  </div>
</div>

<ng-template #templateConfirmDeleteMeasurement>
  <div class="modal-body text-center">
    <p>
      {{ generateDeleteMeasurementConfirmMessage() }}
    </p>
    <button type="button" class="btn btn-default" (click)="deleteMeasurement()"
            data-testid="delete-measurement-dialog-confirm-button">
      {{ "modalResponseYes" | localize }}
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRefDeleteMeasurementConfirm?.hide()">
      {{ "modalResponseNo" | localize }}
    </button>
  </div>
</ng-template>

