import {Component, ElementRef, ViewChild} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {finalize} from "rxjs/operators";
import {I18nService} from "../../i18n/i18n.service";
import {ErrorCode, ErrorResponse} from "../../tools/error-response";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {ChangePasswordService} from "./change-password.service";
import {FormConfigBase} from "../../tools/form/form-config-base";
import {PropertyChangeListener} from "../../tools/form/form-field-config";
import {FormValidator} from "../../tools/form/form-validator";
import {ChangePasswordValidator} from "./change-password.validator";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent implements PropertyChangeListener<ChangePasswordModel> {

  @ViewChild("retypedPasswordInput") retypedPasswordInput!: ElementRef;

  model: ChangePasswordModel = emptyChangePasswordModel();

  private validator: FormValidator<ChangePasswordModel> = new ChangePasswordValidator(this.model);
  formConfig: ChangePasswordFormConfig = new ChangePasswordFormConfig(
    this.model,
    this,
    (key) => this.validator.isValid(key),
  );

  private readonly changeInProgress = new Subject<boolean>();
  readonly changeInProgress$: Observable<boolean> = this.changeInProgress.asObservable();

  constructor(
    private changePasswordService: ChangePasswordService,
    private notifications: Notifications,
    private i18Service: I18nService
  ) {
  }

  onPropertyChange(property: keyof ChangePasswordModel, newValue: any): void {
    this.model[property] = newValue;
    this.validator.updateValidation(property);

    if (property === "newPassword") {
      this.validator.updateValidation("retypedPassword");
    }
  }

  submit(): void {

    this.validator.updateAllValidations();
    if (!this.validator.isFormValid()) {
      return;
    }

    const oldPassword = this.model.oldPassword.trim();

    this.notifications.clearAll();
    this.changeInProgress.next(true);
    this.changePasswordService
      .changePassword(oldPassword, this.model.newPassword)
      .pipe(finalize(() => this.changeInProgress.next(false)))
      .subscribe({
        next: () => {
          this.resetForm();
          this.notifications.addNotification(NotificationType.OK, this.i18Service.getLocalizedString("changePasswordSuccessfulMessage"));
        },
        error: (response: ErrorResponse) => {
          console.error(response);
          let errorMessage = this.i18Service.getLocalizedString("changePasswordFailedMessage");
          if (response.errorCodes.includes(ErrorCode.AUTH_INVALID_CREDENTIALS)) {
            errorMessage += ` ${this.i18Service.getLocalizedString("loginFailedInvalidCredentials")}.`;
          }
          this.notifications.addNotification(NotificationType.ERROR, errorMessage);
        },
      });
  }

  private resetForm() {
    this.model.oldPassword = "";
    this.model.newPassword = "";
    this.model.retypedPassword = "";
    this.validator.resetValidationStatuses();
  }
}

export interface ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  retypedPassword: string;
}

function emptyChangePasswordModel(): ChangePasswordModel {
  return {
    oldPassword: "",
    newPassword: "",
    retypedPassword: "",
  };
}

export class ChangePasswordFormConfig extends FormConfigBase<ChangePasswordModel> {

  readonly oldPassword = this.createField({
    property: "oldPassword",
    label: "changePasswordOldPasswordLabel",
    type: "password",
    required: true
  });

  readonly newPassword = this.createField({
    property: "newPassword",
    label: "changePasswordNewPasswordLabel",
    type: "password",
    required: true,
    invalidLabel: "changePasswordStrongPasswordMessage"
  });

  readonly retypedPassword = this.createField({
    property: "retypedPassword",
    label: "changePasswordRetypePasswordLabel",
    type: "password",
    required: true,
    invalidLabel: "changePasswordRetypePasswordLabel"
  });

  constructor(
    model: ChangePasswordModel,
    propertyChangeListener?: PropertyChangeListener<ChangePasswordModel>,
    validationProvider?: (property: keyof ChangePasswordModel) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }
}
