import {UserRole} from "../authentication/authenticated-user";

interface ColumnVisibilityParameters {
  role: UserRole;
  isOperator: boolean;
  isChainDoctor: boolean;
  isChainNurse: boolean;
}

export function createColumnVisibility({
  role,
  isOperator,
  isChainDoctor,
  isChainNurse
}: ColumnVisibilityParameters): ColumnVisibility {
  return {
    code: true,
    uniqueId: true,
    hospitalName: role === "DATA_OFFICER" || role === "DOCTOR" || (role === "NURSE" && !!isOperator),
    uploadTime: role === "NURSE" || role === "DATA_OFFICER" || role === "DOCTOR",
    nurse: role === "DATA_OFFICER",
    deliveryTime: role === "DATA_OFFICER" || role === "NURSE" || role === "ORDERING_NURSE" || isChainDoctor,
    type: true,
    status: true,
    doctor: role === "DATA_OFFICER" || isChainDoctor,
    action: role === "DATA_OFFICER" || role === "DOCTOR" || isChainNurse,
    deviceSerialNumber: role === "DATA_OFFICER",
    initiateTime: role === "NURSE" || role === "DATA_OFFICER" || role === "ORDERING_NURSE",
    technician: role === "DATA_OFFICER",
    reportLanguage: role === "DATA_OFFICER" || role === "DOCTOR",
  };
}

export interface ColumnVisibility {
  code: boolean;
  uniqueId: boolean;
  hospitalName: boolean;
  uploadTime: boolean;
  nurse: boolean;
  deliveryTime: boolean;
  type: boolean;
  status: boolean;
  doctor: boolean;
  action: boolean;
  deviceSerialNumber: boolean;
  initiateTime: boolean;
  technician: boolean;
  reportLanguage: boolean;
}
