<table class="table table-sm table-borderless">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">
        <small>
          <strong>h:min</strong>
        </small>
      </th>
      <th scope="col">
        <small>
          <strong>sys</strong>
        </small>
      </th>
      <th scope="col">
        <small>
          <strong>dia</strong>
        </small>
      </th>
      <th scope="col">
        <small>
          <strong>bpm</strong>
        </small>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let reading of bpData.data; let readingIndex = index">
      <td class="pt-0 pb-0">
        <small>{{ readingIndex + 1 }}</small>
      </td>
      <td class="pt-0 pb-0">
        <small>{{ reading.timestamp | date: 'HH:mm' }}</small>
      </td>
      <td class="pt-0 pb-0">
        <small>{{ reading.systolic }}</small>
      </td>
      <td class="pt-0 pb-0">
        <small>{{ reading.diastolic }}</small>
      </td>
      <td class="pt-0 pb-0">
        <small>{{ reading.heartRate }}</small>
      </td>
    </tr>
  </tbody>
</table>
