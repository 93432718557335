import {Component, Input, OnInit} from "@angular/core";
import {FormFieldConfig} from "./form-field-config";
import {environment} from "../../../environments/environment";
import {LocalizationStrings} from "../../i18n/i18n-model";

@Component({
  selector: "form-field-wrapper",
  templateUrl: "./form-field-wrapper.component.html",
})
export class FormFieldWrapperComponent implements OnInit {

  responsiveLayout = environment.eforms;

  @Input()
    config: FormFieldConfig<any> | undefined;


  @Input()
    valueOverCharacterLimit?: boolean;

  ngOnInit(): void {
    if (this.config === undefined) {
      throw Error("Missing config!");
    }
  }

  isVerticalLayout() {
    return this.config!.verticalLayout || this.responsiveLayout;
  }

  getInvalidLabel(): keyof LocalizationStrings {

    if (this.config!.type == "textarea" && this.valueOverCharacterLimit) {
      return "textTooLongError";
    }

    return this.config!.invalidLabel!;

  }
}
