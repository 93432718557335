import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormFieldConfig} from "../../../../../tools/form/form-field-config";
import {AnalysisGenerationConfig, DoctorFindings} from "../../../analysis";
import {localizationKey} from "../../../../../i18n/i18n-model";

export interface DiagnosisOption {
  text: string | undefined;
  selected: boolean;
}

@Component({
  selector: "app-diagnosis-generator",
  templateUrl: "./diagnosis-generator.component.html",
})
export class DiagnosisGeneratorComponent implements OnInit {

  @Input()
  config?: FormFieldConfig<DoctorFindings>;

  @Input()
  isInitiallyManualMode?: boolean;

  @Input()
  analysisGenerationConfig?: AnalysisGenerationConfig;

  @Output()
  onGenerate = new EventEmitter<string>();

  isManualMode? = false;

  isWaitingForRegenerateConfirmation = false;

  localizationKey = localizationKey;

  preview: (string | undefined)[] = [];

  firstHalfOfOptions?: DiagnosisOption[];
  secondHalfOfOptions?: DiagnosisOption[];

  ngOnInit(): void {
    this.isManualMode = this.isInitiallyManualMode;
    const analysisGenerationConfig = this.analysisGenerationConfig!;
    const options = [
      {text: analysisGenerationConfig.diagnosis_i49_1, selected: false},
      {text: analysisGenerationConfig.diagnosis_i47_1, selected: false},
      {text: analysisGenerationConfig.diagnosis_i48_0, selected: false},
      {text: analysisGenerationConfig.diagnosis_i48_1, selected: false},
      {text: analysisGenerationConfig.diagnosis_i48_2, selected: false},
      {text: analysisGenerationConfig.diagnosis_i48_3, selected: false},
      {text: analysisGenerationConfig.diagnosis_i48_4, selected: false},
      {text: analysisGenerationConfig.diagnosis_i49_5, selected: false},
      {text: analysisGenerationConfig.diagnosis_z03_5, selected: false},
      {text: analysisGenerationConfig.diagnosis_i49_3, selected: false},
      {text: analysisGenerationConfig.diagnosis_i47_2, selected: false},
      {text: analysisGenerationConfig.diagnosis_i44_0, selected: false},
      {text: analysisGenerationConfig.diagnosis_i44_1, selected: false},
      {text: analysisGenerationConfig.diagnosis_i44_2, selected: false},
      {text: analysisGenerationConfig.diagnosis_i44_7, selected: false},
      {text: analysisGenerationConfig.diagnosis_i45_0, selected: false}
    ]
      .filter(option => option.text);
    const half = Math.ceil(options.length / 2);
    this.firstHalfOfOptions = options.slice(0, half);
    this.secondHalfOfOptions = options.slice(half, options.length);
  }

  checkboxChanged() {
    this.preview =
      this.firstHalfOfOptions!
        .concat(this.secondHalfOfOptions!)
        .filter(option => option.selected)
        .map(option => option.text);

    this.onGenerate.emit(this.generateDiagnoses());
  }

  private generateDiagnoses(): string {

    const selectedDiagnosesTexts =
      this.firstHalfOfOptions!
        .concat(this.secondHalfOfOptions!)
        .filter(option => option.selected)
        .map(option => option.text);

    return selectedDiagnosesTexts.every(text => text === undefined || text === "")
      ? ""
      : (
        selectedDiagnosesTexts
          .filter(text => text)
          .join("\n")
      );
  }
}
