<div class="container">
  <form class="form-striped">
    <form-field [config]="formConfig!.code"></form-field>
    <div class="alert alert-danger ml-2" data-testid="code-exists-message" *ngIf="displayCodeExists$ | async">
      {{ "uploadErrorCodeNotUnique" | localize}}
    </div>
    <form-field [config]="formConfig!.type"></form-field>

    <form-field [config]="formConfig!.reasonForStudy"></form-field>
    <form-field *ngIf="!!isMeasurementOrdered" [config]="formConfig!.operatorHospital"></form-field>
    <div class="row">
      <action-button *ngIf="!!isMeasurementOrdered == false"
                     buttonLabel="initiateFormButtonLabel"
                     actionInProgressLabel="initiateFormButtonInProgressLabel"
                     [actionInProgress]="initiateInProgress$ | async"
                     (buttonClick)="onInitiateClick()"
                     [testId]="'submit-button'">
      </action-button>
      <action-button *ngIf="!!isMeasurementOrdered == true"
                     buttonLabel="orderFormButtonLabel"
                     actionInProgressLabel="orderFormButtonInProgressLabel"
                     [actionInProgress]="initiateInProgress$ | async"
                     (buttonClick)="onInitiateClick()"
                     [testId]="'submit-button'">
      </action-button>


      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ml-2">
        {{ errorMessage | localize }}
      </span>
      </div>

  </form>
</div>
