<div class="container row">
  <div class="card col-md-8 mx-auto">
    <app-user-login *ngIf="otpMode===false"
                    (clearNotifications)="clearNotifications()"
                    (otpRequiredChange)="otpModeStep($event)"
                    (userAuthenticated)="userAuthenticated($event)"
                    (notifyError)="notifyError($event)"></app-user-login>

    <app-otp-login *ngIf="otpMode===true"
                   [userCredentials]="userCredentials"
                   (clearNotifications)="clearNotifications()"
                   (userAuthenticated)="userAuthenticated($event)"
                   (notifyError)="notifyError($event)"></app-otp-login>
  </div>
</div>
