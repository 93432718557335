<div class="container-fluid">
  <app-time-filter
    [from]="initialFromDate"
    [to]="initialToDate"
    [loadingInProgress]="listLoadingInProgress$ | async"
    (searchClick)="handleOnSearchClick($event)"
  >
  </app-time-filter>
  <div class="row">
   <span>
      {{ "measurementListCount" | localize }}
     <span data-testid="visible-items-counter-id">{{ items.length }}</span>
    /
    <span data-testid="counter-id">{{ getTotalRowsCount() }}</span>
    </span>
  </div>

  <div class="row card-header text-white bg-primary font-weight-bold">
    <div class="col-sm" *ngIf="columnVisibility.code">{{ "measurementsGridColumnLabelCode" | localize }}</div>
    <div class="col-sm" *ngIf="columnVisibility.uniqueId">{{ "measurementsGridColumnLabelUniqueId" | localize }}</div>
    <div class="col-sm" *ngIf="columnVisibility.hospitalName">{{ "measurementsGridColumnLabelHospitalName" | localize }}
      <div class="row">
        <app-select
          [value]="filterMap.get('hospitalName')"
          [options]="filterOptions.get('hospitalName') || []"
          [testId]="'hospital-filter'"
          (valueChanged)="handleFilterChange('hospitalName', $event)"
        >
        </app-select>
      </div>
    </div>
    <div class="col-sm"
         *ngIf="columnVisibility.initiateTime">{{ "measurementsGridColumnLabelInitiateTime" | localize }}
    </div>
    <div class="col-sm"
         *ngIf="columnVisibility.uploadTime">{{ "measurementsGridColumnLabelUploadTime" | localize }}
    </div>
    <div class="col-sm" *ngIf="columnVisibility.nurse">{{ "measurementsGridColumnLabelNurse" | localize }}</div>
    <div class="col-sm"
         *ngIf="columnVisibility.deliveryTime">{{ "measurementsGridColumnLabelDeliveryTime" | localize }}
    </div>
    <div class="col-sm" *ngIf="columnVisibility.type">{{ "measurementsGridColumnLabelType" | localize }}
      <div class="row">
        <app-select
          [value]="filterMap.get('type')"
          [options]="filterOptions.get('type') || []"
          (valueChanged)="handleFilterChange('type', $event)"
        >
        </app-select>
      </div>
    </div>
    <div class="col-sm" *ngIf="columnVisibility.status">{{ "measurementsGridColumnLabelStatus" | localize }}
      <div class="row" *ngIf="isStatusFilterVisible()">
        <app-select
          [value]="filterMap.get('status')"
          [options]="filterOptions.get('status') || []"
          (valueChanged)="handleFilterChange('status', $event)"
          [testId]="'status-filter'"
        >
        </app-select>
      </div>
    </div>
    <div class="col-sm" *ngIf="columnVisibility.technician">
      {{ localizationKey("measurementsGridColumnLabelTechnician") | localize }}
      <div class="row">
        <app-select
          [value]="filterMap.get('technician')"
          [options]="filterOptions.get('technician') || []"
          (valueChanged)="handleFilterChange('technician', $event)"
        >
        </app-select>
      </div>
    </div>
    <div class="col-sm-2" *ngIf="columnVisibility.doctor">{{ "measurementsGridColumnLabelDoctor" | localize }}
      <div class="row">
        <app-select
          [value]="filterMap.get('doctor')"
          [options]="filterOptions.get('doctor') || []"
          (valueChanged)="handleFilterChange('doctor', $event)"
          testId="doctor-filter"
        >
        </app-select>
      </div>
    </div>
    <div class="col-sm"
         *ngIf="columnVisibility.deviceSerialNumber">{{ "measurementsGridColumnLabelDeviceSerialNumber" | localize }}
    </div>
    <div class="col-sm"
         *ngIf="columnVisibility.reportLanguage">{{ localizationKey("measurementGridReportLanguage") | localize }}
    </div>
    <div class="col-sm-2" *ngIf="columnVisibility.action">{{ "measurementsGridColumnLabelAction" | localize }}</div>
  </div>

  <loading-indicator *ngIf="listLoadingInProgress$ | async"></loading-indicator>

  <div class="grid grid-striped text-break">
    <div *ngFor="let item of items" id="list-item">
      <app-list-item
        [columnVisibility]="columnVisibility"
        [item]="item"
        (measurementChanged)="onMeasurementChanged()"
      ></app-list-item>
    </div>
  </div>
</div>
