import {Component, EventEmitter, Output} from "@angular/core";

@Component({
  selector: "app-holter-type-editor",
  templateUrl: "./holter-type-editor.component.html",
})
export class HolterTypeEditorComponent {

  @Output()
  editingCancelled = new EventEmitter();

  @Output()
  holterTypeSet: EventEmitter<number> = new EventEmitter<number>();

  holterTypes: HolterType[] = [
    {durationDays: "1", localisationKey: "holterTimedTypeOfDurationDays1"},
    {durationDays: "2", localisationKey: "holterTimedTypeOfDurationDays2"},
    {durationDays: "3", localisationKey: "holterTimedTypeOfDurationDays3"},
    {durationDays: "4", localisationKey: "holterTimedTypeOfDurationDays4"},
    {durationDays: "5", localisationKey: "holterTimedTypeOfDurationDays5"},
    {durationDays: "6", localisationKey: "holterTimedTypeOfDurationDays6"},
    {durationDays: "7", localisationKey: "holterTimedTypeOfDurationDays7"},
  ];

  selectedHolterDurationDays = "1";

  onClose() {
    this.editingCancelled.emit();
  }

  onSubmit() {
    this.holterTypeSet.emit(parseInt(this.selectedHolterDurationDays));
  }
}

export interface HolterType {
  durationDays: string;
  localisationKey: string;
}
