import {numberToOneDecimalsLocaleString} from "../../../../../../tools/formatting/number-to-one-decimal-locale-string";
import {numberToTwoDecimalsLocaleString} from "../../../../../../tools/formatting/number-to-two-decimals-locale-string";

export function formatBurden(value: number, locale: Intl.LocalesArgument): string {
  return value < 0.1
    ? `<${numberToTwoDecimalsLocaleString(0.1, locale)} %`
    : `${numberToTwoDecimalsLocaleString(value, locale)} %`;
}
export function formatAfibBurden(value: number, locale: Intl.LocalesArgument): string {
  return value < 0.1
    ? `<${numberToOneDecimalsLocaleString(0.1, locale)} %`
    : `${numberToOneDecimalsLocaleString(value, locale)} %`;
}
