import { Component, Input } from "@angular/core";
import { FormFieldConfig, translateFormValue } from "./form-field-config";

@Component({
  selector: "app-form-field-display",
  templateUrl: "./form-field-display.component.html",
})
export class FormFieldDisplayComponent {
  @Input() formFieldConfig: FormFieldConfig<any> = {
    property: "any",
    model: { any: "" },
    label: "loadingLabel",
    type: "text",
    isValid: () => true,
  };

  @Input() label = "";
  @Input() value? = "";
  @Input() displayHorizontal = true;

  getLocalizedFormValue(): string | undefined {
    return (
      this.value ||
      translateFormValue(this.formFieldConfig, this.formFieldConfig.model[this.formFieldConfig.property], (key) => key)
    );
  }
}
