import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-map-cell-renderer",
  templateUrl: "./map-cell-renderer.component.html",
})
export class MapCellRendererComponent implements AgRendererComponent {
  map: object = {};
  Array = Array;

  agInit(params: ICellRendererParams): void {
    this.map = params?.value;
  }

  refresh(params: any): boolean {
    this.map = params?.value;

    return true;
  }
}
