import {Injectable} from "@angular/core";
import {ApiClient} from "../../../../tools/api-client";
import {Observable} from "rxjs";
import {PatientData} from "../../../upload/upload-model";
import {FoundMeasurement} from "../../found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../../../tools/recursive-null-to-undefined";

export interface UpdateMeasurementRequest {
  measurementStartTime: string;
  patientData: PatientData;
}

@Injectable()
export class EditMeasurementDataService {
  constructor(private readonly apiClient: ApiClient) {
  }

  update(measurementId: number, updateMeasurementRequest: UpdateMeasurementRequest): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(
      `/api/measurements/${measurementId}/edit-measurement-data`,
      updateMeasurementRequest
    )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
