import {OtpFormModel, UserCredentialsFormModel} from "./login-form-model";
import {Injectable} from "@angular/core";
import {isRequiredDigitsStringOfLength, isRequiredTextOfMaxLength} from "../validations";

@Injectable()
export class LoginValidator {

  validateUserCredentials(model: UserCredentialsFormModel): ReadonlyMap<keyof UserCredentialsFormModel, boolean> {
    return new Map<keyof UserCredentialsFormModel, boolean>()
      .set("username", isRequiredTextOfMaxLength(50, model.username))
      .set("password", isRequiredTextOfMaxLength(50, model.password));
  }

  validateOtp(model: OtpFormModel): ReadonlyMap<keyof OtpFormModel, boolean> {
    return new Map<keyof OtpFormModel, boolean>()
      .set("username", isRequiredTextOfMaxLength(50, model.username))
      .set("password", isRequiredTextOfMaxLength(50, model.password))
      .set("otp", isRequiredDigitsStringOfLength(6, model.otp));
  }
}
