import { Injectable } from "@angular/core";
import { ApiClient } from "../../../tools/api-client";
import { Observable } from "rxjs";
import { HospitalDetails } from "./hospital.details";
import { MeasurementType } from "../../measurement-models";
import { map } from "rxjs/operators";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

export interface HospitalInformationUpdateRequest {
  hospitalId: number;
  publicName: string;
  operatorAppointmentDescription: string;
  address: string;
  postalCode: string;
  city: string;
}

export interface UpdateHospitalPatientFormTemplatesRequest {
  hospitalId: number;
  selectedPatientFormTemplates: Map<MeasurementType, string>;
}

export interface HospitalLanguageSettings {
  language: string;
  reportLanguageEnabled: boolean;
}

export interface NurseSettings {
  hospitalId: number;
  nurse2Fa: boolean;
  combinedReportEnabled: boolean;
}

export interface PutHospitalInChainRequest {
  hospitalId: number;
  chainId: number;
}

const baseUrl = "/api/hospitals";

@Injectable()
export class EditHospitalService {
  constructor(private readonly apiClient: ApiClient) {
  }

  getHospital(id: number): Observable<HospitalDetails> {
    return this.apiClient.get<HospitalDetails>(`${baseUrl}/${id}`)
      .pipe(map((hospitalDetails) =>
        ({
          ...hospitalDetails,
          selectedPatientFormTemplates: this.toMeasurementTypeMap(hospitalDetails.selectedPatientFormTemplates),
          patientFormTemplateOptionsByMeasurementType: this.toMeasurementTypeMap(hospitalDetails.patientFormTemplateOptionsByMeasurementType),
        })))
      .pipe(map((hospitalDetails) => recursiveNullToUndefined(hospitalDetails)));
  }

  private toMeasurementTypeMap(selectedPatientFormTemplates: any) {
    return new Map(Object.keys(selectedPatientFormTemplates)
      .map(key => [key as MeasurementType, selectedPatientFormTemplates[key]]));
  }

  addOperator(hospitalId: number, operatorId: number): Observable<any> {
    return this.apiClient.patch<any>(`${baseUrl}/add-operator`, {
      hospitalId: hospitalId,
      operatorId: operatorId
    });
  }

  removeOperator(hospitalId: number, operatorId: number) {
    return this.apiClient.patch<any>(`${baseUrl}/remove-operator`, {
      hospitalId: hospitalId,
      operatorId: operatorId
    });
  }

  updateNurseSettings(nurseSettings: NurseSettings): Observable<void> {
    return this.apiClient.patch<void>(`${baseUrl}/nurse-settings`, nurseSettings);
  }

  updateHospitalInformation(hospitalInformationUpdateRequest: HospitalInformationUpdateRequest): Observable<void> {
    return this.apiClient.patch<void>(`${baseUrl}/update-hospital-information`, hospitalInformationUpdateRequest);
  }

  updateHospitalPatientFormTemplates(updateHospitalPatientFormTemplatesRequest: UpdateHospitalPatientFormTemplatesRequest): Observable<void> {
    return this.apiClient.patch<void>(`${baseUrl}/update-patient-form-templates`, {
      ...updateHospitalPatientFormTemplatesRequest,
      selectedPatientFormTemplates: Object.fromEntries(updateHospitalPatientFormTemplatesRequest.selectedPatientFormTemplates.entries())
    });
  }

  updateLanguageSettings(hospitalId: number, hospitalLanguageSettings: HospitalLanguageSettings): Observable<void> {
    return this.apiClient.patch<void>(`${baseUrl}/language-settings/${hospitalId}`, hospitalLanguageSettings);
  }

  putHospitalInChain(setChainRequest: PutHospitalInChainRequest): Observable<void> {
    return this.apiClient.patch<void>(`${baseUrl}/put-hospital-in-chain`, setChainRequest);
  }
}
