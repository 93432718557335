import {Observable, Subject} from "rxjs";
import {AuthenticatedUser} from "../authentication/authenticated-user";
import {ErrorResponse} from "../../tools/error-response";
import {OtpFormModel, UserCredentialsFormModel} from "./login-form-model";
import { EventEmitter, Output, Directive } from "@angular/core";
import { finalize } from "rxjs/operators";


@Directive()
export abstract class AuthenticationComponent {
  protected validationStatuses = new Map<keyof UserCredentialsFormModel | keyof OtpFormModel, boolean>();
  private readonly verificationInProgress = new Subject<boolean>();
  readonly authenticationInProgress$: Observable<boolean> = this.verificationInProgress.asObservable();

  @Output() clearNotifications = new EventEmitter();

  abstract authenticate(): Observable<AuthenticatedUser>;

  abstract validate(propertyToValidate?: keyof OtpFormModel): void;

  protected doVerification(success: (authenticatedUser: AuthenticatedUser) => void,
    error: (errorResponse: ErrorResponse) => void) {

    this.authenticate()
      .pipe(finalize(() => this.verificationInProgress.next(false)))
      .subscribe(
        (authenticatedUser) => {
          success(authenticatedUser);
        },
        (errorResponse: ErrorResponse) => {
          error(errorResponse);
        });
  }

  clearNotificationsAndShowProgress() {
    this.clearNotifications.emit();
    this.verificationInProgress.next(true);
  }

  isValid(): boolean {
    this.validate();
    return this.validationStatuses.size > 0
      && Array.from(this.validationStatuses).every(([, isValid]) => isValid);
  }

  validateProperties(validationResult: ReadonlyMap<keyof UserCredentialsFormModel | keyof OtpFormModel, boolean>,
    propertyToValidate?: keyof UserCredentialsFormModel | keyof OtpFormModel) {
    Array.from(validationResult)
      .filter(([property]) => propertyToValidate === undefined || propertyToValidate === property)
      .forEach(([prop, isValid]) => this.validationStatuses.set(prop, isValid));
  }
}
