import {NurseMeasurementType} from "./initiate-measurement-data";
import {MeasurementType} from "../measurement-models";

const homeTypes: NurseMeasurementType[] = [
  "HOME_HOLTER_24H",
  "HOME_HOLTER_48H",
  "HOME_HOLTER_7D",
];

export class HomeType {
  constructor(private readonly type: NurseMeasurementType) {
  }

  getMeasurementType(): MeasurementType {
    switch (this.type) {
      case "HOME_HOLTER_24H":
        return "HOLTER_ECG";
      case "HOME_HOLTER_48H":
        return "HOLTER_ECG";
      case "HOME_HOLTER_7D":
        return "HOLTER_ECG";
      default:
        return this.type as MeasurementType;
    }
  }

  isHomeType(): boolean {
    return homeTypes.includes(this.type);
  }

  getSpecifiedHomeDuration(): number | undefined {
    switch (this.type) {
      case "HOME_HOLTER_24H":
        return 1;
      case "HOME_HOLTER_48H":
        return 2;
      case "HOME_HOLTER_7D":
        return 7;
      default:
        return undefined;
    }
  }
}
