import {AnchorValues} from "../replace-anchors";
import {numberToTwoDecimalsLocaleString} from "../../../../../../tools/formatting/number-to-two-decimals-locale-string";
import {formatDuration} from "./format-duration";
import {ExternalFindings} from "../../../external-findings";
import {formatAfibBurden, formatBurden} from "./format-burden";


interface ExternalFindingFieldFormatter {
  format(value: number | undefined, locale: Intl.LocalesArgument): string | undefined;
}

interface ExternalFindingsFormats {
  [key: (keyof ExternalFindings)]: ExternalFindingFieldFormatter;
}

const decimalNumberFormatter: ExternalFindingFieldFormatter = {
  format: (value, locale) => value === undefined
    ? undefined
    : numberToTwoDecimalsLocaleString(value!, locale)
};

const durationFormatter: ExternalFindingFieldFormatter = {
  format: (value, locale) => value === undefined
    ? undefined
    : formatDuration(value!, locale)
};

const burdenFormatter: ExternalFindingFieldFormatter = {
  format: (value, locale) => value === undefined
    ? undefined
    : formatBurden(value!, locale)
};

const afibBurdenFormatter: ExternalFindingFieldFormatter = {
  format: (value, locale) => value === undefined
    ? undefined
    : formatAfibBurden(value!, locale)
};

export function createExternalFindingsFormats(): ExternalFindingsFormats {

  return {
    afibBurden: afibBurdenFormatter,
    afibTotalDuration: durationFormatter,
    averageHeartRate: decimalNumberFormatter,
    minimumHeartRate: decimalNumberFormatter,
    maximumHeartRate: decimalNumberFormatter,
    pausesOver2_5s: decimalNumberFormatter,
    ventricularBeats: decimalNumberFormatter,
    ventricularTachycardias: decimalNumberFormatter,
    ventricularTachycardiasLongestBeats: decimalNumberFormatter,
    ventricularTachycardiasLongestBeatsMaxHeartRate: decimalNumberFormatter,
    ventricularTachycardiasMaxHeartRate: decimalNumberFormatter,
    ventricularTachycardiasMaxHeartRateBeats: decimalNumberFormatter,
    ventricularTachycardiasLongestDurationMaxHeartRate: decimalNumberFormatter,
    atrialBeats: decimalNumberFormatter,
    atrialTachycardias: decimalNumberFormatter,
    atrialTachycardiasLongestBeats: decimalNumberFormatter,
    atrialTachycardiasLongestBeatsMaximumHeartRate: decimalNumberFormatter,
    atrialTachycardiasMaximumHeartRate: decimalNumberFormatter,
    atrialTachycardiasMaximumHeartRateBeats: decimalNumberFormatter,

    pausesOver2_5sLongestDuration: durationFormatter,
    ventricularTachycardiasLongestDuration: durationFormatter,
    ventricularTachycardiasMaxHeartRateDuration: durationFormatter,
    atrialTachycardiasLongestDuration: durationFormatter,
    atrialTachycardiasMaximumHeartRateDuration: durationFormatter,
    atrialTachycardiasLongestDurationMaxHeartRate: decimalNumberFormatter,
    ventricularBeatsBurden: burdenFormatter,
    atrialBeatsBurden: burdenFormatter
  };
}


export function formatExternalFindings(
  externalFindings: ExternalFindings,
  locale: Intl.LocalesArgument,
): AnchorValues {
  return Object.fromEntries(
    Object.entries(createExternalFindingsFormats())
      .map(([formatField, formatter]) =>
        [
          formatField,
          formatter.format(externalFindings[formatField], locale)
        ]
      )
  );
}
