import { isExisting, isValidMeasurementCode } from "../validations";
import { UploadFormModel } from "./upload-form-model";
import { FormValidator } from "../../tools/form/form-validator";
import { createMeasurementFormValidationMap } from "./create-measurement-form-validation-map";


export class UploadValidator extends FormValidator<UploadFormModel> {

  constructor(uploadData: UploadFormModel) {
    super(uploadData, createMeasurementFormValidationMap(uploadData)
      .set("measurementCode", () => isValidMeasurementCode(uploadData.measurementCode))
      .set("measurementFileName", () => isExisting(uploadData.measurementFileName)));
  }
}
