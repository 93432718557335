<div class="py-2">
  <div class="row">
    <div class="col-6 row">
      <div class="mr-auto">
        <span class="generator-title col-4">{{label | localize}}</span>
      </div>
      <span *ngIf="value"
            [attr.data-testId]="testId + '-badge'"
            class="badge badge-pill badge-secondary generator-badge mr-2">{{value}}</span>
    </div>
    <app-generator-text-field
      class="col-6 row"
      [testId]="testId"
      [text]="text"
      (onTextChange)="onGeneratedTextEdited($event)"
    ></app-generator-text-field>

  </div>
</div>
