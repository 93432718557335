export interface UserCredentialsFormModel {
  username: string;
  password: string;
}

export interface OtpFormModel {
  username: string;
  password: string;
  otp: string;
}

export function emptyUserCredentialsFormModel(): UserCredentialsFormModel {
  return {
    username: "",
    password: "",
  };
}

export function emptyOtpFormModel(): OtpFormModel {
  return {
    username: "",
    password: "",
    otp: ""
  };
}
