import {Component, EventEmitter, Input, Output} from "@angular/core";
import {EcgSymptom} from "../upload-model";
import {environment} from "../../../../environments/environment";
import {isSymptomDescriptionTooLong} from "../create-measurement-form-validation-map";
import {localizationKey} from "../../../i18n/i18n-model";
import {DateTime} from "luxon";
import {DateTimeSerializationService} from "../../../tools/formatting/date-time-serialization.service";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";

@Component({
  selector: "app-ecg-symptom",
  templateUrl: "./ecg-symptom.component.html",
})
export class EcgSymptomComponent {


  constructor(private readonly dateTimeSerializationService: DateTimeSerializationService) {
  }

  readonly maxCharacters = 80;

  @Input()
  symptomNumber = 1;

  @Input()
  symptom: EcgSymptom = {
    time: undefined,
    description: undefined,
  };
  responsiveLayout = environment.eforms;

  @Input("data-testid")
  dataTestId?: string;

  @Output()
  symptomChange: EventEmitter<EcgSymptom> = new EventEmitter<EcgSymptom>();

  onSymptomValueChange() {
    this.symptomChange.emit(this.symptom);
  }

  isSymptomValid(symptom: EcgSymptom) {
    return !isSymptomDescriptionTooLong(symptom);
  }

  symptomDateTime() {
    return this.symptom?.time ? DateTime.fromISO(this.symptom.time) : undefined;
  }

  onSymptomTimeChange(dateTime: DateTime | undefined) {
    this.symptom.time = dateTime === undefined
      ? undefined
      : this.dateTimeSerializationService.serializeWithOffset(dateTime);
    this.onSymptomValueChange();
  }

  protected readonly localizationKey = localizationKey;

  showNurseCharacterCounter() {
    return ReleaseToggleState
      .getInstance()
      .isReleased("SHOW_SYMPTOM_CHARACTER_COUNTER_TO_NURSES_297");
  }
}
