import {Injectable} from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import {TCreatedPdf} from "pdfmake/build/pdfmake";
import {Content, ContentColumns, TDocumentDefinitions} from "pdfmake/interfaces";
import {combineLatest, from, mergeMap, Observable, of} from "rxjs";
import {I18nService} from "../../../i18n/i18n.service";
import {formatDate, formatDateTime} from "../../../tools/date-time-utils";
import {Analysis} from "../analysis";
import {convertToFullUrl, createFonts, formatLabel, getAssetUrl, PrintedReportValue} from "../report";
import {EcgReportModel} from "./ecg-report-model";
import {EcgReportModelFactory} from "./ecg-report-model-factory";
import {ClockService} from "../../../tools/time/clock.service";
import {AuthenticationService} from "../../authentication/authentication.service";
import {map} from "rxjs/operators";
import {PDFDocument, PDFPage} from "pdf-lib";

export interface GeneratedReports {
  readonly primary: File;
  readonly combined: File;
}

@Injectable()
export class EcgReportGenerator {
  constructor(
    private readonly i18nService: I18nService,
    private readonly clockService: ClockService,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  generateReports(ecgAnalysis: Analysis, samplesDocumentBase64: Observable<string | undefined>): Observable<GeneratedReports> {
    const primaryReportObservable = new Observable<Blob>((observer) =>
      this.makePdfReport(ecgAnalysis)
        .getBlob((blob) => {
          observer.next(blob);
        })
    );
    return combineReports(
      primaryReportObservable,
      samplesDocumentBase64,
      (pdfA, pdfB) => from(concatPdfs(pdfA, pdfB)))
      .pipe(map((reports) => {
        const file = new File([reports.primary], `${ecgAnalysis.code}_ecg_report.pdf`);
        const combinedFile = reports.combined !== undefined
          ? new File([new Blob([reports.combined])], "combined-report.pdf")
          : undefined;
        return {
          primary: file,
          combined: combinedFile
        };
      }))
      .pipe(map((reports) => ({
        primary: reports.primary,
        combined: reports.combined!
      })));
  }

  generateAndPreviewReport(ecgAnalysis: Analysis, samplesDocumentBase64: Observable<string | undefined>) {
    const primaryReportObservable = new Observable<Blob>((observer) =>
      this.makePdfReport(ecgAnalysis)
        .getBlob((blob) => {
          observer.next(blob);
        })
    );
    combineReports(
      primaryReportObservable,
      samplesDocumentBase64,
      (pdfA, pdfB) => from(concatPdfs(pdfA, pdfB)))
      .subscribe((reports) => {
        if (reports.combined !== undefined) {
          const file = new Blob([reports.combined], {type: "application/pdf"});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          const file = new Blob([reports.primary], {type: "application/pdf"});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      });
  }

  private makePdfReport(ecgAnalysis: Analysis): TCreatedPdf {
    const ecgReportModel = new EcgReportModelFactory(
      ecgAnalysis,
      formatDate(this.clockService.now()),
      (key) => this.i18nService.getLocalizedString(key),
      (date) => formatDateTime(date),
      this.authenticationService.getCurrentAuthenticatedUser()!.token
    ).createEcgReportModel();

    const fonts = createFonts();
    const documentDefinitions = this.createPdfDefinitions(ecgReportModel);

    return pdfMake.createPdf(documentDefinitions, undefined, fonts);
  }

  private createPdfDefinitions(ecgReportModel: EcgReportModel): TDocumentDefinitions {
    return {
      pageMargins: [40, 90, 40, 80],
      header: createHeader(ecgReportModel),
      footer: (currentPage, pageCount) => createFooter(ecgReportModel, currentPage, pageCount),
      content: [
        createFindingsPageContent(ecgReportModel),
        createPatientDataPageContent(ecgReportModel),
      ],
      defaultStyle: {
        font: "Roboto",
        fontSize: 11,
        lineHeight: 1.1,
      },
      images: {
        logo: convertToFullUrl(ecgReportModel.logoUrlPath),
        footerLogo: getAssetUrl("logo/report-footer-logo.png"),
      },
    };
  }
}

function createHeader(ecgReportModel: EcgReportModel): ContentColumns {
  return {
    columns: [
      {image: "logo", width: 180, margin: 25},
      {
        stack: [
          {
            text: [
              {text: formatLabel(ecgReportModel.measurementCode.label), bold: true},
              {text: ecgReportModel.measurementCode.value}
            ],
          }, {
            text: [
              {text: formatLabel(ecgReportModel.uniqueId.label), bold: true},
              {text: ecgReportModel.uniqueId.value}
            ]
          }
        ],
        alignment: "right",
        margin: 40,
      },
    ],
  };
}

function createFooter(ecgReportModel: EcgReportModel, currentPage: number, pageCount: number): ContentColumns {
  return {
    margin: [40, 20, 40, 20],
    columns: [
      {image: "footerLogo", width: 80, margin: 0},
      {
        text: ecgReportModel.disclaimer,
        fontSize: 8,
        italics: true,
        lineHeight: 1,
        width: 320
      },
      {
        text: [{text: formatLabel(ecgReportModel.pageLabel)}, {text: `${currentPage}/${pageCount}`, bold: true}],
        alignment: "right",
        width: 50
      },
    ],
    columnGap: 30
  };
}

function createFindingsPageContent(model: EcgReportModel): Content {
  const LABEL_WIDTH = 160;

  const patientStatusColumns: ContentColumns[] = [model.patient, model.startTime, model.patientStatusComments].map((item) => ({
    columns: [{text: formatLabel(item.label), width: LABEL_WIDTH, bold: true}, {text: item.value}],
    margin: [0, 0, 0, 10],
  }));

  const symptomFindingsColumns: ContentColumns[] = model.symptomFindings.map((item) => ({
    columns: [
      {text: item.label, width: LABEL_WIDTH, bold: true, italics: true},
      {text: [{text: `${item.secondaryLabel}\n`, bold: true, italics: true}, item.value]},
    ],
  }));

  const isSymptomHolter = model.measurementType === "SYMPTOM_HOLTER";
  const isEcgAtrialFibrillation = model.measurementType === "ECG_ATRIAL_FIBRILLATION";

  return [
    {
      text: isEcgAtrialFibrillation ? model.ecgAtrialFibrillationPageTitle : model.findingsPageTitle,
      alignment: "center",
      bold: true,
      fontSize: 16,
      margin: [0, 0, 0, 20],
    },
    patientStatusColumns,
    isSymptomHolter ? {text: `${model.symptomFindingsTitle}:`, bold: true, margin: [0, 10, 0, 10]} : "",
    isSymptomHolter ? symptomFindingsColumns : "",
    {
      columns: [{
        text: formatLabel(model.findings.label),
        width: LABEL_WIDTH,
        bold: true
      }, {text: model.findings.value}],
      margin: [0, isSymptomHolter ? 20 : 0, 0, 10],
    },
    {
      columns: [{
        text: formatLabel(model.conclusion.label),
        width: LABEL_WIDTH,
        bold: true
      }, {text: model.conclusion.value}],
      margin: [0, 0, 0, 10],
    },
    isSymptomHolter || isEcgAtrialFibrillation
      ? ""
      : {
        columns: [{
          text: formatLabel(model.diagnosis.label),
          width: LABEL_WIDTH,
          bold: true
        }, {text: model.diagnosis.value}],
        margin: [0, 0, 0, 10],
      },
    {text: `${model.doctor.city} ${model.reportDate}`, margin: [LABEL_WIDTH, 20, 0, 10]},
    {text: model.doctor.fullName, margin: [LABEL_WIDTH, 0, 0, 0]},
    {text: model.doctor.title, margin: [LABEL_WIDTH, 0, 0, 0]},
  ];
}

function createPatientDataPageContent(model: EcgReportModel): Content {
  const isSymptomHolter = model.measurementType === "SYMPTOM_HOLTER";

  return [
    createExternalAnalysisLink(model),
    createPatientDataPageTitle(model),
    createPatientValueGroupContent([{reportValue: model.patientData.reasonForStudy, labelPosition: "TOP"}]),
    createPatientValueGroupContent([
      {reportValue: model.patientData.gender, labelPosition: "LEFT"},
      {reportValue: model.patientData.age, labelPosition: "LEFT"},
      {reportValue: model.patientData.height, labelPosition: "LEFT"},
      {reportValue: model.patientData.weight, labelPosition: "LEFT"},
    ]),
    createPatientValueGroupContent([{reportValue: model.patientData.smoking, labelPosition: "LEFT"}]),
    createPatientValueGroupContent([
      {reportValue: model.patientData.abnormalHeartbeats, labelPosition: "LEFT"},
      {reportValue: model.patientData.slowPulse, labelPosition: "LEFT"},
      {reportValue: model.patientData.fastPulse, labelPosition: "LEFT"},
      {reportValue: model.patientData.palpitation, labelPosition: "LEFT"},
      {reportValue: model.patientData.irregularPulse, labelPosition: "LEFT"},
    ]),
    createPatientValueGroupContent([
      {reportValue: model.patientData.dizziness, labelPosition: "LEFT"},
      {reportValue: model.patientData.lossOfConsciousness, labelPosition: "LEFT"},
      {reportValue: model.patientData.blackouts, labelPosition: "LEFT"},
      {reportValue: model.patientData.chestPain, labelPosition: "LEFT"},
      {reportValue: model.patientData.shortnessOfBreath, labelPosition: "LEFT"},
    ]),
    createPatientValueGroupContent([{reportValue: model.patientData.diagnosedHeartDisease, labelPosition: "TOP"}]),
    createPatientValueGroupContent([{reportValue: model.patientData.currentMedication, labelPosition: "TOP"}]),
    createPatientValueGroupContent([{reportValue: model.patientData.patientFeedback, labelPosition: "TOP"}]),
    isSymptomHolter ? "" : createPatientValueGroupContent([{
      reportValue: model.patientData.diary,
      labelPosition: "TOP"
    }]),
  ];
}

function createPatientDataPageTitle(model: EcgReportModel): Content {
  const patientDataTitle: Content = {text: model.patientDataPageTitle, fontSize: 14, bold: true, margin: [0, 0, 0, 10]};
  return model.externalAnalysisUrl !== undefined
    ? patientDataTitle
    : {
      ...patientDataTitle,
      pageBreak: "before"
    };
}

function createExternalAnalysisLink(model: EcgReportModel): Content {
  return model.externalAnalysisUrl !== undefined
    ? {
      text: [
        {text: formatLabel(model.externalAnalysisUrlLabel), bold: true},
        {text: model.externalAnalysisUrl, link: model.externalAnalysisUrl, decoration: "underline"}
      ],
      pageBreak: "before",
      margin: [0, 0, 0, 20],
    }
    : {} as Content;

}

function createPatientValueGroupContent(reportValues: PrintedReportValue[]): Content {
  return reportValues.map((reportValue, i) => createPatientValueContent(reportValue, i === reportValues.length - 1));
}

function createPatientValueContent(printedValue: PrintedReportValue, withMarginBottom: boolean): Content {
  const labelWidth = 180;
  const fontSize = 8;
  const margin: number | [number, number, number, number] = withMarginBottom ? [0, 0, 0, 10] : 0;

  if (printedValue.labelPosition === "LEFT") {
    return {
      columns: [
        {text: formatLabel(printedValue.reportValue.label), bold: true, width: labelWidth, fontSize},
        {text: printedValue.reportValue.value, fontSize},
      ],
      margin,
    };
  } else {
    return {
      text: [
        {text: `${formatLabel(printedValue.reportValue.label)}\n`, bold: true, fontSize},
        {text: printedValue.reportValue.value, fontSize},
      ],
      margin,
    };
  }
}

export async function concatPdfs(pdfA: string | Uint8Array | ArrayBuffer, pdfB: string | Uint8Array | ArrayBuffer) {
  const pdfADocument = await PDFDocument.load(pdfA);
  const pdfBDocument = await PDFDocument.load(pdfB);

  const mergedPdf = await PDFDocument.create();
  const copiedPagesA = await mergedPdf.copyPages(pdfADocument, pdfADocument.getPageIndices());
  copiedPagesA.forEach(page => scalePageToA4(page));
  copiedPagesA.forEach((page) => mergedPdf.addPage(page));

  const copiedPagesB = await mergedPdf.copyPages(pdfBDocument, pdfBDocument.getPageIndices());
  copiedPagesB.forEach(page => scalePageToA4(page));
  copiedPagesB.forEach((page) => mergedPdf.addPage(page));

  return await mergedPdf.save();
}

function scalePageToA4(page: PDFPage) {
  const A4_WIDTH = 595.28;
  const A4_HEIGHT = 841.89;

  const {width, height} = page.getSize();

  const scaleX = A4_WIDTH / width;
  const scaleY = A4_HEIGHT / height;
  const scale = Math.min(scaleX, scaleY); // Maintain aspect ratio

  page.scale(scale, scale);

  const newWidth = width * scale;
  const newHeight = height * scale;
  const offsetX = (A4_WIDTH - newWidth) / 2;
  const offsetY = (A4_HEIGHT - newHeight) / 2;
  page.translateContent(offsetX, offsetY);

  page.setSize(A4_WIDTH, A4_HEIGHT);
}


export function combineReports(
  primaryReportObservable: Observable<Blob>,
  samplesDocumentBase64Observable: Observable<string | undefined>,
  concatPdfsLambda: (
    pdfA: (string | Uint8Array | ArrayBuffer),
    pdfB: (string | Uint8Array | ArrayBuffer)
  ) => Observable<Uint8Array>
): Observable<{ combined: Uint8Array | undefined; primary: ArrayBuffer }> {
  return combineLatest([
    primaryReportObservable.pipe(mergeMap((blob) => blob.arrayBuffer())),
    samplesDocumentBase64Observable
  ])
    .pipe(mergeMap(reports => {
      if (reports[1] === undefined) {
        return of<[ArrayBuffer, Uint8Array | undefined]>([reports[0], undefined]);
      } else {
        return concatPdfsLambda(reports[0], reports[1])
          .pipe<[ArrayBuffer, Uint8Array | undefined]>(map(concatenated => [reports[0], concatenated]));
      }
    }))
    .pipe(map((reports) => ({
      primary: reports[0],
      combined: reports[1]
    })));
}
