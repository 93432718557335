import { Injectable } from "@angular/core";
import { LocalStorageService } from "../../tools/local-storage.service";
import { AuthenticatedUser, isValidAuthenticatedUser } from "./authenticated-user";

@Injectable()
export class AuthenticatedUserStore {
  constructor(private readonly localStorageService: LocalStorageService) {}

  save(authenticatedUser: AuthenticatedUser) {
    if (!isValidAuthenticatedUser(authenticatedUser)) {
      throw new Error(`Invalid authenticatedUser: ${JSON.stringify(authenticatedUser)}`);
    }
    this.localStorageService.set(AUTHENTICATED_USER_LOCAL_STORAGE_KEY, JSON.stringify(authenticatedUser));
  }

  get(): AuthenticatedUser | undefined {
    const localStorageValue = this.localStorageService.get(AUTHENTICATED_USER_LOCAL_STORAGE_KEY);
    if (!localStorageValue) {
      return undefined;
    }

    const authenticatedUser = tryGetFromLocalStorage(localStorageValue);
    if (!authenticatedUser) {
      this.delete();
    }
    return authenticatedUser;
  }

  delete() {
    this.localStorageService.delete(AUTHENTICATED_USER_LOCAL_STORAGE_KEY);
  }
}

function tryGetFromLocalStorage(localStorageValue: string): AuthenticatedUser | undefined {
  try {
    const authenticatedUser: AuthenticatedUser = JSON.parse(localStorageValue);
    return isValidAuthenticatedUser(authenticatedUser) ? authenticatedUser : undefined;
  } catch (e) {
    console.error("Error parsing authenticated user from local storage", localStorageValue);
    return undefined;
  }
}

export const AUTHENTICATED_USER_LOCAL_STORAGE_KEY = "AUTHENTICATED_USER";
