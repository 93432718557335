<div>

  <form-field-wrapper
    data-testId="diagnosis-generator"
    [hidden]="isManualMode"
    [config]="config"
  >
    <div class="row">
      <div class="col-6">
        <div *ngFor="let option of firstHalfOfOptions; let index = index">
          <label for="left-option{{index}}">
            <input
              data-testId="diagnosis-generator-checkbox"
              id="left-option{{index}}"
              type="checkbox"
              [(ngModel)]="option.selected"
              (ngModelChange)="checkboxChanged()"
            >
            {{ option.text }}</label>
        </div>
      </div>
      <div class="col-6">
        <div *ngFor="let option of secondHalfOfOptions; let index = index">
          <label for="right-option{{index}}">
            <input
              data-testId="diagnosis-generator-checkbox"
              id="right-option{{index}}"
              type="checkbox"
              [(ngModel)]="option.selected"
              (ngModelChange)="checkboxChanged()"
            >
            {{ option.text }}</label>
        </div>
      </div>
    </div>

    <div class="border-top border-gray pt-2 mt-3" data-testId="diagnosis-generator-preview">
      <div *ngFor="let previewLine of preview"
      >{{ previewLine }}</div>
    </div>

  </form-field-wrapper>

  <ng-content
    *ngIf="isManualMode"
  ></ng-content>

  <div class="d-flex flex-row-reverse">
    <button
      data-testId="diagnosis-generator-done"
      *ngIf="!isManualMode"
      (click)="isManualMode = true"
      type="button"
      class="btn btn-sm btn-outline-success mr-1 mb-1 align-self-end"
    >
      {{ localizationKey("analysisPatientStatusCommentsGeneratorDoneButton") | localize }}
    </button>
    <button
      data-testId="diagnosis-generator-regenerate"
      (click)="isWaitingForRegenerateConfirmation = true"
      *ngIf="isManualMode && !isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary mr-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsRegenerateButton") | localize }}<i
      class="fa fa-chevron-right mx-1"></i>
    </button>

    <button
      (click)="isWaitingForRegenerateConfirmation = false"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-secondary mr-1"
    >
      {{ localizationKey("analysisPatientStatusCommentsCancelRegenerateButton") | localize }}
    </button>
    <button
      (click)="isWaitingForRegenerateConfirmation = false; isManualMode = false"
      *ngIf="isWaitingForRegenerateConfirmation"
      type="button"
      class="btn btn-sm btn-outline-primary mr-1"
      data-testId="diagnosis-generator-regenerate-confirm"
    >
      {{ localizationKey("analysisPatientStatusCommentsConfirmRegenerateButton") | localize }}
    </button>
  </div>
  <div
    *ngIf="isWaitingForRegenerateConfirmation"
    class="d-flex flex-row-reverse mt-1 text-secondary small"
  >
    {{ localizationKey("analysisRegenerationWarningLabel") | localize }}
    <i class="fa fa-exclamation-triangle p-1" aria-hidden="true"></i>
  </div>
</div>
