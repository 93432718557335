<div class="btn-group" dropdown>
  <button dropdownToggle data-testid="user-menu-button" type="button" class="btn btn-secondary rounded-sm">
    <i class="fa fa-lg fa-user-circle-o mr-2" aria-hidden="true"></i>
    <span class="navbar-text font-weight-bold">{{ currentUser?.username }}</span>
  </button>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
    <li role="menuitem">
      <a class="dropdown-item" href="/{{ navigationRoute.CHANGE_PASSWORD }}" >
        <i class="fa fa-key"></i>
        {{ "changePasswordLabel" | localize }}
      </a>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <a class="dropdown-item" href="/{{ navigationRoute.CHANGE_LANGUAGE }}" >
        <i class="fa fa-language"></i>
        {{ "changeLanguageLabel" | localize }}
      </a>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <a class="dropdown-item" href="#" (click)="onLogoutClick($event)" data-testid="logout-button">
        <i class="fa fa-sign-out"></i>
        {{ "navigationLogout" | localize }}
      </a>
    </li>
  </ul>
</div>
