import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {finalize, flatMap} from "rxjs/operators";
import {localizationKey, LocalizationStrings} from "../../../i18n/i18n-model";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {UploadFormConfig} from "../../upload/upload-form-config";
import {Analysis, BpFindingsModel, createUploadFormConfig, DoctorFindings, emptyDoctorFindings} from "../analysis";
import {AnalysisService} from "../analysis.service";
import {BloodPressureReportGenerator} from "../bp-report/blood-pressure-report-generator.service";
import {FinishAnalysisService} from "../finish-analysis.service";
import {MeasurementIdProvider} from "../measurement-id-provider";
import {BloodPressureService} from "./bloodPressure.service";
import {BloodPressureData, BloodPressureFindings} from "./bp-analysis";
import {BpDoctorFindingsFormConfig} from "./bp-doctor-findings-form-config";
import {
  BpFindingsValidationResult,
  BpFindingsValidator,
  emptyBpFindingsValidationResult,
  initialBpFindingsValidationResult,
} from "./bp-findings-validator";
import {BpReportModelFactory} from "../bp-report/bp-report-model-factory";
import {formatDateTime} from "../../../tools/date-time-utils";
import {I18nService} from "../../../i18n/i18n.service";
import {AuthenticationService} from "../../authentication/authentication.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {getCommentButtonLabel} from "../../comments/comments.utils";
import {CommentsCountChangedEvent} from "../../comments/comments.component";
import {ClockService} from "../../../tools/time/clock.service";

@Component({
  selector: "app-bp-analysis",
  templateUrl: "./bp-analysis.component.html",
})
export class BpAnalysisComponent implements OnInit, PropertyChangeListener<DoctorFindings> {
  bpAnalysis?: Analysis;
  patientDataFields?: UploadFormConfig;
  isFinishMode = false;
  isPreviewMode = false;
  errorMessage?: keyof LocalizationStrings;
  bpFindingsFormConfig: BpDoctorFindingsFormConfig = new BpDoctorFindingsFormConfig(emptyDoctorFindings(), this);

  bpData: BloodPressureData | undefined;
  bpChartImageBase64: string | undefined;

  bpFindingsModel: BpFindingsModel | undefined;

  private bpFindingsValidationResult: BpFindingsValidationResult = emptyBpFindingsValidationResult();
  readonly labelStudyId: keyof LocalizationStrings = "uploadFormLabelCode";
  readonly labelUniqueId: keyof LocalizationStrings = "reportUniqueIdLabel";
  readonly labelSave: keyof LocalizationStrings = "analysisButtonSave";
  readonly labelInProgress: keyof LocalizationStrings = "analysisLabelSaveInProgress";
  readonly labelButtonFinish: keyof LocalizationStrings = "analysisLabelButtonFinish";
  readonly labelButtonFinishConfirm: keyof LocalizationStrings = "analysisLabelButtonFinishConfirm";
  readonly labelButtonFinishCancel: keyof LocalizationStrings = "analysisLabelButtonFinishCancel";
  readonly labelButtonFinishInProgress: keyof LocalizationStrings = "analysisLabelButtonFinishInProgress";
  readonly labelPreviewReport: keyof LocalizationStrings = "analysisButtonPreviewReport";
  readonly saveInProgress = new Subject<boolean>();
  readonly saveInProgress$: Observable<boolean> = this.saveInProgress.asObservable();
  readonly finishAnalysisInProgress = new Subject<boolean>();
  readonly finishAnalysisInProgress$: Observable<boolean> = this.finishAnalysisInProgress.asObservable();
  readonly measurementLoadingInProgress = new BehaviorSubject<boolean>(true);
  readonly measurementLoadingInProgress$: Observable<boolean> = this.measurementLoadingInProgress.asObservable();
  readonly bpDataLoadingInProgress = new BehaviorSubject<boolean>(true);
  readonly bpDataLoadingInProgress$: Observable<boolean> = this.bpDataLoadingInProgress.asObservable();
  protected readonly getCommentButtonLabel = getCommentButtonLabel;

  private readonly reportModelFactory: BpReportModelFactory;
  @ViewChild("templateComments") templateComments!: TemplateRef<any>;

  modalRefComments: BsModalRef | undefined;

  constructor(
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly analysisService: AnalysisService,
    private readonly bloodPressureService: BloodPressureService,
    private readonly notifications: Notifications,
    private readonly route: ActivatedRoute,
    private readonly bpReportGenerator: BloodPressureReportGenerator,
    private readonly finishAnalysisService: FinishAnalysisService,
    private readonly navigator: RouteNavigator,
    private readonly findingsValidator: BpFindingsValidator,
    private readonly i18nService: I18nService,
    private readonly authenticationService: AuthenticationService,
    readonly modalService: BsModalService,
    clockService: ClockService
  ) {

    const user = authenticationService.getCurrentAuthenticatedUser();
    this.reportModelFactory = new BpReportModelFactory(
      (key) => i18nService.getLocalizedString(key),
      (date) => formatDateTime(date),
      () => clockService.now(),
      user!.token
    );

    this.isPreviewMode = user?.role == "DATA_OFFICER";
  }

  ngOnInit(): void {
    const measurementId = this.measurementIdProvider.getId(this.route);

    if (measurementId === undefined) {
      return;
    }

    this.loadAnalysis(measurementId);
    this.loadBloodPressureData(measurementId);
  }

  private loadAnalysis(measurementId: number): void {
    this.analysisService
      .getAnalysis(measurementId)
      .pipe(finalize(() => this.measurementLoadingInProgress.next(false)))
      .subscribe(
        (bpAnalysis) => {
          this.updateAnalysis(bpAnalysis);
        },
        () => {
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("analysisMessageFailedGet"));
        }
      );
  }

  private updateAnalysis(analysis: Analysis): void {
    this.bpAnalysis = analysis;
    this.patientDataFields = createUploadFormConfig(analysis);

    this.bpFindingsFormConfig = new BpDoctorFindingsFormConfig(
      analysis.doctorFindings,
      this,
      (property) =>
        // @ts-ignore
        this.bpFindingsValidationResult[property].simpleResult
    );

    this.bpFindingsValidationResult = initialBpFindingsValidationResult();
  }

  private loadBloodPressureData(measurementId: number): void {
    this.bloodPressureService
      .getData(measurementId)
      .pipe(finalize(() => this.bpDataLoadingInProgress.next(false)))
      .subscribe(
        (data) => {
          this.bpData = data;
          this.bpFindingsModel = this.convertBloodPressureFindingsToMatrix(data.findings);
        },
        () => {
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("analysisMessageLoadBpDataFailed"));
        }
      );
  }

  private convertBloodPressureFindingsToMatrix(findings: BloodPressureFindings): BpFindingsModel {

    function formatValue(value: number): string {
      return value.toFixed(0);
    }

    const smallerThanActiveString = this.i18nService.getLocalizedString("bpSmallerThanActive");

    return {
      avgDiastolicBP: [
        `${formatValue(findings.average.dia.active)} mmHg`,
        `${formatValue(findings.average.dia.sleep)} mmHg (${formatValue(findings.average.dia.sleepSmallerThanActivePercentage)} % ${smallerThanActiveString})`,
        `${formatValue(findings.average.dia.all)} mmHg`,
      ],
      avgSystolicBP: [
        `${formatValue(findings.average.sys.active)} mmHg`,
        `${formatValue(findings.average.sys.sleep)} mmHg (${formatValue(findings.average.sys.sleepSmallerThanActivePercentage)} % ${smallerThanActiveString})`,
        `${formatValue(findings.average.sys.all)} mmHg`,
      ],
      avgDiastolicRange: [
        `${formatValue(findings.range.min.dia.active)} - ${formatValue(findings.range.max.dia.active)} mmHg`,
        `${formatValue(findings.range.min.dia.sleep)} - ${formatValue(findings.range.max.dia.sleep)} mmHg`,
        `${formatValue(findings.range.min.dia.all)} - ${formatValue(findings.range.max.dia.all)} mmHg`,
      ],
      avgSystolicRange: [
        `${formatValue(findings.range.min.sys.active)} - ${formatValue(findings.range.max.sys.active)} mmHg`,
        `${formatValue(findings.range.min.sys.sleep)} - ${formatValue(findings.range.max.sys.sleep)} mmHg`,
        `${formatValue(findings.range.min.sys.all)} - ${formatValue(findings.range.max.sys.all)} mmHg`,
      ],
      diastolicBPload: [
        `${formatValue(findings.load.dia.active)} %`,
        `${formatValue(findings.load.dia.sleep)} %`,
        `${formatValue(findings.load.dia.all)} %`,
      ],
      systolicBPload: [
        `${formatValue(findings.load.sys.active)} %`,
        `${formatValue(findings.load.sys.sleep)} %`,
        `${formatValue(findings.load.sys.all)} %`,
      ],
      avgPuls: [
        `${formatValue(findings.heartRate.average.active)}/min`,
        `${formatValue(findings.heartRate.average.sleep)}/min`,
        `${formatValue(findings.heartRate.average.all)}/min`,
      ]
    };
  }

  private validateBpFindingsForm(): boolean {
    this.validateFindings();
    const isValid = this.isFindingsValid();
    if (!isValid) {
      this.errorMessage = "analysisFormValidationError";
    }

    return isValid;
  }

  private isFindingsValid(): boolean {
    return (Object.keys(this.bpFindingsValidationResult) as [keyof BpFindingsValidationResult])
      .map((key) => this.bpFindingsValidationResult[key])
      .every(
        (validationResult) => validationResult.simpleResult !== false && validationResult.arrayResult.every((result) => result !== false)
      );
  }

  onPropertyChange(property: keyof DoctorFindings, newValue: any): void {
    this.bpAnalysis!.doctorFindings[property] = newValue;
    this.validateFindings();
  }

  onBpChartImageChange(imageBase64: string): void {
    this.bpChartImageBase64 = imageBase64;
  }

  previewReport(): void {
    if (!this.isDataFullyLoaded()) {
      return;
    }

    const reportModel = this.reportModelFactory.createBpReportModel(this.bpAnalysis!, this.bpFindingsModel!);

    this.bpReportGenerator.generateAndPreviewReport(reportModel, this.bpChartImageBase64!);
  }

  onSaveButtonClick(): void {
    if (!this.validateBpFindingsForm()) {
      return;
    }

    this.saveInProgress.next(true);
    this.analysisService
      .saveAnalysis(this.bpAnalysis!.id, this.bpAnalysis!.doctorFindings)
      .pipe(finalize(() => this.saveInProgress.next(false)))
      .subscribe(
        (savedBpnalysis) => {
          this.notifications.addNotification(NotificationType.OK, localizationKey("analysisMessageSaveSuccess"));
          this.bpAnalysis = savedBpnalysis;
        },
        () => (this.errorMessage = "analysisMessageSaveFailed")
      );
  }

  switchToFinishMode(): void {
    this.isFinishMode = true;
    this.errorMessage = undefined;
    this.validateFindings();
  }

  switchToSaveMode(): void {
    this.isFinishMode = false;
    this.errorMessage = undefined;
    this.validateFindings();
  }

  private validateFindings(): void {
    this.bpFindingsValidationResult = this.findingsValidator.validate(
      {
        measurementType: this.bpAnalysis!.type,
        measurementCode: this.bpAnalysis!.code,
        ecgFindings: this.bpAnalysis!.doctorFindings,
      },
      this.isFinishMode
    );
  }

  onFinishAnalysisClick(): void {
    if (!this.validateBpFindingsForm()) {
      return;
    }

    if (!this.isDataFullyLoaded()) {
      return;
    }

    this.finishAnalysisInProgress.next(true);

    const reportModel = this.reportModelFactory.createBpReportModel(this.bpAnalysis!, this.bpFindingsModel!);

    this.analysisService
      .saveAnalysis(this.bpAnalysis!.id, this.bpAnalysis!.doctorFindings)
      .pipe(
        flatMap(() => this.bpReportGenerator.generateReport(reportModel, this.bpChartImageBase64!)),
        flatMap((bpGeneratedReportFile: File) => this.finishAnalysisService.finishAnalysis(this.bpAnalysis!.id!, bpGeneratedReportFile)),
        finalize(() => this.finishAnalysisInProgress.next(false))
      )
      .subscribe(
        () => this.navigator.navigateTo(NavigationRoute.LIST),
        () => (this.errorMessage = "finishAnalysisFailed")
      );
  }

  onShowCommentsClick() {
    this.modalRefComments = this.modalService.show(this.templateComments, {
      class: "modal-lg"
    });
  }

  onCommentsCountChanged(event: CommentsCountChangedEvent) {
    this.bpAnalysis!.commentsCount = event.newCommentCount;
  }

  getButtonType() {
    if (this.bpAnalysis!.commentsCount == 0) {
      return "btn-secondary";
    } else {
      return "btn-primary";
    }
  }

  isDataFullyLoaded() {
    return this.bpAnalysis && this.bpChartImageBase64;
  }

  protected readonly localizationKey = localizationKey;
}
