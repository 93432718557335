import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ReturnService } from "./return.service";
import { FoundMeasurement } from "../found-measurement";
import { LocalizationStrings } from "../../../i18n/i18n-model";

@Component({
  selector: "app-return-study",
  templateUrl: "./return-study.component.html",
})
export class ReturnStudyComponent {
  @Input() measurementId?: number;
  @Output() readonly returnedStudy = new EventEmitter<FoundMeasurement>();

  readonly buttonLabelReturnStudy: keyof LocalizationStrings = "measurementsGridButtonLabelReturnStudy";
  readonly buttonLabelConfirm: keyof LocalizationStrings = "measurementsGridButtonLabelReturnStudyConfirm";

  isConfirmMode = false;

  constructor(private readonly returnService: ReturnService) {}

  onConfirm() {
    this.returnService.returnStudy(this.measurementId!).subscribe((study) => this.returnedStudy.emit(study));
  }
}
