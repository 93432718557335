import {Observable, of} from "rxjs";

export interface GetSamplesReportParams {
  featureToggle: boolean;
  isExternal: boolean;
  fileSelected: boolean;
  fileUploadedBefore: boolean;
  getSamplesFromBackend: () => Observable<string | undefined>;
  readFile: () => Observable<string | undefined>;
}


export function getSamplesReport(params: GetSamplesReportParams): Observable<string | undefined> {
  const {
    featureToggle,
    isExternal,
    fileSelected,
    fileUploadedBefore,
    getSamplesFromBackend,
    readFile
  } = params;

  if (!featureToggle) {
    return of(undefined);
  }

  if (isExternal) {
    return getSamplesFromBackend();
  }

  if (fileSelected) {
    return readFile();
  }

  if (fileUploadedBefore) {
    return getSamplesFromBackend();
  }

  return of(undefined);
}
