<div
  *ngIf="!!config"
  class="form-group"
  [ngClass]="{
    'form-field-is-valid': config.isValid() === true,
    'form-field-is-invalid': config.isValid() === false,
    '': config.isValid() === undefined,
    col: isVerticalLayout(),
    row: !isVerticalLayout()
  }"
>
  <label
    [for]="config.property"
    [class]="'col-form-label'"
    [ngClass]="{
      'font-weight-bold': config.type === 'header',
      'text-right': config.alignLabelRight,
      'col-10': isVerticalLayout(),
      'col-5': !isVerticalLayout()
    }"
  >{{ config.label | localize }}<span *ngIf="config.required && !responsiveLayout"> *</span><span *ngIf="responsiveLayout && config.infoLabel"> ({{ config.infoLabel | localize}})</span></label
  >

  <div
    [ngClass]="{
      'row': !isVerticalLayout(),
      'col': isVerticalLayout(),
      'col-12': isVerticalLayout(),
      'col-5': !isVerticalLayout()
    }"
  >
    <ng-content></ng-content>

    <div
      *ngIf="!!config.invalidLabel"
      [hidden]="config.isValid() !== false"
      class="form-field-invalid-feedback"
      [attr.data-testid]="'error-' + config.property.toString()"
    >
      {{ getInvalidLabel() | localize }}
    </div>
  </div>
  <span *ngIf="!!config.infoLabel && !responsiveLayout" class="pl-1 pt-1">
    {{ config.infoLabel | localize }}
  </span>
</div>
