import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../../tools/api-client";
import { BloodPressureData } from "./bp-analysis";

@Injectable()
export class BloodPressureService {
  constructor(private readonly apiClient: ApiClient) {}

  getData(measurementId: number): Observable<BloodPressureData> {
    return this.apiClient.get<BloodPressureData>(`/api/bloodPressure/${measurementId}/data`);
  }
}
