import {isValidFile} from "../../validations";
import {measurementFileConfigByType} from "./measurement-file-config";
import {Injectable} from "@angular/core";
import {MeasurementType} from "../../measurement-models";
import {DateTime} from "luxon";

@Injectable()
export class UploadFileValidator {
  validate(file: File | undefined, type: MeasurementType) {
    const config = measurementFileConfigByType.get(type)!;
    let fileNameValidator;
    switch (type) {
      case "HOLTER_ECG":
      case "SYMPTOM_HOLTER":
      case "ECG_ATRIAL_FIBRILLATION":
        fileNameValidator = (name: string) => validateEcgFileName(name);
        break;
      default:
        fileNameValidator = undefined;
    }
    if (file && (file.name.toLowerCase().endsWith(".edf") || file.name.toLowerCase().endsWith(".efs"))) {
      fileNameValidator = undefined;
    }
    return isValidFile(file, {
      minFileSize: config.minFileSize,
      maxFileSize: config.maxFileSize,
      requiredExtensions: config.allowedFileExtensions,
      fileNameValidator: fileNameValidator,
    });
  }
}

function validateEcgFileName(name: string): boolean {
  if (!name) {
    return false;
  }

  const nameParts = name.split("-");
  if (nameParts.length !== 3) {
    return false;
  }
  const [deviceName, date, time] = nameParts;

  if (deviceName.trim().length === 0) {
    return false;
  }
  const dateTime = `20${date}${time}`;

  return DateTime.fromFormat(dateTime, "yyyyMMddHHmmss").isValid;
}
