import {Component} from "@angular/core";
import {FormFieldConfig, PropertyChangeListener} from "../../../../tools/form/form-field-config";
import {EFormIdProvider} from "../../e-form-id-provider.service";
import {ActivatedRoute} from "@angular/router";
import {EFormMailingInfoService, MailingInfo} from "./e-form-mailing-info.service";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";

export interface MailingInfoFormModel {
  name: string;
  mailingAddress: string;
  emailAddress: string;
  phoneNumber: string;
  permission: boolean;
}

@Component({
  selector: "eform-mailing-info",
  templateUrl: "./e-form-mailing-info.component.html",
})
export class EFormMailingInfoComponent {

  constructor(
    private readonly mailingInfoService: EFormMailingInfoService,
    private readonly eFormCodeProvider: EFormIdProvider,
    private readonly route: ActivatedRoute,
    private readonly notifications: Notifications
  ) {
    const self = this;

    const propertyChangeListener: PropertyChangeListener<MailingInfoFormModel> = {
      onPropertyChange(property: keyof MailingInfoFormModel, newValue: any) {
        self.model[property] = newValue as never;
        self.orderingEnabled = isMailingInfoComplete(self.model);
      }
    };

    this.formConfig = new MailingInfoFormConfig(this.model, propertyChangeListener);

  }

  private model: MailingInfoFormModel = {
    name: "",
    mailingAddress: "",
    phoneNumber: "",
    emailAddress: "",
    permission: false
  };

  readonly formConfig: MailingInfoFormConfig;

  orderingEnabled = false;

  deviceOrdered = false;

  orderDevice() {
    const eFormId = this.eFormCodeProvider.getEFormId(this.route);

    this.mailingInfoService.orderDevice(eFormId!, this.formModelToMailingInfo())
      .subscribe({
        next: () => {
          this.deviceOrdered = true;
        },
        error: () => {
          this.notifications.addNotification(
            NotificationType.ERROR,
            "mailingInfoFormDeviceOrderFailedErrorMessage"
          );
        }
      });
  }

  private formModelToMailingInfo(): MailingInfo {
    return {
      name: this.model.name,
      mailingAddress: this.model.mailingAddress,
      phoneNumber: this.model.phoneNumber,
      emailAddress: this.model.emailAddress
    };
  }
}


class MailingInfoFormConfig {

  readonly name: FormFieldConfig<MailingInfoFormModel>;
  readonly mailingAddress: FormFieldConfig<MailingInfoFormModel>;
  readonly emailAddress: FormFieldConfig<MailingInfoFormModel>;
  readonly phoneNumber: FormFieldConfig<MailingInfoFormModel>;
  readonly permission: FormFieldConfig<MailingInfoFormModel>;

  constructor(model: MailingInfoFormModel, propertyChangeListener: PropertyChangeListener<MailingInfoFormModel>) {
    this.name = {
      type: "text",
      required: true,
      label: "mailingInfoFormNameLabel",
      invalidLabel: "mailingInfoFormNameInvalidLabel",
      property: "name",
      model: model,
      isValid: () => true,
      propertyChangeListener: propertyChangeListener
    };

    this.mailingAddress = {
      type: "text",
      required: true,
      label: "mailingInfoFormMailingAddressLabel",
      invalidLabel: "mailingInfoFormMailingAddressInvalidLabel",
      property: "mailingAddress",
      model: model,
      isValid: () => true,
      propertyChangeListener: propertyChangeListener
    };

    this.emailAddress = {
      type: "text",
      required: false,
      label: "mailingInfoFormEmailAddressLabel",
      property: "emailAddress",
      model: model,
      isValid: () => true,
      propertyChangeListener: propertyChangeListener
    };

    this.phoneNumber = {
      type: "text",
      required: true,
      label: "mailingInfoFormPhoneNumberLabel",
      invalidLabel: "mailingInfoFormPhoneNumberInvalidLabel",
      property: "phoneNumber",
      model: model,
      isValid: () => true,
      propertyChangeListener: propertyChangeListener
    };

    this.permission = {
      type: "checkbox",
      required: true,
      label: "mailingInfoFormPermissionLabel",
      property: "permission",
      model: model,
      isValid: () => true,
      propertyChangeListener: propertyChangeListener
    };
  }
}

export function isMailingInfoComplete(mailingInfo: MailingInfoFormModel): boolean {
  return mailingInfo.name.length > 0
    && mailingInfo.mailingAddress.length > 0
    && mailingInfo.phoneNumber.length > 0
    && mailingInfo.permission;
}
