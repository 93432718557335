import {ErrorHandler, Injectable} from "@angular/core";
import {Notifications, NotificationType} from "./notifications/notifications";
import {RouteNavigator} from "./navigation/route-navigator.service";
import {ErrorCode, ErrorResponse} from "./error-response";
import {NavigationRoute} from "./navigation/navigation-route";
import {localizationKey, LocalizationStrings} from "../i18n/i18n-model";
import {AuthenticationService} from "../features/authentication/authentication.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {throwError} from "rxjs";

@Injectable()
export class ErrorResponseHandler {
  constructor(
    private readonly notifications: Notifications,
    private readonly routeNavigator: RouteNavigator,
    private readonly authenticationService: AuthenticationService,
    private readonly errorHandler: ErrorHandler
  ) {}

  getHandler(): (error: HttpErrorResponse) => Observable<never> {
    return (error: HttpErrorResponse) => {
      this.errorHandler.handleError(error);

      const errorResponse = toErrorResponse(error);
      errorResponse.handled = this.handleError(errorResponse);
      return throwError(errorResponse);
    };
  }

  private handleError(errorResponse: ErrorResponse): boolean {
    switch (errorResponse.errorCodes[0]) {
      case ErrorCode.AUTH_TOKEN_EXPIRED:
        return this.handleAuthenticationError("authTokenExpired");
      case ErrorCode.AUTH_AUTHENTICATE_FAILED:
        return this.handleAuthenticationError("authAuthenticateFailed");
      case ErrorCode.AUTH_ACCESS_DENIED:
        return this.handleAccessDenied();
      case ErrorCode.JOB_ALREADY_EXISTS:
        this.notifications.addNotification(NotificationType.WARNING, localizationKey("jobAlreadyExistsMessage"));
        return true;
      default:
        return false;
    }
  }

  private handleAuthenticationError(cause: keyof LocalizationStrings): boolean {
    this.authenticationService.logout();
    this.notifications.addNotification(NotificationType.WARNING, localizationKey(cause));
    this.routeNavigator.navigateTo(NavigationRoute.LOGIN);
    return true;
  }

  private handleAccessDenied(): boolean {
    this.notifications.addNotification(NotificationType.ERROR, localizationKey("authAccessDenied"));
    this.routeNavigator.navigateTo(NavigationRoute.HOME);
    return true;
  }
}

function toErrorResponse(error: HttpErrorResponse): ErrorResponse {
  const errorResponse: ErrorResponse =
    error.error instanceof ErrorEvent ? { errorCodes: [ErrorCode.NETWORK_ERROR], handled: false } : (error.error as ErrorResponse);

  if (!errorResponse.errorCodes) {
    errorResponse.errorCodes = [ErrorCode.INTERNAL_SERVER_ERROR];
  }
  return errorResponse;
}
