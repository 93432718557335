import {UserRole} from "../../authentication/authenticated-user";
import {ReportType} from "../../measurement-models";
import {ReportDownloadAction} from "./list-item.component";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";

export function getReportType(
  role: UserRole,
  reportDownloadAction: ReportDownloadAction,
  combinedReportExists: boolean,
  combinedReportEnabled: boolean
): ReportType {
  if (reportDownloadAction === "SECONDARY") {
    return "MEASUREMENT_SECONDARY_REPORT";
  }

  if (
    (role === "DATA_OFFICER" || role === "DOCTOR")
    && combinedReportExists
    && ReleaseToggleState.getInstance().isReleased("CONCAT_SAMPLES_REPORT_TO_ANALYSIS_REPORT_285")
  ) {
    return "MEASUREMENT_COMBINED_REPORT";
  }

  if (
    (role === "NURSE" || role === "ORDERING_NURSE")
    && combinedReportEnabled
    && combinedReportExists
  ) {
    return "MEASUREMENT_COMBINED_REPORT";
  }

  return "MEASUREMENT_REPORT";

}
