import { MeasurementType } from "../../measurement-models";
import { LocalizationStrings } from "../../../i18n/i18n-model";
import { BYTES_IN_GB, BYTES_IN_KB, BYTES_IN_MB } from "../../validations";

export interface MeasurementFileConfig {
  readonly allowedFileExtensions: string[];
  readonly minFileSize: number;
  readonly maxFileSize: number;
  readonly invalidFileLabel: keyof LocalizationStrings;
}

export const measurementFileConfigByType: ReadonlyMap<MeasurementType, MeasurementFileConfig> = new Map<
MeasurementType,
MeasurementFileConfig
>()
  .set("SLEEP", {
    allowedFileExtensions: ["dtx"],
    minFileSize: 100 * BYTES_IN_KB,
    maxFileSize: 100 * BYTES_IN_MB,
    invalidFileLabel: "sleepMeasurementFileInvalid",
  })
  .set("HOLTER_ECG", {
    allowedFileExtensions: ["efs", "edf", "c3s"],
    minFileSize: 5 * BYTES_IN_MB,
    maxFileSize: BYTES_IN_GB,
    invalidFileLabel: "ecgMeasurementFileInvalid",
  })
  .set("SYMPTOM_HOLTER", {
    allowedFileExtensions: ["efs", "edf", "c3s"],
    minFileSize: 5 * BYTES_IN_MB,
    maxFileSize: BYTES_IN_GB,
    invalidFileLabel: "ecgMeasurementFileInvalid",
  })
  .set("ECG_ATRIAL_FIBRILLATION", {
    allowedFileExtensions: ["efs", "edf", "c3s"],
    minFileSize: 5 * BYTES_IN_MB,
    maxFileSize: BYTES_IN_GB,
    invalidFileLabel: "ecgMeasurementFileInvalid",
  })
  .set("BLOOD_PRESSURE", {
    allowedFileExtensions: ["bpm"],
    minFileSize: 512,
    maxFileSize: 100 * BYTES_IN_MB,
    invalidFileLabel: "bpMeasurementFileInvalid",
  });
