<div *ngIf="!!map" style="width: 200px">
  <div *ngFor="let item of map | keyvalue">
    <span class="mr-1 font-weight-light">{{ item.key }}:</span>
    <div *ngIf="Array.isArray(item.value); then arrayTemplate; else notArrayTemplate"></div>
    <ng-template #arrayTemplate>
      <div *ngFor="let arrayValue of item.value" class="text-break">{{ arrayValue }}</div>
    </ng-template>
    <ng-template #notArrayTemplate>
      <span class="text-break">{{ item.value }}</span>
    </ng-template>
  </div>
</div>
