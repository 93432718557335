import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FoundMeasurement} from "./found-measurement";
import {ApiClient} from "../../tools/api-client";
import {DateTimeSerializationService} from "../../tools/formatting/date-time-serialization.service";
import {NurseHospital} from "./nurseHospital";
import {DateTime} from "luxon";
import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";
import {map} from "rxjs/operators";

@Injectable()
export class ListService {

  constructor(
    private apiClient: ApiClient,
    private dateTimeSerializationService: DateTimeSerializationService) {
  }

  getMeasurementsBetween(from: DateTime, to: DateTime): Observable<FoundMeasurement[]> {
    return this.apiClient.get<FoundMeasurement[]>("/api/measurements/list",
      {
        from: this.dateTimeSerializationService.serializeToUtcIsoString(from)!,
        to: this.dateTimeSerializationService.serializeToUtcIsoString(to)!,
      })
      .pipe(map(measurement =>  recursiveNullToUndefined(measurement)));
  }

  getNurseHospital(): Observable<NurseHospital> {
    return this.apiClient.get<NurseHospital>("/api/admin/nurse/hospital");
  }
}
