import { ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class EFormIdProvider {
  constructor() {}

  getEFormId(route: ActivatedRoute): string | undefined {
    const eFormId = route.snapshot.paramMap.get("eFormId");

    if (eFormId === null) {
      return undefined;
    }

    return eFormId;
  }
}
