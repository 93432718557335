export interface ErrorResponse {
  errorCodes: ErrorCode[];
  handled: boolean;
}

export enum ErrorCode {
  NETWORK_ERROR = "networkError",
  INTERNAL_SERVER_ERROR = "internalServerError",

  /*
   * IMPORTANT:
   * keep values identical to backend's upload request validation error codes
   */
  UPLOAD_FAILED = "uploadFailed",
  CODE_LENGTH_INVALID = "codeLengthInvalid",
  CODE_NOT_UNIQUE = "codeNotUnique",
  FILE_NAME_NOT_UNIQUE = "fileNameNotUnique",
  FILE_SIZE_INVALID = "fileSizeInvalid",
  FILE_EXTENSION_INVALID = "fileExtensionInvalid",
  FILE_NAME_INVALID = "fileNameInvalid",
  GENDER_INVALID = "genderInvalid",
  AGE_INVALID = "ageInvalid",
  HEIGHT_INVALID = "heightInvalid",
  WEIGHT_INVALID = "weightInvalid",
  PROFESSION_INVALID = "professionInvalid",
  AUTH_INVALID_CREDENTIALS = "authInvalidCredentials",
  AUTH_ACCESS_DENIED = "authAccessDenied",
  AUTH_TOKEN_EXPIRED = "authTokenExpired",
  AUTH_AUTHENTICATE_FAILED = "authAuthenticateFailed",
  MEASUREMENT_STATUS_INVALID = "measurementStatusInvalid",
  ECG_DATA_OFFICER_FILESHARE_FOLDER_INVALID = "ecgDataOfficerFileshareFolderInvalid",
  INVALID_OTP = "invalidOneTimePassword",
  INVALID_DEVICE_SERIAL_NUMBER = "invalidDeviceSerialNumber",
  INITIATE_FAILED = "initiateFailed",
  ORDER_FAILED = "orderFailed",
  PATIENT_FORM_TEMPLATE_NOT_FOUND = "patientFormTemplateNotFound",
  EFORM_CODE_NOT_FOUND = "eFormCodeMissing",
  INVALID_CAPTCHA_TOKEN = "invalidCaptchaToken",
  MEASUREMENT_NOT_IN_INITIATED_STATUS ="measurementNotInInitiatedStatus",
  PARAMETER_MISSING = "parameterMissing",
  FILE_ALREADY_UPLOADED = "fileAlreadyUploaded",
  REPORT_NOT_FOR_THIS_MEASUREMENT = "reportNotForThisMeasurement",
  DOCTOR_DOES_NOT_HAVE_ACCESS_TO_EXTERNAL_ANALYSIS = "doctorDoesNotHaveAccessToExternalAnalysis",
  JOB_ALREADY_EXISTS = "jobAlreadyExists",
  ECG_SAMPLES_DOCUMENT_MISSING = "ecgSamplesDocumentMissing"
}

export function codesFromResponse(errorResponse: ErrorResponse): ErrorCode[] {
  return errorResponse.errorCodes || [];
}

export function singleCodeFromResponse(errorResponse: ErrorResponse): ErrorCode {
  return codesFromResponse(errorResponse)[0];
}
