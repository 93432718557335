import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";
import { ActionApiParams, ActionApiResponse } from "./actions";

@Injectable()
export class ActionLogService {
  constructor(private apiClient: ApiClient) {}

  getActions(params: ActionApiParams): Observable<ActionApiResponse> {
    const _params: { [param: string]: string | string[] } = {};
    if (params.hospital) {
      _params.hospital = params.hospital;
    }
    if (params.action) {
      _params.action = params.action;
    }
    if (params.limit) {
      _params.limit = params.limit;
    }
    if (params.page) {
      _params.page = params.page;
    }
    if (params.role) {
      _params.role = params.role;
    }
    if (params.timeFrom) {
      _params.timeFrom = params.timeFrom;
    }
    if (params.timeTo) {
      _params.timeTo = params.timeTo;
    }
    if (params.username) {
      _params.username = params.username;
    }
    if (params.sort) {
      _params.sort = params.sort;
    }

    return this.apiClient.get<ActionApiResponse>("/api/actions", _params);
  }
}
