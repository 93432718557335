import { I18nService } from "./i18n.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "localize",
  pure: false,
})
export class LocalizePipe implements PipeTransform {
  constructor(private readonly i18nService: I18nService) {}

  transform(value: any, args?: any): any {
    return this.i18nService.getLocalizedString(value);
  }
}
