import {ListService} from "./list.service";
import {FoundMeasurement} from "./found-measurement";
import {ApiClient} from "../../tools/api-client";
import {DateTimeSerializationService} from "../../tools/formatting/date-time-serialization.service";
import {Observable, of, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {NurseHospital} from "./nurseHospital";
import {DateTime} from "luxon";


@Injectable()
export class ListServiceSpy extends ListService {
  succeeds = true;

  listToLoad: FoundMeasurement[] = [];
  from?: DateTime;
  to?: DateTime;
  nurseHospital?: NurseHospital;

  constructor() {
    super({} as ApiClient, {} as DateTimeSerializationService);
  }

  getMeasurementsBetween(from: DateTime, to: DateTime): Observable<FoundMeasurement[]> {
    this.from = from;
    this.to = to;
    return this.succeeds ? of(this.listToLoad) : throwError("Fake loading failed!");
  }

  getNurseHospital(): Observable<NurseHospital> {
    return of(this.nurseHospital!);
  }
}
