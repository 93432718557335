import {emptyEcgSymptom, MAX_ECG_SYMPTOMS, PatientData} from "./upload/upload-model";

export function addEmptyEcgSymptoms(data: PatientData): PatientData {
  if (!data.symptoms) {
    data.symptoms = [];
  }

  for (let i = data.symptoms.length; i < MAX_ECG_SYMPTOMS; i++) {
    data.symptoms.push(emptyEcgSymptom());
  }

  return data;
}


export const MAX_CHARACTERS_IN_TEXT = 50000;
