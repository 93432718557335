import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FoundMeasurement} from "../found-measurement";
import {ApiClient} from "../../../tools/api-client";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class RestoreMeasurementService {

  constructor(private readonly apiClient: ApiClient) {
  }

  restore(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient.put<FoundMeasurement>(
      `/api/measurements/${measurementId}/restore`
    )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }

}
