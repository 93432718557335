<div data-testid="loading-spinner" *ngIf="showLoadingSpinner">
  <loading-indicator></loading-indicator>
</div>
<div data-testid="initialisation-error" class="alert alert-danger ml-2"
     *ngIf="showError">{{localizationKey("uploadGetContextFailed") | localize}}</div>
<div data-testid="upload-form" *ngIf="showForm" class="container">
  <form class="form-striped">
    <form-field [config]="formConfig!.id"></form-field>
    <form-field [config]="formConfig!.type"></form-field>

    <form-field-wrapper *ngIf="shouldShowFileUpload()" [config]="formConfig!.file">
      <upload-measurement-file
        class="form-control-plaintext"
        [measurementId]="measurementId"
        [measurementType]="mapNurseMeasurementTypeToMeasurementType(uploadFormModel.measurementType)!"
        [measurementCode]="uploadFormModel.measurementCode"
        (fileUploaded)="onPropertyChange(formConfig!.file.property, $event.name)"
        (sessionId)="uploadFormModel.measurementSessionId=$event">
      </upload-measurement-file>
    </form-field-wrapper>

    <hr/>

    <app-form-selector
      [type]="mapNurseMeasurementTypeToMeasurementType(uploadFormModel.measurementType)!"
      [formConfig]="formConfig!"
    ></app-form-selector>

    <ng-container *ngIf="reportLanguageEnabled && isTypeSelected()">
      <hr/>
      <form-field [config]="formConfig!.reportLanguage"></form-field>
    </ng-container>

    <hr/>

    <div class="row" *ngIf="isTypeSelected()">
      <action-button
        buttonLabel="uploadFormButtonLabel"
        actionInProgressLabel="uploadFormButtonInProgressLabel"
        buttonType="button"
        [actionInProgress]="uploadInProgress$ | async"
        [buttonDisabled]="(codeCheckInProgress$ | async) || measurementCodeExists"
        (buttonClick)="onUploadClick()"
        [testId]="'submit-button'">
      </action-button>

      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ml-2">
        {{ errorMessage | localize }}
      </span>
    </div>
  </form>
</div>
