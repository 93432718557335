import {MeasurementType} from "../measurement-models";

export interface MeasurementExistsResponse {
  readonly exists: boolean;
}

export interface UploadRequest {
  readonly code: string;
  readonly type: MeasurementType;
  readonly measurementDataFileName: string;
  readonly measurementStartTime?: string;
  readonly patientData: PatientData;
  readonly sessionId: number;
  readonly nurseComments?: string;
  readonly reportLanguage?: string;
}

export interface UploadInitiatedData {
  readonly measurementId: number;
  readonly fileName: string;
  readonly measurementStartTime?: string;
  readonly patientData: PatientData;
  readonly sessionId: number;
  readonly nurseComments?: string;
  readonly reportLanguage?: string;
}

export interface CreateSessionRequest {
  readonly code: string;
  readonly type: MeasurementType;
  readonly fileName: string;
  readonly fileSize: number;
  readonly measurementId?: number;
}

export interface UploadSession {
  readonly chunkSize: number;
  readonly sessionId: number;
}

export interface PatientData {
  // INTRODUCTORY COMMON

  gender: Gender;
  age: number | undefined;
  height: number | undefined;
  weight: number | undefined;
  overweight: Overweight;

  // SLEEP

  profession: string | undefined;
  hypertension: boolean | undefined;
  diabetes: boolean | undefined; // *** also for BLOOD PRESSURE
  overbite: boolean | undefined;
  cloggedNasalPassage: boolean | undefined;
  allergicRhinitis: boolean | undefined;
  smallChin: boolean | undefined;

  smoking: Smoking; // *** also for BLOOD PRESSURE
  useOfSleepingPills: boolean | undefined;
  alcoholConsumption: string | undefined;

  morningHeadaches: Frequency;
  breathingLoss: Frequency;
  fluttering: Frequency;
  snoring: Frequency;
  restlessSleep: Frequency;
  daytimeSleepiness: Frequency;
  dryMouthInMorning: Frequency;
  needToUrinate: Frequency;

  sittingReading: Probability;
  watchingTelevision: Probability;
  sittingPassive: Probability;
  travelInCarForAnHour: Probability;
  lyingDownInAfternoon: Probability;
  sittingTalkingToSomeone: Probability;
  sitInPeaceAfterNonAlcoholicLunch: Probability;
  sitInCarAfterItHasStoppedForFewMinutes: Probability;

  sleepQuality: SleepQuality;
  fallingAsleepDuration: FallingAsleepDuration;
  sleepHours: number | undefined;
  minutesAwakeAfterMeasurementStart: number | undefined;
  awakeningsDuringMeasurement: number | undefined;

  sleepDiary: string | undefined;

  // ECG COMMON
  abnormalHeartbeats: Frequency;
  slowPulse: Frequency;
  fastPulse: Frequency;
  palpitation: Frequency;
  irregularPulse: Frequency;
  dizziness: Frequency;
  lossOfConsciousness: Frequency;
  blackouts: Frequency;
  chestPain: Frequency;
  shortnessOfBreath: Frequency;

  diagnosedHeartDisease: string | undefined; // *** also for BLOOD PRESSURE

  // ECG HOLTER
  diary: string | undefined;

  // SYMPTOM HOLTER
  symptoms: EcgSymptom[];

  // BLOOD PRESSURE
  waistline: number | undefined;
  cholesterol: number | undefined;
  coronaryArteryDisease: boolean | undefined;
  coronaryInsufficiency: boolean | undefined;
  cerebrovascularDisorders: boolean | undefined;
  kidneyDisease: boolean | undefined;
  parentsHeartAttackUnder60: boolean | undefined;
  parentsStrokeUnder75: boolean | undefined;
  sleepMeasurementNight: SleepMeasurementNight | undefined;
  goToBedTime: string | undefined;
  wakeUpTime: string | undefined;

  // FINAL COMMON
  currentMedication: string | undefined;
  reasonForStudy: string | undefined;
  patientFeedback: string | undefined;
}

export type Overweight = "NO" | "LITTLE" | "LOT" | undefined;
export type Smoking = "NO" | "SOMETIMES" | "DAILY" | undefined;
export type Frequency = "DAILY" | "WEEKLY" | "MONTHLY" | "RARELY" | undefined;
export type Probability = "ZERO" | "SMALL" | "MODERATE" | "LARGE" | undefined;
export type Gender = "F" | "M" | undefined;
export type SleepQuality = "BETTER" | "SAME" | "WORSE" | undefined;
export type FallingAsleepDuration = "SHORTER" | "SAME" | "LONGER" | undefined;
export type SleepMeasurementNight = "WELL" | "POORLY" | "NOT_AT_ALL" | undefined;

export interface EcgSymptom {
  time: string | undefined;
  description: string | undefined;
}

export const MAX_ECG_SYMPTOMS = 8;

const EMPTY_PATIENT_DATA: PatientData = {
  gender: undefined,
  age: undefined,
  height: undefined,
  weight: undefined,
  overweight: undefined,
  profession: undefined,

  hypertension: undefined,
  diabetes: undefined,
  overbite: undefined,
  cloggedNasalPassage: undefined,
  allergicRhinitis: undefined,
  smallChin: undefined,

  smoking: undefined,
  useOfSleepingPills: undefined,
  alcoholConsumption: undefined,

  morningHeadaches: undefined,
  breathingLoss: undefined,
  fluttering: undefined,
  snoring: undefined,
  restlessSleep: undefined,
  daytimeSleepiness: undefined,
  dryMouthInMorning: undefined,
  needToUrinate: undefined,

  sittingReading: undefined,
  watchingTelevision: undefined,
  sittingPassive: undefined,
  travelInCarForAnHour: undefined,
  lyingDownInAfternoon: undefined,
  sittingTalkingToSomeone: undefined,
  sitInPeaceAfterNonAlcoholicLunch: undefined,
  sitInCarAfterItHasStoppedForFewMinutes: undefined,

  sleepQuality: undefined,
  fallingAsleepDuration: undefined,
  sleepHours: undefined,
  minutesAwakeAfterMeasurementStart: undefined,
  awakeningsDuringMeasurement: undefined,

  sleepDiary: undefined,

  abnormalHeartbeats: undefined,
  slowPulse: undefined,
  fastPulse: undefined,
  palpitation: undefined,
  irregularPulse: undefined,
  dizziness: undefined,
  lossOfConsciousness: undefined,
  blackouts: undefined,
  chestPain: undefined,
  shortnessOfBreath: undefined,

  diagnosedHeartDisease: undefined,

  diary: undefined,

  symptoms: [],

  waistline: undefined,
  cholesterol: undefined,
  coronaryArteryDisease: undefined,
  coronaryInsufficiency: undefined,
  cerebrovascularDisorders: undefined,
  kidneyDisease: undefined,
  parentsHeartAttackUnder60: undefined,
  parentsStrokeUnder75: undefined,
  sleepMeasurementNight: undefined,
  goToBedTime: undefined,
  wakeUpTime: undefined,

  currentMedication: undefined,
  reasonForStudy: undefined,
  patientFeedback: undefined,
};

export function emptyPatientData(): PatientData {
  return {...EMPTY_PATIENT_DATA};
}

export function emptyEcgSymptom(): EcgSymptom {
  return {
    description: undefined,
    time: undefined,
  };
}
