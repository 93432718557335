import {Component} from "@angular/core";
import "@babel/polyfill";
import {AuthenticatedUser} from "./features/authentication/authenticated-user";
import {AuthenticationService} from "./features/authentication/authentication.service";
import {NavigationRoute} from "./tools/navigation/navigation-route";
import {environment} from "../environments/environment";
import {localizationKey} from "./i18n/i18n-model";
import {VersionMismatch} from "./version-mismatch";
import {RouteListener} from "./route-listener";
import {ReloadApp} from "./reload-app";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  currentUser: AuthenticatedUser | undefined;
  NavigationRoute = NavigationRoute;

  showNavBarAndFooter = !environment.eforms;

  constructor(
    private authenticationService: AuthenticationService,
    private reloadApp: ReloadApp,
    private routeListener: RouteListener,
    private versionMismatch: VersionMismatch,
  ) {
    this.authenticationService.authenticatedUser$.subscribe((user) => {
      this.currentUser = user;
      environment.language = user ? user.language : environment.defaultLanguage;
    });

    this.routeListener.onRoute().subscribe(() => {
      if (this.versionMismatch.isMismatch()) {
        this.reloadApp.reload();
      }
    });
  }

  isNurse(): boolean {
    return !!this.currentUser && this.currentUser.role === "NURSE";
  }

  isOrderingNurse(): boolean {
    return !!this.currentUser && this.currentUser.role === "ORDERING_NURSE";
  }


  isDataOfficer(): boolean {
    return !!this.currentUser && this.currentUser.role === "DATA_OFFICER";
  }

  protected readonly localizationKey = localizationKey;
}
