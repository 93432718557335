import {SelectionOption, SelectionOptions} from "../tools/form/form-field.component";
import {getMeasurementTypeLocalizationKey, MeasurementType} from "./measurement-models";
import {localizationKey} from "../i18n/i18n-model";
import {NurseMeasurementType} from "./initiate-measurement/initiate-measurement-data";
import {HospitalMeasurementTypes} from "./hospitals/edit-hospital/hospital-measurement-types.service";
import {ReleaseToggleState} from "../tools/release-toggles/release-toggle-state";

export const measurementTypeOptions =
  SelectionOptions.with<MeasurementType>(
    {
      value: "SLEEP",
      text: localizationKey(getMeasurementTypeLocalizationKey("SLEEP")),
    },
    {
      value: "HOLTER_ECG",
      text: localizationKey(getMeasurementTypeLocalizationKey("HOLTER_ECG")),
    },
    {
      value: "ECG_ATRIAL_FIBRILLATION",
      text: localizationKey(getMeasurementTypeLocalizationKey("ECG_ATRIAL_FIBRILLATION")),
    },
    {
      value: "SYMPTOM_HOLTER",
      text: localizationKey(getMeasurementTypeLocalizationKey("SYMPTOM_HOLTER")),
    },
    {
      value: "BLOOD_PRESSURE",
      text: localizationKey(getMeasurementTypeLocalizationKey("BLOOD_PRESSURE")),
    }
  );

export function getTypeSelectionOptions(allowedTypes: HospitalMeasurementTypes): SelectionOptions<NurseMeasurementType> {

  const options: SelectionOption<NurseMeasurementType>[] = measurementTypeOptions
    .items
    .filter(selectionOption =>
      allowedTypes.types.includes(selectionOption.value)
    );

  if (
    allowedTypes.homeTypes.includes("HOLTER_ECG")
    && ReleaseToggleState.getInstance().isReleased("HOME_HOLTER_LENGTH_342")
  ) {
    options.push(
      {
        value: "HOME_HOLTER_24H",
        text: localizationKey("measurementTypeLabelHomeHolter24h"),
      },
      {
        value: "HOME_HOLTER_48H",
        text: localizationKey("measurementTypeLabelHomeHolter48h"),
      },
      {
        value: "HOME_HOLTER_7D",
        text: localizationKey("measurementTypeLabelHomeHolter7d"),
      },
    );
  }

  return {
    items: options,
    isColumnLayout: measurementTypeOptions.isColumnLayout
  };
}

