<div class="container-fluid container--available-viewport-height" >
  <h3>Comments</h3>
  <div>{{"measurementsGridColumnLabelCode" | localize}}: {{code}}</div>
  <div>{{"measurementsGridColumnLabelHospitalName" | localize}}: {{hospitalName}}</div>
  <div class="h-75 overflow-auto form-control scroll-to-bottom" >
    <div class="form-grid form-grid-striped text-left">
    <div *ngFor="let comment of comments" class="row col-12">
      <div class=" pl-3" data-testId="comment-item">
        <div><b>{{comment.authorUsername}}</b> {{comment.time | appLocalDateTime}}</div>
        <div>{{comment.text}}</div>
      </div>
    </div>
  </div>
  </div>
  <div class="h-25 text-right">
    <div class=" mt-3 mb-3 ">
      <textarea data-testid="add-comment-input" class=" form-control" [(ngModel)]="commentInput"> </textarea>
    </div>
    <button class="btn btn-primary" data-testid="add-comment-button" (click)="add()">Comment</button>
    </div>


</div>
