import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {MeasurementType} from "../../measurement-models";
import {measurementFileConfigByType} from "./measurement-file-config";
import {UploadService} from "../upload.service";
import {UploadFileValidator} from "./upload-file-validator";
import {LocalizationStrings} from "../../../i18n/i18n-model";
import {ErrorCode, singleCodeFromResponse} from "../../../tools/error-response";
import {ChunkedFileUploader} from "./chunked-file-uploader";
import {Subscription} from "rxjs";
import {formatUploadPercentageStyleWidth, formatUploadPercentageText} from "./format-upload-percentage";
import {I18nService} from "../../../i18n/i18n.service";
import {replaceAnchors} from "../../analize/ecg/generators/findings-generator/replace-anchors";
import {formatDateFromIso} from "../../../tools/date-time-utils";
import {getFirstSelectedFile} from "../../../tools/form/get-first-selected.file";

@Component({
  selector: "upload-measurement-file",
  templateUrl: "./upload-measurement-file.component.html",
})
export class UploadMeasurementFileComponent implements OnChanges {
  constructor(
    private readonly uploadService: UploadService,
    private readonly uploadFileValidator: UploadFileValidator,
    private readonly i18nService: I18nService
  ) {
  }

  @Input()
    measurementType: MeasurementType = "SLEEP";

  @Input()
    measurementCode = "";

  @Input()
    measurementId?: number;

  @Output()
    fileUploaded = new EventEmitter<File>();

  @Output()
    sessionId = new EventEmitter<number>();

  chunkedFileUploader: ChunkedFileUploader = new ChunkedFileUploader();

  isFileValid?: boolean;

  measurementFileConfig = measurementFileConfigByType.get(this.measurementType)!;

  private errorMessages = new Map<ErrorCode, keyof LocalizationStrings>()
    .set(ErrorCode.FILE_NAME_NOT_UNIQUE, "uploadFailedFileNameNotUnique")
    .set(ErrorCode.FILE_ALREADY_UPLOADED, "uploadFileFailedFileAlreadyUploaded");

  private uploadPercentageOverride?: number;

  errorMessage?: string;

  uploadSubscription?: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.measurementType) {
      this.measurementFileConfig = measurementFileConfigByType.get(this.measurementType)!;
      this.reset();
    }
  }

  upload(file: File): void {
    this.errorMessage = undefined;

    if (!this.validateFile(file)) {
      return;
    }

    this.uploadSubscription = this.chunkedFileUploader.upload(file,
      () => this.uploadService.createSession({
        code: this.measurementCode,
        type: this.measurementType,
        fileName: file.name,
        fileSize: file.size,
        measurementId: this.measurementId
      }),
      (sessionId, chunk, start) =>
        this.uploadService.uploadChunk({
          sessionId: sessionId,
          chunk: chunk,
          start: start,
          fileSize: file.size
        }))
      .subscribe(() => {
      },
      (errorResponse) => {
        const errorCode = singleCodeFromResponse(errorResponse);

        if (errorCode === ErrorCode.FILE_ALREADY_UPLOADED) {
          this.uploadPercentageOverride = 100;

          this.errorMessage = replaceAnchors(
            this
              .i18nService
              .getLocalizedString(this
                .errorMessages
                .get(errorCode)!),

            {
              uploadingNurse: errorResponse.uploadingNurse[0],
              measurementCode: errorResponse.measurementCode[0],
              uploadTime: formatDateFromIso(errorResponse.uploadTime[0])
            }
          );


        } else {
          this.errorMessage = this.i18nService.getLocalizedString(this.errorMessages
            .get(errorCode)
              || "uploadFileFailed");
        }
      },
      () => {
        this.fileUploaded.emit(file);
        this.sessionId.emit(this.chunkedFileUploader.sessionId);
      });
  }

  cancelUpload() {
    this.uploadSubscription?.unsubscribe();
    this.reset();
  }

  retryUpload() {
    const file = this.chunkedFileUploader.file!;
    this.reset();
    this.upload(file);
  }

  private validateFile(file: File): boolean {
    this.isFileValid = this.uploadFileValidator.validate(file, this.measurementType);
    return this.isFileValid;
  }

  getAllowedExtension(): string | undefined {
    const allowedExtensions = this.measurementFileConfig.allowedFileExtensions.map((extension) => "." + extension).join(",");
    return this.measurementFileConfig.allowedFileExtensions == undefined ? undefined : allowedExtensions;
  }

  progressBarShouldBeGreen() {
    return this.chunkedFileUploader.isComplete;
  }

  uploadPercentageText() {
    return formatUploadPercentageText(this.uploadPercentage());
  }

  uploadPercentageStyle() {
    return formatUploadPercentageStyleWidth(this.uploadPercentage());
  }

  private uploadPercentage() {
    return this.uploadPercentageOverride
      || this.chunkedFileUploader.uploadedPercentage;
  }

  private reset() {
    this.isFileValid = undefined;
    this.errorMessage = undefined;
    this.uploadPercentageOverride = undefined;
    this.chunkedFileUploader.reset();
  }

  handleFileSelection($event: Event) {
    this.upload(getFirstSelectedFile($event));
  }
}
