import {Injectable} from "@angular/core";
import { PDFDocument } from "pdf-lib";
import {map, mergeMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

export interface OrderPdfData {
  type: string;
  code: string;
  initiatedTime: string;
  operatorHospital: string;
  orderingHospital: string;
  uniqueId: string;
  ssn: string;
  name: string;
  pdf: string;
  address?: string;
  city?: string;
  operatorAppointmentDescription?: string;
  postalCode?: string;
}

@Injectable()
export class OrderPdfGeneratorService {

  generate(data: OrderPdfData) {
    return fromPromise(PDFDocument.load(data.pdf))
      .pipe(mergeMap(
        pdfDoc => {
          const form = pdfDoc.getForm();
          form.getTextField("patient_name").setText(data.name);
          form.getTextField("patient_ssn").setText(data.ssn);
          form.getTextField("patient_id").setText(data.code);
          form.getTextField("unique_id").setText(data.uniqueId);
          form.getTextField("public_name").setText(data.operatorHospital || "");
          form.getTextField("street_adress").setText(data.address || "");
          form.getTextField("postal_code").setText(data.postalCode || "");
          form.getTextField("city").setText(data.city || "");
          form.getTextField("operator_appointment_description").setText(data.operatorAppointmentDescription || "");
          form.getTextField("init_time").setText(data.initiatedTime);
          form.getTextField("measurement_type").setText(data.type);
          return fromPromise(pdfDoc.save());
        }))
      .pipe(map(pdf => {
        const file = new Blob([pdf], {type: "application/pdf"});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      );
  }

}
