import {DateTime} from "luxon";

const dateTimeFormat = "dd.MM.yyyy HH:mm";
const dateFormat = "dd.MM.yyyy";
const utcIsoFormat = "yyyy-MM-dd'T'HH:mm:ss.u'Z'";
const utcIsoFormatShort = "yyyy-MM-dd'T'HH:mm:ss'Z'";
const offsetIsoFormat = "yyyy-MM-dd'T'HH:mm:ss.uZZ";
const offsetIsoFormatShort = "yyyy-MM-dd'T'HH:mm:ssZZ";
const isoFormatShortLocal = "yyyy-MM-dd'T'HH:mm:ss";

export type DateTimeString = string | null | undefined;

export enum FormatType {
  LOCAL,
  UTC,
}

export function formatDateTime(isoDateTime: DateTimeString, formatType: FormatType = FormatType.LOCAL): string {
  return format(isoDateTime, formatType, dateTimeFormat);
}

export function formatDate(dateTime: DateTime, formatType: FormatType = FormatType.LOCAL): string {
  return format(dateTime.toISO(), formatType, dateFormat);
}

export function formatDateFromIso(isoDateTime: string, formatType: FormatType = FormatType.LOCAL): string {
  return format(isoDateTime, formatType, dateFormat);
}

function format(isoDateTime: DateTimeString, formatType: FormatType = FormatType.LOCAL, _format: string): string {
  if (!isoDateTime) {
    return "";
  }

  return toDateTime(isoDateTime, formatType)?.toFormat(_format) || isoDateTime;
}

function toDateTime(isoDateTime: string, formatType: FormatType = FormatType.LOCAL): DateTime | undefined {
  const dateTime = createDateTime(isoDateTime);

  if (!dateTime) {
    return undefined;
  }

  return formatType === FormatType.LOCAL ? dateTime : dateTime.toUTC();
}

export function canParseDateTime(isoDateTime: string) {
  return createDateTime(isoDateTime)?.isValid === true;
}

function createDateTime(isoDateTime: string) {
  const dateTimes = [utcIsoFormat, utcIsoFormatShort]
    .map(format =>
      DateTime.fromFormat(isoDateTime, format, {zone: "UTC"}))
    .concat(
      [offsetIsoFormatShort, offsetIsoFormat, isoFormatShortLocal]
        .map(format => DateTime.fromFormat(isoDateTime, format))
    );

  return dateTimes
    .find(dateTime => dateTime.isValid)
    ?.toLocal();
}
