import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FoundMeasurement} from "../found-measurement";
import {Observable, Subject} from "rxjs";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {ReportService} from "./report.service";
import {finalize} from "rxjs/operators";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../../tools/error-response";
import {localizationKey, LocalizationStrings} from "../../../i18n/i18n-model";
import {ReportUploadItem} from "./report-upload-item";
import {getFirstSelectedFile} from "../../../tools/form/get-first-selected.file";

@Component({
  selector: "report-upload",
  templateUrl: "./report-upload.component.html",
})
export class ReportUploadComponent {
  constructor(private readonly reportService: ReportService, private readonly notifications: Notifications) {
  }

  @Input()
    item: FoundMeasurement | undefined;
  @Output()
    fileUploaded: EventEmitter<ReportUploadItem> = new EventEmitter<ReportUploadItem>();
  @Output()
    fileUploadCanceled: EventEmitter<void> = new EventEmitter<void>();

  file: File | undefined;
  private readonly uploadInProgress = new Subject<boolean>();
  readonly uploadInProgress$: Observable<boolean> = this.uploadInProgress.asObservable();

  onFileSelected(file?: File) {
    this.file = file;
  }

  onUploadButtonClick() {
    if (!this.file) {
      throw Error("File not selected");
    }

    if (!this.item) {
      throw Error("Item not set");
    }

    this.uploadInProgress.next(true);

    this.reportService
      .upload(this.item.id, this.file)
      .pipe(finalize(() => this.uploadInProgress.next(false)))
      .subscribe(
        (listItem) => {
          this.fileUploaded.emit({
            measurement: listItem,
            file: this.file!,
          });
        },
        (errorResponse: ErrorResponse) =>
          this.notifications.addNotification(
            NotificationType.ERROR,
            localizationKey(this.errorMessages.get(singleCodeFromResponse(errorResponse)) || "reportUploadFailed")
          )
      );
  }

  private readonly errorMessages = new Map<ErrorCode, keyof LocalizationStrings>()
    .set(ErrorCode.MEASUREMENT_STATUS_INVALID, "reportUploadMeasurementStatusInvalid")
    .set(ErrorCode.FILE_NAME_INVALID, "reportUploadMeasurementFileNameInvalid")
    .set(ErrorCode.REPORT_NOT_FOR_THIS_MEASUREMENT, "reportUploadFileNotForThisMeasurement");

  onCancelButtonClick() {
    this.fileUploadCanceled.emit();
  }

  protected readonly getFirstSelectedFile = getFirstSelectedFile;
}
