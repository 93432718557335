<select [ngModel]="selectedDoctorId" (ngModelChange)="doctorSelected(+$event)" data-testId="doctor-select">
  <ng-container *ngIf="isChainFeatureReleased()">
    <option *ngIf="chainName !== undefined && chainName != null" [value]="CHAIN_OPTION_VALUE">
      {{ chainName }}
    </option>
  </ng-container>
  <option *ngFor="let doctor of doctorSelectorService.doctors" [value]="doctor.id">
    {{ doctor.firstName }} {{ doctor.lastName }}
    <span *ngIf="markCurrentDoctor && currentlyAssignedDoctor && currentlyAssignedDoctor.id === doctor.id">
      ({{ "assignDoctorSelectorLabelCurrent" | localize }})
    </span>
  </option>
</select>
