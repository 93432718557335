import {Injectable} from "@angular/core";
import {LanguageProvider, toLanguageCode} from "./language-provider";
import {LOCALIZATIONS} from "./localizations";
import {LocalizationStrings} from "./i18n-model";

@Injectable()
export class I18nService {
  constructor(private readonly languageProvider: LanguageProvider) {
  }

  getLocalizedString(key: keyof LocalizationStrings, languageString: string | undefined = undefined): string {

    const overrideReportLanguage = shouldIgnoreReportLanguageForKey(key);
    const localizations = LOCALIZATIONS.get(
      languageString
        ? toLanguageCode(languageString)
        : this.languageProvider.getLanguage(overrideReportLanguage)
    );

    if (localizations === undefined) {
      return key;
    }

    return localizations[key] || key;
  }
}

export function shouldIgnoreReportLanguageForKey(key: keyof LocalizationStrings): boolean {

  const regex = /^navigation|^footer|Button|^changePasswordLabel$|^changeLanguageLabel$/;

  return regex.test(key);
}
