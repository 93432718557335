export const ALL_RELEASE_TOGGLE_NAMES = [
  "REPORT_LANGUAGE_141",
  "IMPROVED_LIST_STYLE",
  "DO_UPLOADS_INITIATED_192",
  "GENERATE_PATIENT_STATUS_COMMENTS_227",
  "GENERATE_FINDINGS_233",
  "GENERATE_DIAGNOSIS_246",
  "FINDINGS_GENERATOR_VALUES_OUTDATED_247",
  "ADD_LESS_THAN_0_1_RULES_268",
  "VENTRICULAR_TACHYCARDIA_FINDINGS_FIELD_267",
  "UPLOAD_FORM_TEXT_AREAS_AUTO_EXPANDING_263",
  "ADD_NEW_ATRIAL_TACHYCARDIAS_RULES_269",
  "PATIENT_ID_MUST_NOT_CONTAIN_SSN_241",
  "TRIM_SPACES_ON_PASSWORD_CHANGE_265",
  "FORMAT_FINDINGS_VALUES_271",
  "NURSE_DELETES_INITIATED_MEASUREMENT_277",
  "EDIT_HOLTER_TYPE_16",
  "REASSIGN_DOCTOR_276",
  "SHOW_SYMPTOM_CHARACTER_COUNTER_TO_NURSES_297",
  "PUT_HOSPITAL_IN_CHAIN_280",
  "E_FORM_LANGUAGE_SELECTOR_303",
  "NEW_E_FORM_LANDING_PAGE_DESIGN_304",
  "CONCAT_SAMPLES_REPORT_TO_ANALYSIS_REPORT_285",
  "ASSIGN_TO_CHAIN_282",
  "UPDATE_RULES_333",
  "HOME_HOLTER_PILOT_340",
  "HOME_HOLTER_LENGTH_342",
  "ENABLE_HOME_MEASUREMENT_TYPES_311",
  "HOME_MEASUREMENT_E_FORM_315"
] as const;
type ReleaseToggleNameTuple = typeof ALL_RELEASE_TOGGLE_NAMES;
export type ReleaseToggleName = ReleaseToggleNameTuple[number];

export interface ReleaseToggle {
  name: ReleaseToggleName;
  released: boolean;
}

export class ReleaseToggleState {

  private static instance: ReleaseToggleState | undefined = undefined;

  public static getInstance(): ReleaseToggleState {
    if (ReleaseToggleState.instance === undefined) {
      ReleaseToggleState.instance = new ReleaseToggleState();
    }

    return ReleaseToggleState.instance;
  }

  private readonly toggles: Map<ReleaseToggleName, boolean> = new Map();

  public setToggles(toggles: ReleaseToggle[]) {
    this.toggles.clear();
    toggles.forEach(toggle => this.toggles.set(toggle.name, toggle.released));
  }

  public isReleased(name: ReleaseToggleName): boolean {
    return this.toggles.get(name) || false;
  }

  public turnOff(name: ReleaseToggleName) {
    this.toggles.set(name, false);
  }

  public select<T>(name: ReleaseToggleName, valueIfReleased: T, valueIfNotReleased: T): T {
    return this.isReleased(name)
      ? valueIfReleased
      : valueIfNotReleased;
  }
}
