import {Component, EventEmitter, Input, Output} from "@angular/core";
import {stripNewlines} from "../strip-newlines";

@Component({
  selector: "app-generator-text-field",
  templateUrl: "./generator-text-field.component.html"
})
export class GeneratorTextFieldComponent {

  @Input()
    text?: String;

  @Output()
    onTextChange = new EventEmitter<string>();

  @Input()
    testId?: string;

  onGeneratedTextEdited(text: string) {
    // noinspection JSPrimitiveTypeWrapperUsage: new String() is needed here so angular picks up the change
    this.text = new String(stripNewlines(text));
    this.onTextChange.emit(this.text.toString());
  }

}
