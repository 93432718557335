import {ValueFindings} from "./findings-generator.component";
import {AnalysisGenerationConfig} from "../../../analysis";
import {replaceAnchors} from "./replace-anchors";
import {formatExternalFindings} from "./format/format-external-findings";
import {ExternalFindings} from "../../external-findings";
import {ReleaseToggleState} from "../../../../../tools/release-toggles/release-toggle-state";

type PrevailingRhythm = "SR" | "?" | "AF";

export function generateFindings(
  {
    externalFindings,
    analysisGenerationConfig,
    previousFindings,
    locale
  }: {
    externalFindings: ExternalFindings;
    analysisGenerationConfig: AnalysisGenerationConfig;
    previousFindings: ValueFindings;
    locale: Intl.LocalesArgument;
  }
): ValueFindings {
  const prevailingRhythmValue = calculatePrevailingRhythmValue(externalFindings.afibBurden);

  const formattedExternalFindings = formatExternalFindings(externalFindings, locale);

  return {
    prevailingRhythm: {
      value: prevailingRhythmValue,
      text: formatSentence(calculatePrevailingRhythmText(prevailingRhythmValue, analysisGenerationConfig))
    },
    averageHeartRate: {
      value: formatValue(analysisGenerationConfig.findings_averageHeartRate_value),
      text: formatSentence(analysisGenerationConfig.findings_averageHeartRate)
    },
    minimumHeartRate: {
      value: formatValue(analysisGenerationConfig.findings_minimumHeartRate_value),
      text: formatSentence(analysisGenerationConfig.findings_minimumHeartRate)
    },
    maximumHeartRate: {
      value: formatValue(analysisGenerationConfig.findings_maximumHeartRate_value),
      text: formatSentence(analysisGenerationConfig.findings_maximumHeartRate)
    },
    pausesOver2_5s: {
      value: formatValue(analysisGenerationConfig.findings_pausesOver2_5s_value),
      text: formatSentence(selectSentenceForPausesOver2_5())
    },
    ventricularBeats: {
      value: formatValue(selectValueForVentricularBeats()),
      text: formatSentence(selectSentenceForVentricularBeats())
    },
    ventricularTachycardias: {
      value: formatValue(analysisGenerationConfig.findings_ventricularTachycardias_value),
      text: formatSentence(selectSentenceForVentricularTachycardias())
    },
    atrialBeats: {
      value: formatValue(selectValueForAtrialBeats()),
      text: formatSentence(selectSentenceForAtrialBeats())
    },
    atrialTachycardias: {
      value: formatValue(analysisGenerationConfig.findings_atrialTachycardias_value),
      text: formatSentence(selectSentenceForAtrialTachycardias())
    },
    commentAtrial: {
      value: undefined,
      text: formatSentence(selectSentenceForCommentAtrial())
    },
    commentVentricular: {
      value: undefined,
      text: previousFindings.commentVentricular.text
    },
    symptomDiaryComment: {
      value: undefined,
      text: previousFindings.symptomDiaryComment.text
    },
  };

  function formatValue(sentence: string | undefined) {
    return replaceAnchors(sentence, formattedExternalFindings, "?");
  }

  function formatSentence(sentence: string | undefined) {
    if (!sentence) {
      return sentence;
    }

    return replaceAnchors(sentence, formattedExternalFindings) + " ";
  }

  function selectSentenceForPausesOver2_5() {
    switch (externalFindings.pausesOver2_5s) {
      case undefined:
        return "";
      case 0:
        return analysisGenerationConfig.findings_noPausesOver2_5s;
      default:
        return analysisGenerationConfig.findings_pausesOver2_5s;
    }
  }

  function selectValueForVentricularBeats() {
    return analysisGenerationConfig.findings_ventricularBeats_value;
  }

  function selectSentenceForVentricularBeats() {
    switch (externalFindings.ventricularBeats) {
      case undefined:
        return "";
      case 0:
        return analysisGenerationConfig.findings_noVentricularBeats;
      case 1:
        return analysisGenerationConfig.findings_oneVentricularBeat;
      default:
        return analysisGenerationConfig.findings_ventricularBeats;
    }
  }

  function selectSentenceForVentricularTachycardias() {
    return [
      selectFirstVentricularTachycardiasSentence(),
      selectSecondVentricularTachycardiasSentence()
    ]
      .filter(sentence => sentence !== undefined)
      .join(". ");
  }

  function selectFirstVentricularTachycardiasSentence() {
    switch (externalFindings.ventricularTachycardias) {
      case undefined:
        return undefined;
      case 0:
        return undefined;
      case 1: {
        if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
          if (externalFindings.ventricularTachycardiasLongestDuration === undefined
            || externalFindings.ventricularTachycardiasLongestDuration < 10) {
            return analysisGenerationConfig.findings_oneVentricularTachycardia;
          }
          return analysisGenerationConfig.findings_oneVentricularTachycardiaWithLongestDurationGreaterThan10;
        }
        return analysisGenerationConfig.findings_oneVentricularTachycardia;
      }
      default: {
        if (externalFindings.ventricularTachycardiasLongestDuration === undefined) {
          return undefined;
        } else {
          if (externalFindings.ventricularTachycardiasLongestDuration > 10) {
            if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
              return analysisGenerationConfig.findings_someVentricularTachycardiasWithLongestDurationGreaterThan10;
            }
            return analysisGenerationConfig.findings_someVentricularTachycardiasWithLongestDurationGreaterThan10_old;
          } else {
            return analysisGenerationConfig.findings_someVentricularTachycardiasWithLongestDurationNotGreaterThan10;
          }
        }
      }
    }
  }


  function selectSecondVentricularTachycardiasSentence() {
    if (externalFindings.ventricularTachycardiasMaxHeartRate === undefined
      || externalFindings.ventricularTachycardiasLongestBeatsMaxHeartRate === undefined
      || externalFindings.ventricularTachycardiasMaxHeartRateDuration === undefined
    ) {
      return undefined;
    }
    const maxHeartRateLongestBeatsMaxHeartRateInvalid: boolean = ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")
      ? externalFindings.ventricularTachycardiasMaxHeartRate < externalFindings.ventricularTachycardiasLongestBeatsMaxHeartRate
      : externalFindings.ventricularTachycardiasMaxHeartRate <= externalFindings.ventricularTachycardiasLongestBeatsMaxHeartRate;
    if (maxHeartRateLongestBeatsMaxHeartRateInvalid) {
      return undefined;
    }

    if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
      if ((externalFindings.ventricularTachycardias !== undefined && externalFindings.ventricularTachycardias > 1)
        && (externalFindings.ventricularTachycardiasMaxHeartRate === externalFindings.ventricularTachycardiasLongestBeatsMaxHeartRate
          || externalFindings.ventricularTachycardiasMaxHeartRate === externalFindings.ventricularTachycardiasLongestDurationMaxHeartRate)) {
        return analysisGenerationConfig.findings_ventricularTachycardiasMaxHrEqualsLongestDurationMaxHr;
      }
    }

    if (externalFindings.ventricularTachycardiasMaxHeartRate > externalFindings.ventricularTachycardiasLongestBeatsMaxHeartRate
      && externalFindings.ventricularTachycardiasLongestDurationMaxHeartRate !== undefined
      && externalFindings.ventricularTachycardiasMaxHeartRate > externalFindings.ventricularTachycardiasLongestDurationMaxHeartRate) {

      if (externalFindings.ventricularTachycardiasMaxHeartRateDuration <= 10) {
        return analysisGenerationConfig.findings_ventricularTachycardiasMaxHrDurationNotGreaterThan10;
      } else {
        return analysisGenerationConfig.findings_ventricularTachycardiasMaxHrDurationGreaterThan10;
      }
    }
  }


  function selectSentenceForAtrialBeats() {
    if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
      if (prevailingRhythmValue === "AF") {
        return "";
      }

    } else {
      if (prevailingRhythmValue !== "SR") {
        return "";
      }
    }

    switch (externalFindings.atrialBeats) {
      case undefined:
        return "";
      case 0:
        return analysisGenerationConfig.findings_noAtrialBeats;
      case 1:
        return analysisGenerationConfig.findings_oneAtrialBeat;
      default:
        return analysisGenerationConfig.findings_atrialBeats;
    }
  }

  function selectValueForAtrialBeats() {
    return analysisGenerationConfig.findings_atrialBeats_value;
  }

  function selectSentenceForAtrialTachycardias() {
    if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
      if (prevailingRhythmValue === "AF") {
        return "";
      }
    } else {
      if (prevailingRhythmValue !== "SR") {
        return "";
      }
    }
    return [
      selectFirstAtrialTachycardiasSentence(),
      selectSecondAtrialTachycardiasSentence()
    ]
      .filter(sentence => sentence !== undefined)
      .join(". ");
  }

  function selectFirstAtrialTachycardiasSentence() {
    switch (externalFindings.atrialTachycardias) {
      case undefined:
        return undefined;
      case 0:
        return analysisGenerationConfig.findings_noAtrialTachycardias;
      case 1:
        if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
          if (externalFindings.atrialTachycardiasLongestDuration === undefined) {
            return undefined;
          }
          if (externalFindings.atrialTachycardiasLongestDuration <= 10) {
            return analysisGenerationConfig.findings_oneAtrialTachycardiaWithLongestDurationLessOrEqualThan10;
          } else {
            return analysisGenerationConfig.findings_oneAtrialTachycardiaWithLongestDurationGreaterThan10;
          }
        } else {
          return analysisGenerationConfig.findings_oneAtrialTachycardia;
        }


      default: {
        if (externalFindings.atrialTachycardiasLongestDuration === undefined) {
          return undefined;
        }
        if (externalFindings.atrialTachycardiasLongestDuration <= 10) {
          return analysisGenerationConfig.findings_atrialTachycardias;
        } else {
          if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
            return analysisGenerationConfig.findings_atrialTachycardiasLongestDurationGreaterThan10;
          } else {
            return analysisGenerationConfig.findings_atrialTachycardiasLongestBeatsMoreThan10;
          }
        }
      }
    }
  }

  function selectSecondAtrialTachycardiasSentence() {
    if (externalFindings.atrialTachycardiasMaximumHeartRate === undefined
      || externalFindings.atrialTachycardiasLongestBeatsMaximumHeartRate === undefined
      || externalFindings.atrialTachycardiasMaximumHeartRateDuration === undefined) {
      return undefined;
    }
    const maxHeartRateLongestBeatsMaxHeartRateInvalid: boolean = ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")
      ? externalFindings.atrialTachycardiasMaximumHeartRate < externalFindings.atrialTachycardiasLongestBeatsMaximumHeartRate
      : externalFindings.atrialTachycardiasMaximumHeartRate <= externalFindings.atrialTachycardiasLongestBeatsMaximumHeartRate;
    if (maxHeartRateLongestBeatsMaxHeartRateInvalid) {
      return undefined;
    }

    if (ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
      if ((externalFindings.atrialTachycardias !== undefined && externalFindings.atrialTachycardias > 1)
        && (externalFindings.atrialTachycardiasMaximumHeartRate === externalFindings.atrialTachycardiasLongestBeatsMaximumHeartRate
          || externalFindings.atrialTachycardiasMaximumHeartRate === externalFindings.atrialTachycardiasLongestDurationMaxHeartRate)) {
        return analysisGenerationConfig.findings_atrialTachycardiasMaxHrEqualsLongestDurationMaxHr;
      }
    }
    if (externalFindings.atrialTachycardiasMaximumHeartRate > externalFindings.atrialTachycardiasLongestBeatsMaximumHeartRate
      && externalFindings.atrialTachycardiasLongestDurationMaxHeartRate !== undefined
      && externalFindings.atrialTachycardiasMaximumHeartRate > externalFindings.atrialTachycardiasLongestDurationMaxHeartRate) {
      if (externalFindings.atrialTachycardiasMaximumHeartRateDuration <= 10) {
        return analysisGenerationConfig.findings_atrialTachycardiasMaxHrDurationNotGreaterThan10;
      } else {
        return analysisGenerationConfig.findings_atrialTachycardiasMaxHrDurationGreaterThan10;
      }
    }
  }

  function selectSentenceForCommentAtrial() {
    if (externalFindings.afibBurden === 0 && externalFindings.atrialTachycardias! > 0) {
      return analysisGenerationConfig.findings_noAtrialFibrillation;
    }
    if (!ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")) {
      if (externalFindings.afibBurden !== undefined && externalFindings.afibBurden > 0) {
        return analysisGenerationConfig.findings_someAtrialFibrillation;
      }
    }
    return "";
  }

}


function calculatePrevailingRhythmValue(afibBurden: number | undefined): PrevailingRhythm | undefined {
  return afibBurden === undefined
    ? undefined
    : (
      ReleaseToggleState.getInstance().isReleased("UPDATE_RULES_333")
        ? afibBurden === 0
        : afibBurden < 50
    )
      ? "SR"
      : afibBurden < 90
        ? "?"
        : "AF";
}

function calculatePrevailingRhythmText(
  value: PrevailingRhythm | undefined,
  analysisGenerationConfig: AnalysisGenerationConfig
) {
  switch (value) {
    case "SR":
      return analysisGenerationConfig.findings_prevailingRhythm_sinus;
    case "?":
      return analysisGenerationConfig.findings_prevailingRhythm_unknown;
    case "AF":
      return analysisGenerationConfig.findings_prevailingRhythm_afib;
  }
}
