import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CommentsService, FoundComment} from "./comments.service";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {DateTime} from "luxon";

export interface CommentsCountChangedEvent {
  measurementId: number;
  newCommentCount: number;
}

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
})
export class CommentsComponent implements OnInit {

  constructor(private commentService: CommentsService) {

  }

  commentInput?: string;
  errorMessage?: keyof LocalizationStrings;
  comments: FoundComment[] = [];

  @Output()
  commentsCountChanged: EventEmitter<CommentsCountChangedEvent> = new EventEmitter<CommentsCountChangedEvent>();

  @Input()
  measurementId?: number;

  @Input()
  code?: string;

  @Input()
  hospitalName?: string;

  ngOnInit(): void {
    this.loadComments();
  }

  add() {
    if (this.commentInput === undefined || this.commentInput === "") {
      return;
    }

    this.commentService.addComment({text: this.commentInput, measurementId: this.measurementId!})
      .subscribe(
        () => {
          this.commentInput = undefined;
          this.loadComments();
        },
        () => this.errorMessage = "commentAddingFailed"
      );
  }

  private loadComments() {
    this.commentService.getComments(this.measurementId!)
      .subscribe(comments => {
        this.comments = comments
          .sort((comment1, comment2) => DateTime
            .fromISO(comment1.time)
            .diff(DateTime.fromISO(comment2.time))
            .toObject()
            .milliseconds!
          );
        this.commentsCountChanged.emit({measurementId: this.measurementId!, newCommentCount: comments.length});
      },
      _ => this.errorMessage = "commentFetchingFailed");
  }
}
