import { Injectable } from "@angular/core";
import {DateTime} from "luxon";

@Injectable()
export class ClockService {

  now(): DateTime {
    return DateTime.now();
  }
}
