<form class="card-body">
  <p class="card-title text-center">{{"loginFormOtpTitleLabel" | localize}}</p>
  <form-field [config]="formConfig.otp"></form-field>

  <action-button
    buttonClass="btn btn-primary d-block w-50 mx-auto"
    buttonLabel= "loginFormButtonOtpLoginLabel"
    actionInProgressLabel="loginFormButtonOtpLoginInProgressLabel"
    [actionInProgress]="authenticationInProgress$ | async"
    (buttonClick)="onVerifyClick()"
    [testId]="'verify-code-button'"
  >
  </action-button>
</form>
