import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
  get(key: string): string | undefined {
    return localStorage.getItem(key) || undefined;
  }

  set(key: string, data: string) {
    localStorage.setItem(key, data);
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }
}
