import {LocalizationStrings} from "../i18n/i18n-model";
import {UserRole} from "./authentication/authenticated-user";
import {MeasurementStatus} from "./list/found-measurement";

export const ALL_MEASUREMENT_TYPES = ["SLEEP", "HOLTER_ECG", "ECG_ATRIAL_FIBRILLATION", "SYMPTOM_HOLTER", "BLOOD_PRESSURE"] as const;
type MeasurementTypeTuple = typeof ALL_MEASUREMENT_TYPES;
export type MeasurementType = MeasurementTypeTuple[number]; // union type

export type ReportType = "MEASUREMENT_REPORT" | "MEASUREMENT_SECONDARY_REPORT" | "MEASUREMENT_COMBINED_REPORT";

const holterTimedTypeLocalizationKeys: ReadonlyMap<number, keyof LocalizationStrings> = new Map<number, keyof LocalizationStrings>()
  .set(1, "holterTimedTypeOfDurationDays1")
  .set(2, "holterTimedTypeOfDurationDays2")
  .set(3, "holterTimedTypeOfDurationDays3")
  .set(4, "holterTimedTypeOfDurationDays4")
  .set(5, "holterTimedTypeOfDurationDays5")
  .set(6, "holterTimedTypeOfDurationDays6")
  .set(7, "holterTimedTypeOfDurationDays7");

const homeHolterDurationTypeLocalizationKeys: ReadonlyMap<number, keyof LocalizationStrings> = new Map<number, keyof LocalizationStrings>()
  .set(1, "homeHolterDuration1dTypeLabel")
  .set(2, "homeHolterDuration2dTypeLabel")
  .set(3, "homeHolterDuration3dTypeLabel")
  .set(4, "homeHolterDuration4dTypeLabel")
  .set(5, "homeHolterDuration5dTypeLabel")
  .set(6, "homeHolterDuration6dTypeLabel")
  .set(7, "homeHolterDuration7dTypeLabel");

const measurementTypeLocalizationKeys: ReadonlyMap<MeasurementType, keyof LocalizationStrings> = new Map<
MeasurementType,
keyof LocalizationStrings
>()
  .set("SLEEP", "enumMeasurementTypeSleep")
  .set("HOLTER_ECG", "enumMeasurementTypeHolterEcg")
  .set("SYMPTOM_HOLTER", "enumMeasurementTypeSymptomHolter")
  .set("ECG_ATRIAL_FIBRILLATION", "enumMeasurementTypeEcgAtrialFibrillation")
  .set("BLOOD_PRESSURE", "enumMeasurementTypeBloodPressure");

const homeMeasurementTypeLocalizationKeys: ReadonlyMap<MeasurementType, keyof LocalizationStrings> = new Map<
MeasurementType,
keyof LocalizationStrings
>()
  .set("SLEEP", "homeSleepTypeLabel")
  .set("HOLTER_ECG", "homeHolterTypeLabel")
  .set("SYMPTOM_HOLTER", "homeSymptomHolterTypeLabel")
  .set("ECG_ATRIAL_FIBRILLATION", "homeEcgAtrialFibrillationTypeLabel")
  .set("BLOOD_PRESSURE", "homeBloodPressureTypeLabel");


export function getMeasurementTypeDisplayLocalizationKey(
  {
    type,
    isHome,
    actualDurationDays,
    specifiedDurationDays
  }: {
    type: MeasurementType;
    isHome: boolean;
    actualDurationDays?: number;
    specifiedDurationDays?: number;
  }
): keyof LocalizationStrings {
  return isHome
    ? type !== "HOLTER_ECG"
      ? homeMeasurementTypeLocalizationKeys.get(type)!
      : actualDurationDays !== undefined
        ? homeHolterDurationTypeLocalizationKeys.get(actualDurationDays!)!
        : homeHolterDurationTypeLocalizationKeys.get(specifiedDurationDays!)!
    : type === "HOLTER_ECG" && !!actualDurationDays
      ? holterTimedTypeLocalizationKeys.get(actualDurationDays)!
      : getMeasurementTypeLocalizationKey(type)!;
}

export function getMeasurementTypeLocalizationKey(type: MeasurementType): keyof LocalizationStrings {
  return measurementTypeLocalizationKeys.get(type) || "enumMeasurementTypeSleep";
}

export function isAnalysisCloseable(type: MeasurementType): boolean {
  return isEcgMeasurement(type) || type === "BLOOD_PRESSURE";
}

export function isEcgMeasurement(type: MeasurementType): boolean {
  const ecgMeasurementTypes: MeasurementType[] = ["SYMPTOM_HOLTER", "HOLTER_ECG", "ECG_ATRIAL_FIBRILLATION"];

  return ecgMeasurementTypes.includes(type);
}

export function isBloodPressureMeasurement(type: MeasurementType): boolean {
  return type === "BLOOD_PRESSURE";
}
