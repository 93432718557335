import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";

@Injectable()
export class Notifications {
  private readonly added = new Subject<Notification>();
  readonly added$: Observable<Notification> = this.added.asObservable();

  private readonly deleteAll = new ReplaySubject<void>(1);
  readonly deleteAll$: Observable<void> = this.deleteAll.asObservable();

  addNotification(type: NotificationType, message: string) {
    this.added.next({
      type,
      message,
    });
  }

  clearAll() {
    this.deleteAll.next();
  }
}

export enum NotificationType {
  OK = "ok",
  ERROR = "error",
  WARNING = "warning",
}

export interface Notification {
  type: NotificationType;
  message: string;
}
