import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { RouteNavigator } from "../../tools/navigation/route-navigator.service";
import { NavigationRoute } from "../../tools/navigation/navigation-route";
import { UserRole } from "./authenticated-user";

@Injectable()
export class HomeGuard implements CanActivate {
  constructor(private readonly routeNavigator: RouteNavigator, private readonly authenticationService: AuthenticationService) {}

  private homeRoutes: Map<UserRole, NavigationRoute> = new Map<UserRole, NavigationRoute>()
    .set("NURSE", NavigationRoute.LIST)
    .set("DATA_OFFICER", NavigationRoute.LIST)
    .set("DOCTOR", NavigationRoute.LIST);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.getCurrentAuthenticatedUser();

    if (!currentUser) {
      this.routeNavigator.navigateTo(NavigationRoute.LOGIN);
      return false;
    } else {
      this.routeNavigator.navigateTo(this.homeRoutes.get(currentUser.role) || NavigationRoute.LIST);
      return true;
    }
  }
}
