import {Injectable} from "@angular/core";
import {ApiClient} from "../api-client";
import {Observable} from "rxjs";
import {ReleaseToggle} from "./release-toggle-state";

@Injectable()
export class ReleaseTogglesService {

  constructor(private readonly apiClient: ApiClient) {
  }

  getAllToggles(): Observable<ReleaseToggle[]> {
    return this.apiClient.get<ReleaseToggle[]>("/api/release-toggles");
  }
}

