import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-background-information",
  templateUrl: "./background-information.component.html",
})
export class BackgroundInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
