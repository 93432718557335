import {Observable, of, Subscriber} from "rxjs";
import {concatMap, flatMap, tap} from "rxjs/operators";
import {FileChunker} from "./file-chunker";
import {UploadSession} from "../upload-model";

export class ChunkedFileUploader {
  uploadedPercentage = 0;
  isUploading = false;
  isComplete = false;
  file?: File = undefined;
  sessionId?: number;

  upload(
    file: File,
    createSession: () => Observable<UploadSession>,
    uploadChunk: (sessionId: number, chunk: Blob, start: number) => Observable<void>
  ): Observable<any> {

    this.file = file;

    return createSession()
      .pipe(tap(createSessionResponse => this.sessionId = createSessionResponse.sessionId))
      .pipe(flatMap(createSessionResponse => this.createChunkGeneratingObservable(createSessionResponse)))
      .pipe(concatMap(uploadArgs => uploadChunk(this.sessionId!, uploadArgs.chunk, uploadArgs.start)
        .pipe(flatMap(() => of(uploadArgs.progress)))))
      .pipe(tap((progress) => {
        this.uploadedPercentage = progress;
        this.isUploading = progress < 100;
        this.isComplete = progress == 100;
      }));
  }

  createChunkGeneratingObservable(uploadConfig: UploadSession) {
    return new Observable((observer: Subscriber<UploadChunkData>) => {
      const chunker = new FileChunker(this.file!, uploadConfig.chunkSize);
      while (chunker.hasNextChunk()) {
        const start = chunker.getStart();
        const nextChunk = chunker.getNextChunk();
        const progress = chunker.getProgress();
        const value = {
          chunk: nextChunk,
          progress: progress,
          start: start
        } as UploadChunkData;
        observer.next(value);
      }
      observer.complete();
    });
  }

  reset() {
    this.uploadedPercentage = 0;
    this.isUploading = false;
    this.isComplete = false;
    this.file = undefined;
  }
}

interface UploadChunkData {
  chunk: Blob;
  progress: number;
  start: number;
}
