import { Component, Input } from "@angular/core";
import { UploadFormConfig } from "../upload-form-config";
import { MeasurementType } from "../../measurement-models";
import {HomeMeasurementStatus} from "../../list/found-measurement";

@Component({
  selector: "app-form-selector",
  templateUrl: "./upload-form-selector.component.html",
})
export class UploadFormSelectorComponent {

  @Input() type!: MeasurementType;
  @Input() hidePostMeasurementFields = false;
  @Input() formConfig!: UploadFormConfig;
}
