<div class="container-fluid container--available-viewport-height">
  <ag-grid-angular
    class="ag-theme-alpine h-100"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [components]="frameworkComponents"
    (gridReady)="gridReadyHandler($event)"
    (filterChanged)="filterChangedHandler($event)"
    (rowDataChanged)="rowDataChangedHandler($event)"
    (sortChanged)="sortChangedHandler($event)"
  >
  </ag-grid-angular>
  <pagination
    class="justify-content-center"
    [totalItems]="totalCount"
    [itemsPerPage]="itemsPerPage"
    [maxSize]="10"
    [(ngModel)]="currentPage"
    (pageChanged)="goToPage($event)"
  ></pagination>
</div>
