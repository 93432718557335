import { Injectable } from "@angular/core";
import { MeasurementType } from "../../../measurement-models";
import { FoundMeasurement } from "../../found-measurement";
import { I18nService } from "../../../../i18n/i18n.service";
import { LocalizationStrings } from "../../../../i18n/i18n-model";
import {ReportDownloadAction} from "../../list-item/list-item.component";

const prefixKeyByType = new Map<MeasurementType, keyof LocalizationStrings>()
  .set("SLEEP", "downloadedReportFilenamePrefix_sleep")
  .set("SYMPTOM_HOLTER", "downloadedReportFilenamePrefix_symptomHolter")
  .set("ECG_ATRIAL_FIBRILLATION", "downloadedReportFilenamePrefix_ecgAtrialFibrillation")
  .set("HOLTER_ECG", "downloadedReportFilenamePrefix_ecgHolter")
  .set("BLOOD_PRESSURE", "downloadedReportFilenamePrefix_bloodPressure");


@Injectable()
export class ReportFilenameFactory {
  constructor(private readonly translationService: I18nService) {}

  createFilename(study: FoundMeasurement, actionType: ReportDownloadAction): string {
    return `${this.getPrefix(study.type, actionType)}_${study.code}.pdf`;
  }

  private getPrefix(type: MeasurementType, actionType: ReportDownloadAction): string {
    if (actionType === "SECONDARY") {
      return this.translationService.getLocalizedString("downloadedReportFilenamePrefix_samples");
    }

    return this.translationService.getLocalizedString(prefixKeyByType.get(type) || "downloadedReportFilenamePrefix_sleep");
  }
}
