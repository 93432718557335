import {MeasurementType} from "./measurement-models";
import {NurseMeasurementType} from "./initiate-measurement/initiate-measurement-data";

export function getNurseMeasurementType(
  type: MeasurementType,
  isHome: boolean,
  additionalData?: {
    specifiedHolterDuration?: number;
  }
): NurseMeasurementType {

  if (type === "HOLTER_ECG" && isHome) {
    switch (additionalData?.specifiedHolterDuration) {
      case 1: return "HOME_HOLTER_24H";
      case 2: return "HOME_HOLTER_48H";
      case 7: return "HOME_HOLTER_7D";
      default: throw Error(`Unsupported specified home holter duration: ${additionalData?.specifiedHolterDuration}`);
    }
  }

  return type;
}
