import { ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class MeasurementIdProvider {
  constructor() {}

  getId(route: ActivatedRoute): number | undefined {
    const idParam = route.snapshot.paramMap.get("id");

    if (idParam === null || isNaN(+idParam)) {
      return undefined;
    }

    return +idParam;
  }
}
