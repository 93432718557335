<div class="container">
  <form class="form-striped">
    <form-field [config]="formConfig.type"></form-field>
    <form-field [config]="formConfig.initiatedTime"></form-field>
    <form-field *ngIf="!printFormData?.isHome" [config]="formConfig.name"></form-field>
    <form-field *ngIf="!printFormData?.isHome" [config]="formConfig.ssn"></form-field>
    <form-field [config]="formConfig.code"></form-field>
    <form-field [config]="formConfig.uniqueId"></form-field>
    <form-field [config]="formConfig.language"></form-field>
    <div class="row">
      <action-button
        buttonLabel="printFormButtonLabel"
        actionInProgressLabel="printOrderFormButtonInProgressLabel"
        (buttonClick)="onPrintFormButtonClick()"
        [testId]="'submit-button'">
      </action-button>

      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ml-2">
        {{ errorMessage | localize }}
      </span>
    </div>

  </form>
</div>
