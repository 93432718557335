import { Component } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent {
  readonly customerSupportInfo: CustomerSupportInfo = {
    email: "aspa@remotest.fi",
    phone: "09 4289 1613",
  };
}

interface CustomerSupportInfo {
  email: string;
  phone: string;
}
