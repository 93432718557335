import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageServiceFake {
  private readonly fakeLocalStorage: Map<string, string> = new Map<string, string>();

  get(key: string): string | undefined {
    return this.fakeLocalStorage.get(key);
  }

  set(key: string, data: string) {
    this.fakeLocalStorage.set(key, data);
  }

  delete(key: string) {
    this.fakeLocalStorage.delete(key);
  }
}
