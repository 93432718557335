import {Injectable} from "@angular/core";
import {ApiClient} from "../../../tools/api-client";
import {Observable} from "rxjs/internal/Observable";
import {MeasurementType} from "../../measurement-models";

export interface HospitalMeasurementTypes {
  types: MeasurementType[];
  homeTypes: MeasurementType[];
}

export interface SetHospitalMeasurementTypes {
  types: MeasurementType[];
  homeTypes: MeasurementType[];
  readonly hospitalId: number;
}

@Injectable()
export class HospitalMeasurementTypesService {

  constructor(private readonly apiClient: ApiClient) {
  }

  setAllowedTypes(setHospitalMeasurementTypes: SetHospitalMeasurementTypes): Observable<void> {
    return this.apiClient.put<void>("/api/hospitals/allowed-types", setHospitalMeasurementTypes);
  }

  getOwnHospitalMeasurementTypes(): Observable<HospitalMeasurementTypes> {
    return this.apiClient.get<HospitalMeasurementTypes>("/api/hospitals/allowed-types");
  }
}
