import {Injectable} from "@angular/core";
import {ApiClient} from "../../../tools/api-client";
import {FoundMeasurement} from "../found-measurement";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class RejectStudyService {

  constructor(private readonly api: ApiClient) {
  }

  public reject(measurementId: number): Observable<FoundMeasurement> {
    return this.api
      .put<FoundMeasurement>(`/api/measurements/${measurementId}/reject`)
      .pipe(map(foundMeasurement => recursiveNullToUndefined(foundMeasurement)));
  }
}
