import {enGbLocale, fiLocale, svLocale} from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { LanguageCode } from "../../i18n/i18n-model";

export function initDatepickerLocales() {
  defineLocale(LanguageCode.FI_FI, fiLocale);
  defineLocale(LanguageCode.EN_US, enGbLocale);
  defineLocale(LanguageCode.SV_SV, svLocale);
}

export const dateTimeConfig = {
  inputFormat: "DD.MM.YYYY",
};
