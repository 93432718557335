<i
  *ngIf="showNurseDownloadedReport() && hasReport()"
  data-testid="new-report-indicator"
  class="text-warning fa fa-exclamation-circle fa-2x align-middle"
  [title]="'measurementsGridTitleNewReport' | localize"></i>
<a
  *ngIf="hasReport()"
  data-testid="download-report"
  target="bla.pdf"
  class="btn btn-sm btn-success ml-2 mr-2"
  [href]="getDownloadUrl('PRIMARY')"
  [title]="'measurementsGridButtonTitleDownloadReport' | localize"
  (click)="markReportDownloadedForNurse()">
  <i class="fa fa-download"></i>
</a>
<a
  *ngIf="hasSecondaryReport()"
  data-testid="download-secondary-report"
  target="_blank"
  [href]="getDownloadUrl('SECONDARY')"
  class="btn btn-sm bg-transparent"
  [title]="'measurementsGridButtonTitleDownloadSecondaryReport' | localize">
  <i class="fa fa-2x fa-paperclip text-success"></i>
</a>
