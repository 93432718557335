import { HttpResponse } from "@angular/common/http";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { I18nService } from "../../i18n/i18n.service";
import { Notifications, NotificationType } from "../../tools/notifications/notifications";
import { User, UserListAction, UserListItemViewControl } from "./user-list";
import { UserListService } from "./user-list.service";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
})
export class UserListComponent implements OnInit {
  @ViewChild("templateConfirm") templateConfirm!: TemplateRef<any>;

  users: User[] = [];
  modalRefConfirm: BsModalRef | undefined;

  private readonly viewControl: Map<number, UserListItemViewControl> = new Map<number, UserListItemViewControl>();
  private readonly userListLoadingInProgress = new BehaviorSubject<boolean>(true);
  readonly userListLoadingInProgress$: Observable<boolean> = this.userListLoadingInProgress.asObservable();

  constructor(
    private readonly userListService: UserListService,
    private readonly notifications: Notifications,
    private i18nService: I18nService,
    readonly modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.userListService.getUsers()
      .pipe(finalize(() => this.userListLoadingInProgress.next(false))).subscribe({
        next: (users: User[]) => {
          this.users = users;
        },
        error: (response: HttpResponse<any>) => {
          console.error(response);
          this.notifications.addNotification(NotificationType.ERROR, this.i18nService.getLocalizedString("userListGetListFailed"));
        },
      });
  }

  handleAction(): void {
    const userListActionData = this.modalService.config.initialState as { user: User; action: UserListAction };
    switch (userListActionData.action) {
      case UserListAction.RESET_PASSWORD:
        this.resetPassword(userListActionData.user);
        break;
      case UserListAction.ACTIVATE_USER:
        this.activateUser(userListActionData.user);
        break;
      case UserListAction.DEACTIVATE_USER:
        this.deactivateUser(userListActionData.user);
        break;
    }
  }

  private resetPassword(user: User): void {
    this.modalRefConfirm?.hide();
    this.viewControl.set(user.id, { passwordResetInProgress: true });
    this.userListService.resetPassword(user.id)
      .pipe(
        finalize(() => this.viewControl.set(user.id, { passwordResetInProgress: false }))
      )
      .subscribe({
        next: () => {
          this.notifications.addNotification(NotificationType.OK, this.i18nService.getLocalizedString("userListResetPasswordSucceeded"));
        },
        error: (response: HttpResponse<any>) => {
          console.error(response);
          this.notifications.addNotification(NotificationType.ERROR, this.i18nService.getLocalizedString("userListResetPasswordFailed"));
        },
      });
  }

  private activateUser(user: User): void {
    this.modalRefConfirm?.hide();
    this.viewControl.set(user.id, { userActiveStatusChangeInProgress: true });
    this.userListService.activateUser(user.id)
      .pipe(
        finalize(() => this.viewControl.set(user.id, { userActiveStatusChangeInProgress: false }))
      )
      .subscribe({
        next: () => {
          user.active = true;
          this.notifications.addNotification(NotificationType.OK, this.i18nService.getLocalizedString("userListActivateUserSucceeded"));
        },
        error: (response: HttpResponse<any>) => {
          console.error(response);
          this.notifications.addNotification(NotificationType.ERROR, this.i18nService.getLocalizedString("userListActivateUserFailed"));
        },
      });
  }

  private deactivateUser(user: User): void {
    this.modalRefConfirm?.hide();
    this.viewControl.set(user.id, { userActiveStatusChangeInProgress: true });
    this.userListService.deactivateUser(user.id)
      .pipe(
        finalize(() => this.viewControl.set(user.id, { userActiveStatusChangeInProgress: false }))
      )
      .subscribe({
        next: () => {
          user.active = false;
          this.notifications.addNotification(NotificationType.OK, this.i18nService.getLocalizedString("userListDeactivateUserSucceeded"));
        },
        error: (response: HttpResponse<any>) => {
          console.error(response);
          this.notifications.addNotification(NotificationType.ERROR, this.i18nService.getLocalizedString("userListDeactivateUserFailed"));
        },
      });
  }

  onResetPasswordClick(user: User): void {
    this.showConfirmModal(user, UserListAction.RESET_PASSWORD);
  }

  onActivateUserClick(user: User): void {
    this.showConfirmModal(user, UserListAction.ACTIVATE_USER);
  }

  onDeactivateUserClick(user: User): void {
    this.showConfirmModal(user, UserListAction.DEACTIVATE_USER);
  }

  private showConfirmModal(user: User, action: UserListAction): void {
    this.modalRefConfirm = this.modalService.show(this.templateConfirm, { class: "modal-sm", initialState: { user, action } });
  }

  isResetPasswordInProgress(user: User): boolean {
    return this.getViewControl(user)?.passwordResetInProgress || false;
  }

  isUserActiveStatusChangeInProgress(user: User): boolean {
    return this.getViewControl(user)?.userActiveStatusChangeInProgress || false;
  }

  private getViewControl(user: User): UserListItemViewControl | undefined {
    return this.viewControl.get(user.id);
  }
}
