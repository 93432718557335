import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";
import { User } from "./user-list";

@Injectable()
export class UserListService {
  constructor(private readonly apiClient: ApiClient) {}

  getUsers(): Observable<User[]> {
    return this.apiClient.get<User[]>("/api/users");
  }

  resetPassword(userId: number): Observable<void> {
    return this.apiClient.put<void>(`/api/users/${userId}/resetPassword`);
  }

  activateUser(userId: number): Observable<void> {
    return this.apiClient.put<void>(`/api/users/${userId}/activate`);
  }

  deactivateUser(userId: number): Observable<void> {
    return this.apiClient.put<void>(`/api/users/${userId}/deactivate`);
  }
}
