import { Injectable } from "@angular/core";
import { Doctor } from "../doctor";
import { DoctorsService } from "./doctors.service";

@Injectable()
export class DoctorSelectorService {
  readonly doctors: Doctor[] = [];

  constructor(doctorsService: DoctorsService) {
    doctorsService.getAllDoctors().subscribe((doctors: Doctor[]) => this.doctors.push(...doctors));
  }
}
