import { ColDef } from "ag-grid-community";
import { UserRole } from "../authentication/authenticated-user";

export interface ActionItem {
  readonly action: string;
  readonly additionalData: any;
  readonly role: UserRole;
  readonly timestamp: string;
  readonly username: string;
}

export interface ActionApiResponse {
  readonly totalCount: number;
  readonly actionLogEntries: ActionItem[];
}

const agGridFilterTypes = ["text", "date"] as const;
export type AgGridFilterType = typeof agGridFilterTypes[number];

export interface ActionApiParams {
  hospital?: string;
  timeFrom?: string;
  timeTo?: string;
  action?: string;
  username?: string;
  role?: UserRole;
  page?: string;
  limit?: string;
  sort?: string;
}

export const actionLogDefaultColumnDef: ColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  floatingFilter: true,
  suppressMenu: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilterComponentParams: {
    suppressFilterButton: true,
  },
  comparator: (valueA: any, valueB: any): number =>
    // to match BE sorting (null values last)
    valueB - valueA
  ,
  cellStyle: () =>
    // to enable cell selection
    ({
      userSelect: "text",
    })
  ,
};

export const actionLogColumnDefs: ColDef[] = [
  { field: "hospital" },
  { field: "action" },
  { field: "role" },
  {
    field: "timestamp",
    cellRenderer: "dateWithCopyRendererComponent",
    filter: "agDateColumnFilter",
    filterParams: { filterOptions: ["inRange"], suppressAndOrCondition: true },
    floatingFilterComponentParams: { suppressFilterButton: false },
  },
  { field: "username" },
  {
    field: "additionalData",
    flex: 1,
    filter: false,
    sortable: false,
    cellRenderer: "mapCellRendererComponent",
    autoHeight: true,
    minWidth: 200,
  },
];
