<form>
  <app-form-selector
    [type]="measurement!.type"
    [formConfig]="formConfig"
  ></app-form-selector>

  <action-button
    buttonLabel="editMeasurementDataUpdateButtonLabel"
    actionInProgressLabel="editMeasurementDataUpdateInProgressLabel"
    buttonType="button"
    (buttonClick)="onSubmit()"
    [testId]="'submit-button'">
  </action-button>

  <span
    data-testid="edit-measurement-form-error-message"
    *ngIf="!editMeasurementValidator!.isFormValid()"
    class="alert alert-danger ml-2">
        {{ localizationKey("editMeasurementDataFormInvalid") | localize }}
  </span>
</form>
