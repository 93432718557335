import {HttpResponse} from "@angular/common/http";
import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Observable, Observer, of, Subject} from "rxjs";
import {finalize, map, switchMap} from "rxjs/operators";
import {LocalizationStrings} from "src/app/i18n/i18n-model";
import {I18nService} from "../../i18n/i18n.service";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {isRequiredTextOfMaxLength, isValidEmail} from "../validations";
import {AddNursesResponse, Hospital} from "./new-users";
import {NewUsersService} from "./new-users.service";

@Component({
  selector: "app-new-users",
  templateUrl: "./new-users.component.html",
})
export class NewUsersComponent implements OnInit {
  @ViewChild("confirmSubmitTemplate") confirmSubmitTemplate!: TemplateRef<any>;

  selectedHospital = "";
  hospitalSuggestions$: Observable<string[]>;
  userEmailsRaw = "";
  userEmails: string[] = [];
  modalRef: BsModalRef | undefined;
  invalidEmail = "";
  invalidEmailLength = "";
  orderingNurse = false;

  private readonly addNursesInProgress = new Subject<boolean>();
  readonly addNursesInProgress$: Observable<boolean> = this.addNursesInProgress.asObservable();
  hospitalNameValid = true;

  constructor(
    private modalService: BsModalService,
    private newUsersService: NewUsersService,
    private readonly notifications: Notifications,
    private i18nService: I18nService
  ) {
    this.hospitalSuggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.selectedHospital);
    }).pipe(switchMap((hospitalName: string) => {
      if (hospitalName) {
        return this.newUsersService
          .getHospitals(hospitalName)
          .pipe(map((hospitals: Hospital[]) => hospitals?.map((hospital) => hospital.name) || []));
      }

      return of([]);
    }));
  }

  ngOnInit(): void {
  }

  private resetForm(): void {
    this.selectedHospital = "";
    this.userEmailsRaw = "";
    this.invalidEmail = "";
    this.invalidEmailLength = "";
    this.userEmails = [];
    this.orderingNurse = false;
  }

  private parseRawEmails(): string[] {
    return this.userEmailsRaw
      .split("\n")
      .map((str) => str.trim())
      .filter((str) => !!str);
  }

  private checkEmailsValidity(emails: string[]): void {
    this.invalidEmail = "";

    for (let i = 0; i < emails.length; i++) {
      if (!isValidEmail(emails[i])) {
        this.invalidEmail = emails[i];
        break;
      }
    }
  }

  private checkEmailsLengthValidity(emails: string[]): void {
    this.invalidEmailLength = "";
    for (let i = 0; i < emails.length; i++) {
      if (!isRequiredTextOfMaxLength(50, emails[i])) {
        this.invalidEmailLength = emails[i];
        break;
      }
    }
  }

  private openConfirmationModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {class: "modal-md"});
  }

  private isNursesDataValid(): boolean {
    return !this.invalidEmail && !this.invalidEmailLength && this.hospitalNameValid;
  }

  confirm(): void {
    this.notifications.clearAll();
    this.addNursesInProgress.next(true);
    this.newUsersService.addNurses(this.selectedHospital, this.userEmails, this.orderingNurse)
      .pipe(finalize(() => this.addNursesInProgress.next(false))).subscribe({
        next: (response: AddNursesResponse) => {
          if (response.errorCodes?.length) {
            this.addEmailsToErrorNotification("newUsersAddFailedInvalidEmails", response.invalidEmails);
            this.addEmailsToErrorNotification("newUsersAddFailedExistingEmails", response.existingEmails);
            this.addEmailsToErrorNotification("newUsersAddFailedUnsentEmails", response.unsentEmails);
          } else {
            this.resetForm();
            this.notifications.addNotification(NotificationType.OK, this.i18nService.getLocalizedString("newUsersAddNursesSuccessfulPost"));
          }
        },
        error: (response: HttpResponse<any>) => {
          console.error(response);
          this.notifications.addNotification(NotificationType.ERROR, this.i18nService.getLocalizedString("newUsersAddNursesFailedPost"));
        },
      });
    this.modalRef?.hide();
  }

  private addEmailsToErrorNotification(specificMessage: keyof LocalizationStrings, emails?: string[]): void {
    if (!emails) {
      return;
    }

    const errorMessage = this.i18nService.getLocalizedString("newUsersAddNursesFailedPost")
      + ` ${this.i18nService.getLocalizedString(specificMessage)}:`
      + ` ${this.formatEmailArray(emails)}.`;

    this.notifications.addNotification(NotificationType.ERROR, errorMessage);
  }

  private formatEmailArray(emails: string[]): string {
    return emails.map((email) => `"${email}"`).join(", ");
  }

  decline(): void {
    this.modalRef?.hide();
  }

  submit(): void {
    const emails = this.parseRawEmails();
    if (!emails.length) {
      return;
    }
    this.checkHospitalNameValidity();
    this.checkEmailsValidity(emails);
    this.checkEmailsLengthValidity(emails);
    if (this.isNursesDataValid()) {
      this.userEmails = emails;
      this.openConfirmationModal(this.confirmSubmitTemplate);
    }
  }

  private checkHospitalNameValidity() {
    this.hospitalNameValid = isRequiredTextOfMaxLength(300, this.selectedHospital);
  }
}
