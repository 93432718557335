import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";

@Injectable()
export class FinishAnalysisService {
  constructor(private readonly apiClient: ApiClient) {
  }

  finishAnalysis(
    measurementId: number,
    analysisReportFile: File,
    combinedAnalysisReportFile?: File
  ): Observable<any> {
    return this.apiClient.put<any>(
      `/api/measurements/${measurementId}/finish-analysis`,
      createFinishAnalysisRequest(analysisReportFile, combinedAnalysisReportFile)
    );
  }
}

function createFinishAnalysisRequest(analysisReportFile: File, combinedReportFile?: File): FormData {
  const formData = new FormData();
  formData.append("analysisReportFile", analysisReportFile, analysisReportFile.name);
  if(combinedReportFile){
    formData.append("combinedReportFile", combinedReportFile, combinedReportFile.name);
  }

  return formData;
}
