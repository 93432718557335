import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Observable} from "rxjs";
import {OtpConfig} from "./otp-config";
import {emptyOtpFormModel, OtpFormModel, UserCredentialsFormModel,} from "../login-form-model";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {LoginValidator} from "../login-validator";
import {LoginService} from "../login.service";
import {ErrorResponse} from "../../../tools/error-response";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {AuthenticationComponent} from "../authentication-component";

@Component({
  selector: "app-otp-login",
  templateUrl: "./otp.component.html",
})

export class OtpComponent extends AuthenticationComponent implements PropertyChangeListener<OtpFormModel> {

  constructor(
    private readonly loginValidator: LoginValidator,
    private readonly loginService: LoginService) {
    super();
  }

  formModel: OtpFormModel = emptyOtpFormModel();

  readonly formConfig = new OtpConfig(this.formModel, this, (property) => this.validationStatuses.get(property));

  @Output() userAuthenticated = new EventEmitter<AuthenticatedUser>();
  @Output() notifyError = new EventEmitter<ErrorResponse>();

  @Input() set userCredentials(model: UserCredentialsFormModel) {
    this.formModel.username = model.username;
    this.formModel.password = model.password;
  }

  onVerifyClick() {
    if (!this.isValid()) {
      return;
    }

    this.clearNotificationsAndShowProgress();

    this.doVerification(
      (authenticatedUser) => {
        this.userAuthenticated.emit(authenticatedUser);
      },
      (errorResponse: ErrorResponse) => {
        this.notifyError.emit(errorResponse);
      }
    );
  }

  onPropertyChange(property: keyof OtpFormModel, newValue: any): void {
    if (property === "otp") {
      newValue = newValue.trim();
    }
    this.formModel[property] = newValue;
    this.validate(property);
  }

  validate(propertyToValidate?: keyof OtpFormModel) {
    const validationResult = this.loginValidator.validateOtp(this.formModel);
    this.validateProperties(validationResult, propertyToValidate);
  }

  authenticate(): Observable<AuthenticatedUser> {
    return this.loginService.verifyOtp({
      username: this.formModel.username,
      password: this.formModel.password.trim(),
      otp: this.formModel.otp.trim()
    });
  }
}
