export function recursiveNullToUndefined<T>(any: T): T {

  if (any == null) {
    return undefined as unknown as T;
  }

  if (typeof any !== "object" && !Array.isArray(any)) {
    return any;
  }

  if (Array.isArray(any)) {
    return any.map(it => it == null ? undefined : recursiveNullToUndefined(it)) as unknown as T;
  }

  if (any instanceof Map) {
    return new Map(Array.from(any.entries()).map(entry => {
      return [recursiveNullToUndefined(entry[0]), recursiveNullToUndefined(entry[1])];
    })) as unknown as T;
  }

  return Object.fromEntries(Object.entries(any).map(entry => {
    return [entry[0], recursiveNullToUndefined(entry[1])];
  })) as T;

}
