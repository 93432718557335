import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild} from "@angular/core";
import {formatMeasurementFileSize, FoundMeasurement} from "../found-measurement";
import {UploadFormConfig} from "../../upload/upload-form-config";
import {emptyUploadFormModel} from "../../upload/upload-form-model";
import {localizationKey, LocalizationStrings} from "../../../i18n/i18n-model";
import {isEcgMeasurement} from "../../measurement-models";
import {emptyDataOfficerMeasurement} from "../list-spec-fixtures.test-util";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {MeasurementDetailsService} from "./measurement-details.service";
import {EditHolterTypeService} from "../edit-holter-type/edit-holter-type.service";
import {FormFieldConfig} from "../../../tools/form/form-field-config";

interface MeasurementMetadata {
  readonly fileName: string;
  readonly dataSize: string;
  readonly specifiedHomeHolterDuration: string | undefined;
}

@Component({
  selector: "app-measurement-details",
  templateUrl: "./measurement-details.component.html",
})
export class MeasurementDetailsComponent implements OnChanges {
  @Input() measurement: FoundMeasurement = emptyDataOfficerMeasurement();

  @Output() measurementChanged: EventEmitter<FoundMeasurement> = new EventEmitter<FoundMeasurement>();

  readonly labelStart: keyof LocalizationStrings = "analysisLabelStartTime";

  patientDataFields?: UploadFormConfig;

  modalRef: BsModalRef | undefined;
  @ViewChild("templateEditMeasurement") templateEditMeasurement!: TemplateRef<any>;
  @ViewChild("templateEditHolterType") templateEditHolterType!: TemplateRef<any>;

  fileNameConfig: FormFieldConfig<MeasurementMetadata> | undefined;
  dataSizeConfig: FormFieldConfig<MeasurementMetadata> | undefined;
  specifiedHomeHolterDurationConfig: FormFieldConfig<MeasurementMetadata> | undefined;

  constructor(
    private readonly modalService: BsModalService,
    private readonly measurementDetailsService: MeasurementDetailsService,
    private readonly editHolterTypeService: EditHolterTypeService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.measurement) {
      return;
    }

    this.patientDataFields = new UploadFormConfig({
      ...emptyUploadFormModel(),
      ...this.measurement.patientData,
    });

    const metadata: MeasurementMetadata = {
      fileName: this.measurement.fileName || "",
      dataSize: formatMeasurementFileSize(this.measurement.dataSize),
      specifiedHomeHolterDuration: this.measurement.specifiedHomeHolterDuration + " d"
    };

    this.fileNameConfig = {
      label: "measurementDetailsFileNameLabel",
      property: "fileName",
      type: "text",
      isValid: () => true,
      model: metadata
    };

    this.dataSizeConfig = {
      label: "measurementDetailsDataSizeLabel",
      property: "dataSize",
      type: "text",
      isValid: () => true,
      model: metadata
    };

    if (this.measurement.specifiedHomeHolterDuration !== undefined) {
      this.specifiedHomeHolterDurationConfig =
        {
          label: "measurementDetailsSpecifiedHomeHolterDurationLabel",
          property: "specifiedHomeHolterDuration",
          type: "text",
          isValid: () => true,
          model: metadata
        };
    }


  }

  isEcgListItem(listItem: FoundMeasurement): boolean {
    return isEcgMeasurement(listItem.type);
  }

  showEditButton() {
    return this.measurementDetailsService.canEditMeasurement(this.measurement.status);
  }

  protected readonly localizationKey = localizationKey;

  showEditHolterTypeButton(): boolean {
    return this.measurementDetailsService.canEditHolterType(this.measurement.type, this.measurement.status);
  }

  showEditMeasurementModal() {
    this.modalRef = this.modalService.show(this.templateEditMeasurement, {
      class: "modal-lg"
    });
  }

  onEditMeasurementSuccess(measurement: FoundMeasurement) {
    this.modalRef?.hide();
    this.measurementChanged.emit(measurement);
  }

  closeEditor() {
    this.modalRef?.hide();
  }

  openHolterEditor() {
    this.modalRef = this.modalService.show(this.templateEditHolterType, {
      class: "modal-sm"
    });
  }

  onHolterTypeSet(holterDurationDays: number) {
    this.closeEditor();
    this.editHolterTypeService.setHolterType(
      this.measurement.id,
      holterDurationDays
    )
      .subscribe((newMeasurement) => {
        this.measurementChanged.emit(newMeasurement);
      });
  }

  protected readonly formatMeasurementFileSize = formatMeasurementFileSize;
}
