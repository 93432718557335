import {ALL_MEASUREMENT_TYPES, MeasurementType} from "../measurement-models";
import {PatientData} from "../upload/upload-model";

export const ALL_NURSE_MEASUREMENT_TYPES = [
  ...ALL_MEASUREMENT_TYPES,
  "HOME_HOLTER_24H",
  "HOME_HOLTER_48H",
  "HOME_HOLTER_7D"
] as const;
type NurseMeasurementTypeTuple = typeof ALL_NURSE_MEASUREMENT_TYPES;
export type NurseMeasurementType = NurseMeasurementTypeTuple[number];

export interface InitiatedMeasurementResponse {
  measurementId: number;
}


export interface InitiateMeasurementFormModel {
  code: string;
  type?: NurseMeasurementType;
  reasonForStudy?: string;
  operatorHospitalId?: string;
}


export interface InitiatedMeasurementData {
  code: string;
  type: MeasurementType;
  patientData?: PatientData;
  isOrdered: boolean;
  isHome: boolean;
  specifiedDuration?: number;
}


export interface InitiateMeasurementRequest {
  code: string;
  type: MeasurementType;
  isHome: boolean;
  reasonForStudy: string;
  specifiedHomeHolterDuration?: number;
}
