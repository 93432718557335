import {Component, OnInit} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {I18nService} from "../../i18n/i18n.service";
import {SelectOption} from "../../tools/select/select.component";
import {AuthenticationService} from "../authentication/authentication.service";
import {ChangeLanguageService} from "./change-language.service";
import {finalize} from "rxjs/operators";
import {ErrorCode, ErrorResponse} from "../../tools/error-response";
import { languageSelectionOptions } from "../../tools/languages";


@Component({
  selector: "app-change-language",
  templateUrl: "./change-language.component.html",
})
export class ChangeLanguageComponent implements OnInit {
  private readonly changeInProgress = new Subject<boolean>();
  readonly changeInProgress$: Observable<boolean> = this.changeInProgress.asObservable();
  selectedLanguage?: string;
  private userLanguage?: string;

  constructor(private readonly authenticationService: AuthenticationService,
    private readonly changeLanguageService: ChangeLanguageService,
    private notifications: Notifications,
    private i18Service: I18nService) {
  }


  readonly langSelectionOptions = languageSelectionOptions;

  ngOnInit(): void {
    this.selectedLanguage = this.authenticationService.getCurrentAuthenticatedUser()!.language;
    this.userLanguage = this.selectedLanguage;
  }


  submit(): void {
    this.notifications.clearAll();
    if (this.selectedLanguage !== this.userLanguage) {
      this.changeInProgress.next(true);
      this.changeLanguageService.changeLanguage(this.selectedLanguage!)
        .pipe(finalize(() => this.changeInProgress.next(false)))
        .subscribe({
          next: () => {
            this.userLanguage = this.selectedLanguage;
            this.updateCurrentUser();
          },
          error: (response: ErrorResponse) => {
            let errorMessage = this.i18Service.getLocalizedString("changeLanguageFailedMessage");
            if (response.errorCodes.includes(ErrorCode.AUTH_INVALID_CREDENTIALS)) {
              errorMessage += ` ${this.i18Service.getLocalizedString("loginFailedInvalidCredentials")}.`;
            }
            this.notifications.addNotification(NotificationType.ERROR, errorMessage);
          },
        });
    }
  }

  updateCurrentUser(): void {
    const currentUser = this.authenticationService.getCurrentAuthenticatedUser()!;
    currentUser.language = this.userLanguage!;
    this.authenticationService.setCurrentAuthenticatedUser(currentUser);
  }

  handleLanguageChange(newLanguage: string) {
    this.selectedLanguage = newLanguage;
  }
}
