import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";

@Injectable()
export class ChangePasswordService {
  constructor(private apiClient: ApiClient) {}

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.apiClient.put<any>("/api/account/changePassword", { oldPassword, newPassword });
  }
}
