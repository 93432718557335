import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {BsDatepickerConfig, BsLocaleService} from "ngx-bootstrap/datepicker";
import {dateTimeConfig, initDatepickerLocales} from "./date-time-config";
import {LanguageProvider} from "../../i18n/language-provider";
import {environment} from "../../../environments/environment";
import {DateTime} from "luxon";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
})
export class DateTimePickerComponent implements OnChanges {

  @Input()
    enableTimePicker = true;

  @Input()
    emitDateTimeWithoutOffset = false;

  @Input()
    dateTime?: DateTime | null;

  @Input()
    disabled = false;

  @Input("data-testid")
    dataTestId?: string;

  @Output()
    dateTimeChange: EventEmitter<DateTime | undefined> = new EventEmitter<DateTime | undefined>();

  responsiveLayout = environment.eforms;

  bsDatePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: "theme-dark-blue",
    dateInputFormat: dateTimeConfig.inputFormat,
  };

  date?: Date;
  time?: Date;

  constructor(languageProvider: LanguageProvider,
    private datePickerLocaleService: BsLocaleService) {
    initDatepickerLocales();
    this.datePickerLocaleService.use(languageProvider.getLanguage());
  }

  ngOnChanges(changes: SimpleChanges) {
    const newDateTime = this.dateTime?.toJSDate();

    if  (newDateTime === undefined) {
      return;
    }

    if (newDateTime?.getTime() != this.date?.getTime()) {
      this.date = newDateTime;
    }

    if (newDateTime?.getTime() != this.time?.getTime()) {
      this.time = newDateTime;
    }
  }

  onDateTimeChanged(): void {
    if (this.dateTime === null) {
      throw Error("Sending null to datetime picker. This is not supported. Will not work as intended");
    }

    this.dateTime = this.createDateTimeWithZeroSeconds();
    this.dateTimeChange.emit(this.dateTime);
  }

  private createDateTimeWithZeroSeconds() {
    return this.date && this.time ?
      DateTime.fromJSDate(this.date)
        .set({
          hour: this.time.getHours(),
          minute: this.time.getMinutes(),
        })
        .startOf("minute")
      : undefined;
  }
}
