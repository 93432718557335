import {SelectOption} from "./select/select.component";
import {LocalizationStrings} from "../i18n/i18n-model";

interface LanguageDefinition {
  value: string;
  text: keyof LocalizationStrings;
  flag: string;
}

export const languageDefinitions: LanguageDefinition[] = [
  {
    value: "en",
    text: "englishLang",
    flag: "🇬🇧"
  },
  {
    value: "fi",
    text: "finnishLang",
    flag: "🇫🇮"
  },
  {
    value: "sv",
    text: "swedishLang",
    flag: "🇸🇪"
  }
];


export const languageSelectionOptions: SelectOption[] =
  languageDefinitions.map(definition => ({
    value: definition.value,
    text: definition.text,
    localize: true
  }));
