<div *ngIf="patientDataFields" class="small card card-body">
  <div>
    <app-form-field-display *ngIf="fileNameConfig" [formFieldConfig]="fileNameConfig"></app-form-field-display>
    <app-form-field-display *ngIf="dataSizeConfig" [formFieldConfig]="dataSizeConfig"></app-form-field-display>
    <ng-container *ngIf="specifiedHomeHolterDurationConfig">
      <hr/>
      <app-form-field-display [formFieldConfig]="specifiedHomeHolterDurationConfig"></app-form-field-display>
    </ng-container>
    <hr/>
  </div>

  <div *ngIf="measurement.type === 'SLEEP'">
    <app-form-field-display [formFieldConfig]="patientDataFields.reasonForStudy"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.age"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.height"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.overweight"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.profession"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.hypertension"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.diabetes"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.overbite"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.cloggedNasalPassage"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.allergicRhinitis"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.smallChin"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.useOfSleepingPills"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.alcoholConsumption"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.morningHeadaches"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.breathingLoss"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.fluttering"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.snoring"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.restlessSleep"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.daytimeSleepiness"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.dryMouthInMorning"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.needToUrinate"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.sittingReading"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.watchingTelevision"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.sittingPassive"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.travelInCarForAnHour"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.lyingDownInAfternoon"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.sittingTalkingToSomeone"></app-form-field-display>
    <app-form-field-display
      [formFieldConfig]="patientDataFields.sitInPeaceAfterNonAlcoholicLunch"></app-form-field-display>
    <app-form-field-display
      [formFieldConfig]="patientDataFields.sitInCarAfterItHasStoppedForFewMinutes"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.currentMedication"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.sleepQuality"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.fallingAsleepDuration"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.sleepHours"></app-form-field-display>
    <app-form-field-display
      [formFieldConfig]="patientDataFields.minutesAwakeAfterMeasurementStart"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.awakeningsDuringMeasurement"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.sleepDiary"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.patientFeedback"></app-form-field-display>
  </div>

  <div *ngIf="isEcgListItem(measurement)">
    <app-form-field-display [label]="labelStart"
                            [value]="measurement.startTime | appLocalDateTime"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.reasonForStudy"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.age"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.height"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.abnormalHeartbeats"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.slowPulse"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.fastPulse"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.palpitation"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.irregularPulse"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.dizziness"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.lossOfConsciousness"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.blackouts"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.chestPain"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.shortnessOfBreath"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.diagnosedHeartDisease"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.currentMedication"></app-form-field-display>

    <hr/>

    <div *ngIf="measurement.type === 'SYMPTOM_HOLTER'">
      <app-form-field-display
        *ngFor="let symptom of measurement?.patientData?.symptoms || []"
        [label]="symptom.time | appLocalDateTime"
        [value]="symptom.description">
      </app-form-field-display>
      <hr/>
    </div>

    <app-form-field-display
      *ngIf="measurement.type === 'HOLTER_ECG' || measurement.type === 'ECG_ATRIAL_FIBRILLATION'"
      [formFieldConfig]="patientDataFields.diary"
    ></app-form-field-display>

    <app-form-field-display [formFieldConfig]="patientDataFields.patientFeedback"></app-form-field-display>
  </div>

  <div *ngIf="measurement.type === 'BLOOD_PRESSURE'">
    <app-form-field-display [formFieldConfig]="patientDataFields.reasonForStudy"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.age"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.height"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.waistline"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.cholesterol"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.diabetes"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.coronaryArteryDisease"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.coronaryInsufficiency"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.cerebrovascularDisorders"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.kidneyDisease"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.parentsHeartAttackUnder60"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.parentsStrokeUnder75"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.diagnosedHeartDisease"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.currentMedication"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.diary"></app-form-field-display>

    <hr/>

    <app-form-field-display [formFieldConfig]="patientDataFields.sleepMeasurementNight"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.goToBedTime"></app-form-field-display>
    <app-form-field-display [formFieldConfig]="patientDataFields.wakeUpTime"></app-form-field-display>

    <hr/>
    <app-form-field-display [formFieldConfig]="patientDataFields.patientFeedback"></app-form-field-display>
  </div>

  <div *ngIf="showEditButton()">
    <button
      class="btn btn-primary float-right"
      data-testId="edit-measurement-button"
      (click)="showEditMeasurementModal()"
    >{{ localizationKey('editMeasurementDataButton') |  localize }}
    </button>
  </div>

  <div *ngIf="showEditHolterTypeButton()">
    <button
      class="btn btn-primary float-right"
      data-testId="edit-holter-type-button"
      (click)="openHolterEditor()"
    >{{ localizationKey('editHolterTypeButton') | localize }}
    </button>
  </div>
</div>

<ng-template #templateEditMeasurement>
  <div class="modal-body text-center">
    <app-edit-measurement
      [measurement]="this.measurement"
      (onUpdateSuccess)="onEditMeasurementSuccess($event)">
    </app-edit-measurement>
    <button type="button" class="btn btn-primary" (click)="closeEditor()">
      {{ "modalResponseClose" | localize }}
    </button>
  </div>
</ng-template>

<ng-template #templateEditHolterType>
  <div class="modal-body text-center">
    <app-holter-type-editor
      (editingCancelled)="closeEditor()"
      (holterTypeSet)="onHolterTypeSet($event)"
    >
    </app-holter-type-editor>
  </div>
</ng-template>
