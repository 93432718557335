import {Injectable} from "@angular/core";
import {FoundMeasurement} from "./found-measurement";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {I18nService} from "../../i18n/i18n.service";
import {UploadFormConfig} from "../upload/upload-form-config";
import {emptyUploadFormModel, UploadFormModel} from "../upload/upload-form-model";
import {FormFieldConfig, translateFormValue} from "../../tools/form/form-field-config";

const SECTION_SEPARATOR = "\n\n";
const LINE_SEPARATOR = "\n";
export const GROUP_SEPARATOR = `\n${"-".repeat(60)}\n`;
export const FIELD_PREFIX = "  - ";
export const LABEL_VALUE_SEPARATOR = ": ";
export const LABEL_EMPTY_VALUE_SEPARATOR = ":";

@Injectable()
export class ListItemTextBuilder {
  constructor(private readonly i18nService: I18nService) {
  }

  build(item: FoundMeasurement): string {
    const builder = new Builder(this.i18nService, item.reportLanguage);

    return [
      builder.printIds(item),
      SECTION_SEPARATOR,
      builder.printPatientQuestionnaireInfo(item),
      SECTION_SEPARATOR,
      builder.printCalculatedInfo(item),
    ].join("");
  }
}

class Builder {

  constructor(
    private readonly i18nService: I18nService,
    private readonly language?: string
  ) {
  }

  printPatientQuestionnaireInfo(item: FoundMeasurement): string {
    return getQuestionnaireGroups(item)
      .map((group) => this.printQuestionnaireGroup(group))
      .join(GROUP_SEPARATOR);
  }

  printCalculatedInfo(listItem: FoundMeasurement): string {
    return [
      this.formatValueLine("itemInfoTextLabelBmi", listItem.bmi),
      this.formatValueLine("itemInfoTextLabelEssScore", listItem.essScore),
    ].join(LINE_SEPARATOR);
  }

  printIds(listItem: FoundMeasurement): string {
    const patientId = this.formatValueLine("uploadFormLabelCode", listItem.code);
    const uniqueId = this.formatValueLine("reportUniqueIdLabel", listItem.uniqueId, "");

    return `${patientId} / ${uniqueId}`;
  }

  private printQuestionnaireGroup(group: QuestionnaireGroup): string {
    const groupTitle: string = group.groupTitleLabel ? `${this.translate(group.groupTitleLabel)}${LINE_SEPARATOR}` : "";
    const fields: string = group.fields.map((fieldConfig) => this.formatQuestionnaireFieldLine(fieldConfig)).join(LINE_SEPARATOR);

    return [groupTitle, fields].join("");
  }

  private formatQuestionnaireFieldLine(fieldConfig: FormFieldConfig<UploadFormModel>): string {
    const fieldValueText = this.formatQuestionnaireFieldValue(fieldConfig);

    const fullFieldText =
      !!fieldConfig.infoLabel && !!fieldValueText ? `${fieldValueText} ${this.translate(fieldConfig.infoLabel)}` : fieldValueText;
    return this.formatValueLine(fieldConfig.label, fullFieldText);
  }

  private formatQuestionnaireFieldValue(fieldConfig: FormFieldConfig<UploadFormModel>): string | undefined {
    const formValue = fieldConfig.model[fieldConfig.property];

    if (!isValuePresent(formValue)) {
      return undefined;
    }

    return translateFormValue(fieldConfig, formValue, (key) => this.translate(key));
  }

  private formatValueLine(labelKey: keyof LocalizationStrings, value: any, fieldPrefix?: string): string {
    const label = this.translate(labelKey);

    if (!isValuePresent(value)) {
      return [FIELD_PREFIX, label, LABEL_EMPTY_VALUE_SEPARATOR].join("");
    }

    const fieldPrefixOrDefault = fieldPrefix === undefined ? FIELD_PREFIX : fieldPrefix;
    const valuePrefix = [fieldPrefixOrDefault, label, LABEL_VALUE_SEPARATOR].join("");
    const text = value.toString().trimRight();
    const formattedText = text.includes("\n") ? formatMultilineText(text, valuePrefix.length) : text;

    return [valuePrefix, formattedText].join("");
  }

  private translate(key: keyof LocalizationStrings): string {
    return this.i18nService.getLocalizedString(key, this.language);
  }
}

function isValuePresent(value: any): boolean {
  return value !== undefined && value !== null && value.toString().trim().length > 0;
}

function formatMultilineText(value: string, indent: number): string {
  return value.replace(/\n/g, `\n${" ".repeat(indent)}`);
}

interface QuestionnaireGroup {
  groupTitleLabel?: keyof LocalizationStrings;
  fields: FormFieldConfig<UploadFormModel>[];
}

export function getQuestionnaireGroups(item: FoundMeasurement): QuestionnaireGroup[] {
  const formConfig = new UploadFormConfig({
    ...emptyUploadFormModel(),
    ...item.patientData,
  });

  return [
    {
      fields: [
        formConfig.gender,
        formConfig.age,
        formConfig.height,
        formConfig.weight,
        formConfig.overweight,
        formConfig.profession,
        formConfig.reasonForStudy,
      ],
    },
    {
      fields: [
        formConfig.hypertension,
        formConfig.diabetes,
        formConfig.overbite,
        formConfig.cloggedNasalPassage,
        formConfig.allergicRhinitis,
        formConfig.smallChin,
      ],
    },
    {
      fields: [formConfig.smoking, formConfig.useOfSleepingPills, formConfig.alcoholConsumption, formConfig.cloggedNasalPassage],
    },
    {
      groupTitleLabel: "uploadFormHeaderFrequency",
      fields: [
        formConfig.morningHeadaches,
        formConfig.breathingLoss,
        formConfig.fluttering,
        formConfig.snoring,
        formConfig.restlessSleep,
        formConfig.daytimeSleepiness,
        formConfig.dryMouthInMorning,
        formConfig.needToUrinate,
      ],
    },
    {
      groupTitleLabel: "uploadFormHeaderProbability",
      fields: [
        formConfig.sittingReading,
        formConfig.watchingTelevision,
        formConfig.sittingPassive,
        formConfig.travelInCarForAnHour,
        formConfig.lyingDownInAfternoon,
        formConfig.sittingTalkingToSomeone,
        formConfig.sitInPeaceAfterNonAlcoholicLunch,
        formConfig.sitInCarAfterItHasStoppedForFewMinutes,
      ],
    },
    {
      fields: [formConfig.currentMedication],
    },
    {
      groupTitleLabel: "uploadFormHeaderSleepRegistrationEvents",
      fields: [
        formConfig.sleepQuality,
        formConfig.fallingAsleepDuration,
        formConfig.sleepHours,
        formConfig.minutesAwakeAfterMeasurementStart,
        formConfig.awakeningsDuringMeasurement,
      ],
    },
    {
      fields: [formConfig.sleepDiary, formConfig.patientFeedback],
    },
  ];
}
