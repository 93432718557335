import {Injectable} from "@angular/core";

import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {
  InitiatedMeasurementResponse,
  InitiateMeasurementFormModel,
  InitiateMeasurementRequest
} from "./initiate-measurement-data";


@Injectable()
export class InitiateMeasurementService {

  constructor(private apiClient: ApiClient) {
  }

  initiateMeasurement(initiateMeasurementFormRequest: InitiateMeasurementRequest): Observable<InitiatedMeasurementResponse> {
    return this.apiClient.post<InitiatedMeasurementResponse>("/api/measurements/initiate", initiateMeasurementFormRequest);
  }



  setInitiatedMeasurementLanguage(measurementId: number, language: string): Observable<void> {
    return this.apiClient.put(`/api/measurements/initiated/${measurementId}/eform/${language}`);
  }
}
