import {Component, Input} from "@angular/core";
import {localizationKey} from "../../../../i18n/i18n-model";

@Component({
  selector: "app-character-counter",
  templateUrl: "./character-counter.component.html",
})
export class CharacterCounterComponent {

  @Input("data-testId")
  dataTestId?: string;

  @Input()
  maxCharacters?: number;

  @Input()
  text?: string;

  constructor() {
  }

  protected readonly localizationKey = localizationKey;

  getRemainingCharacters() {
    return `${this.maxCharacters! - this.getTextLength()}`;
  }

  getClass(): string {
    return this.getTextLength() <= this.maxCharacters!
      ? "form-field-feedback"
      : "form-field-invalid-feedback";
  }

  private getTextLength() {
    return this.text == null ? 0 : this.text.length;
  }
}
