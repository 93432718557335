import { Injectable } from "@angular/core";
import { ApiClient } from "../../../tools/api-client";
import { FoundMeasurement } from "../found-measurement";
import { Observable } from "rxjs";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class ReturnService {
  constructor(private apiClient: ApiClient) {}

  returnStudy(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(`/api/measurements/${measurementId}/return`)
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
