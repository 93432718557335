import {LocalizationStrings} from "../../../i18n/i18n-model";
import {FormFieldConfig, translateFormValue} from "../../../tools/form/form-field-config";
import {UploadFormModel} from "../../upload/upload-form-model";
import {Analysis, BpFindingsModel, createUploadFormConfig} from "../analysis";
import {ReportValue} from "../report";
import {BpReportModel, BpReportPatientData} from "./bp-report-model";
import {formatDate, formatDateTime, FormatType} from "../../../tools/date-time-utils";
import {PatientData} from "../../upload/upload-model";
import {UploadFormConfig} from "../../upload/upload-form-config";
import {DateTime} from "luxon";

export class BpReportModelFactory {
  constructor(
    private readonly translate: (key: keyof LocalizationStrings) => string,
    private readonly dateTimeTransform: (date?: string) => string,
    private readonly now: () => DateTime,
    private readonly userToken: string
  ) {
  }

  createBpReportModel(analysis: Analysis, findings: BpFindingsModel): BpReportModel {
    const config = createUploadFormConfig(analysis);

    const patient = [this.createFormFieldValue(config.gender), this.createFormFieldValue(config.age)].filter((s) => s!).join(", ");

    return {
      uniqueId: this.reportValue("reportUniqueIdLabel", analysis.uniqueId),
      measurementCode: this.reportValue("uploadFormLabelCode", analysis.code),
      patient: this.reportValue("reportLabelPatient", patient),
      startTime: this.reportValue("analysisLabelStartTime", this.dateTimeTransform(analysis.startTime)),
      findings: findings,
      findingsSectionTitle: this.translate("reportFindingsSectionTitle"),
      conclusion: this.reportValue("analysisLabelConclusion", analysis.doctorFindings.conclusion),
      reportDate: formatDate(this.now()),
      doctor: {
        fullName: `${analysis.doctor.firstName} ${analysis.doctor.lastName}`.trim(),
        city: analysis.doctor.city || "",
        title: analysis.doctor.title || "",
      },
      patientDataPageTitle: this.translate("reportPatientDataPageTitle"),
      findingsPageTitle: this.translate("bpReportBPmonitoring24h"),
      pageLabel: this.translate("reportPageLabel"),
      patientData: this.createReportPatientData(analysis.patientData, config),
      logoUrlPath: `api/measurements/${analysis.id}/report/logo?token=${this.userToken}`
    };
  }

  private reportValue(label: keyof LocalizationStrings | undefined, value: string | undefined): ReportValue {
    return {
      label: label ? this.translate(label) : "",
      value: value || "",
    };
  }

  private createFormFieldValue(formFieldConfig: FormFieldConfig<UploadFormModel> | undefined): string {
    if (formFieldConfig === undefined) {
      return "";
    }

    const formValue = translateFormValue(formFieldConfig, formFieldConfig.model[formFieldConfig.property], (key) =>
      this.translate(key));
    const infoLabelValue = formFieldConfig.infoLabel && !!formValue ? this.translate(formFieldConfig.infoLabel) : undefined;
    return [formValue, infoLabelValue].filter((s) => !!s).join(" ");
  }

  private createReportPatientData(patientData: PatientData, config: UploadFormConfig): BpReportPatientData {
    return {
      age: this.patientDataReportValue(config.age),
      height: this.patientDataReportValue(config.height),
      weight: this.patientDataReportValue(config.weight),
      gender: this.patientDataReportValue(config.gender),
      overweight: this.patientDataReportValue(config.overweight),
      waistline: this.patientDataReportValue(config.waistline),
      cholesterol: this.patientDataReportValue(config.cholesterol),
      smoking: this.patientDataReportValue(config.smoking),
      diabetes: this.patientDataReportValue(config.diabetes),
      cerebrovascularDisorders: this.patientDataReportValue(config.cerebrovascularDisorders),
      coronaryArteryDisease: this.patientDataReportValue(config.coronaryArteryDisease),
      coronaryInsufficiency: this.patientDataReportValue(config.coronaryInsufficiency),
      currentMedication: this.patientDataReportValue(config.currentMedication),
      nurseComments: this.patientDataReportValue(config.nurseComments),
      parentsHeartAttackUnder60: this.patientDataReportValue(config.parentsHeartAttackUnder60),
      parentsStrokeUnder75: this.patientDataReportValue(config.parentsStrokeUnder75),
      diagnosedHeartDisease: this.patientDataReportValue(config.diagnosedHeartDisease),
      goToBedTime: {
        label: this.translate("uploadFormLabelGoToBedTime"),
        value: formatDateTime(patientData.goToBedTime, FormatType.LOCAL)
      },
      wakeUpTime: {
        label: this.translate("uploadFormLabelWakeUpTime"),
        value: formatDateTime(patientData.wakeUpTime, FormatType.LOCAL)
      },
      kidneyDisease: this.patientDataReportValue(config.kidneyDisease),
      sleepMeasurementNight: this.patientDataReportValue(config.sleepMeasurementNight),
      reasonForStudy: this.patientDataReportValue(config.reasonForStudy),
      patientFeedback: this.patientDataReportValue(config.patientFeedback),
      diary: this.patientDataReportValue(config.diary)
    };
  }

  private patientDataReportValue(formFieldConfig: FormFieldConfig<UploadFormModel> | undefined): ReportValue {
    return this.reportValue(formFieldConfig?.label, this.createFormFieldValue(formFieldConfig));
  }
}
