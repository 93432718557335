import {Observable, throwError} from "rxjs";
import {AuthenticatedUser, isValidAuthenticatedUser} from "../authentication/authenticated-user";
import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {tap} from "rxjs/operators";

@Injectable()
export class LoginService {
  constructor(private apiClient: ApiClient) {
  }

  login(credentials: Credentials): Observable<AuthenticatedUser> {
    return this.validate("/api/login", credentials);
  }

  verifyOtp(credentials: Credentials): Observable<AuthenticatedUser> {
    return this.validate("/api/login/otp", credentials);
  }

  private validate(url: string, credentials: Credentials): Observable<AuthenticatedUser> {
    return this.apiClient.post<AuthenticatedUser>(
      url,
      credentials
    ).pipe(tap((user) => {
      if (!user.otpRequired) {
        if (!isValidAuthenticatedUser(user)) {
          return throwError(new Error("Invalid user data: " + JSON.stringify(user)));
        }
      }
    }));
  }
}

export interface Credentials {
  readonly username: string;
  readonly password: string;
  readonly otp?: string;
}
