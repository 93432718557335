export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  hospital?: string;
  role: String;
  active: boolean;
}

export interface UserListItemViewControl {
  passwordResetInProgress?: boolean;
  userActiveStatusChangeInProgress?: boolean;
}

export enum UserListAction {
  RESET_PASSWORD,
  ACTIVATE_USER,
  DEACTIVATE_USER,
}
