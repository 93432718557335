import { Component, Input, OnInit } from "@angular/core";
import { FormFieldConfig } from "./form-field-config";
import { LocalizationStrings } from "../../i18n/i18n-model";
import { SelectionOptions } from "./form-field.component";

@Component({
  selector: "form-header",
  template: " <form-field [config]=\"config\"></form-field> ",
})
export class FormHeaderComponent implements OnInit {
  @Input() label: keyof LocalizationStrings | undefined;
  @Input() columnLabels: (keyof LocalizationStrings)[] = [];

  config: FormFieldConfig<any> | undefined;

  ngOnInit(): void {
    this.config = {
      type: "header",
      label: this.label,
      isValid: () => undefined,
      propertyChangeListener: {
        onPropertyChange: () => {},
      },
      property: "",
      model: {},
      selectionOptions: this.createOptionItems(),
    } as FormFieldConfig<any>;
  }

  private createOptionItems() {
    const optionItems =
      this.columnLabels.map((columnLabel) => ({
        text: columnLabel,
        value: "",
      }));

    return SelectionOptions.inColumnsWith<string>(...optionItems);
  }
}
