<div data-testid="form-BLOOD_PRESSURE">

  <form-field [config]="formConfig.measurementStartTime"></form-field>

  <hr *ngIf="!responsiveLayout"/>

  <form-field [config]="formConfig.reasonForStudy"></form-field>
  <form-field [config]="formConfig.gender"></form-field>
  <form-field [config]="formConfig.age"></form-field>
  <form-field [config]="formConfig.height"></form-field>
  <form-field [config]="formConfig.weight"></form-field>
  <form-field [config]="formConfig.waistline"></form-field>
  <form-field [config]="formConfig.cholesterol"></form-field>

  <hr/>

  <form-field [config]="formConfig.smoking"></form-field>
  <form-field [config]="formConfig.diabetes"></form-field>
  <form-field [config]="formConfig.coronaryArteryDisease"></form-field>
  <form-field [config]="formConfig.coronaryInsufficiency"></form-field>
  <form-field [config]="formConfig.cerebrovascularDisorders"></form-field>
  <form-field [config]="formConfig.kidneyDisease"></form-field>

  <hr/>

  <form-field [config]="formConfig.parentsHeartAttackUnder60"></form-field>
  <form-field [config]="formConfig.parentsStrokeUnder75"></form-field>

  <hr/>

  <form-field [config]="formConfig.diagnosedHeartDisease"></form-field>

  <hr/>

  <form-field [config]="formConfig.currentMedication"></form-field>

  <div
    data-testid="post-measurement-form-BLOOD_PRESSURE"
    *ngIf="!hidePostMeasurementFields"
  >

    <hr/>

    <form-field [config]="formConfig.diary"></form-field>

    <hr/>

    <form-field [config]="formConfig.sleepMeasurementNight"></form-field>
    <form-field [config]="formConfig.goToBedTime"></form-field>
    <form-field [config]="formConfig.wakeUpTime"></form-field>

    <hr/>

    <form-field [config]="formConfig.patientFeedback"></form-field>
    <hr/>

    <form-field [config]="formConfig.nurseComments"></form-field>

  </div>
</div>
