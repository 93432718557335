<div class="container fixed-top in-front-of-everything" data-testid="notifications">
  <div
    [attr.data-testid]="item.type.toString() + '-notification'"
    *ngFor="let item of notificationItems"
    class="alert mb-0"
    [ngClass]="{
      'alert-danger': item.type === 'error',
      'alert-warning': item.type === 'warning',
      'alert-success': item.type === 'ok'
    }"
  >
    {{ item.message | localize }}
    <button type="button" class="close" aria-label="Close" (click)="deleteNotification(item.id)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
