<form class="card-body">
  <form-field [config]="formConfig.username"></form-field>
  <form-field [config]="formConfig.password"></form-field>

  <action-button
    buttonClass="btn btn-primary d-block w-50 mx-auto"
    buttonLabel="loginFormButtonLoginLabel"
    actionInProgressLabel="loginFormButtonLoginInProgressLabel"
    [actionInProgress]="authenticationInProgress$ | async"
    (buttonClick)="onLoginClick()"
    [testId]="'login-button'"
  >
  </action-button>
</form>
