import {FormValidator} from "../../tools/form/form-validator";
import {ChangePasswordModel} from "./change-password.component";

export class ChangePasswordValidator extends FormValidator<ChangePasswordModel> {
  constructor(changePasswordModel: ChangePasswordModel) {
    super(changePasswordModel, new Map([
      ["newPassword", () => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?!.*[\s]).{8,20}$/.test(changePasswordModel.newPassword)],
      ["retypedPassword", () => changePasswordModel.newPassword === changePasswordModel.retypedPassword]
    ]));
  }
}
