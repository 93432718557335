import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Doctor} from "../doctor";
import {DoctorSelectorService} from "./doctor-selector.service";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";

const NON_EXISTANT_DOCTOR_ID: DoctorIdSelection = -1;

type DoctorIdSelection = number | undefined;

@Component({
  selector: "doctor-selector",
  templateUrl: "doctor-selector.component.html",
})
export class DoctorSelectorComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter<Doctor | undefined>();

  @Input() currentlyAssignedDoctor: Doctor | undefined;

  @Input() markCurrentDoctor: boolean = true;

  @Input() chainName: string | undefined;

  public readonly CHAIN_OPTION_VALUE: DoctorIdSelection = undefined;

  selectedDoctorId: DoctorIdSelection = NON_EXISTANT_DOCTOR_ID;


  constructor(public doctorSelectorService: DoctorSelectorService) {
  }

  isChainFeatureReleased(): boolean {
    return ReleaseToggleState.getInstance().isReleased("ASSIGN_TO_CHAIN_282");
  }

  ngOnInit(): void {
    this.selectedDoctorId = this.currentlyAssignedDoctor
      ? this.currentlyAssignedDoctor.id
      : this.chainName
        ? this.CHAIN_OPTION_VALUE
        : NON_EXISTANT_DOCTOR_ID;
  }

  doctorSelected(selectedDoctorId: DoctorIdSelection) {
    this.selectedDoctorId = selectedDoctorId;
    const selectedDoctor = this.doctorSelectorService.doctors.find((doctor) => doctor.id === selectedDoctorId);
    this.selectionChanged.emit(selectedDoctor);
  }
}
