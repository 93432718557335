<div class="container container--available-viewport-height">
  <div class="row justify-content-sm-center mb-3">
    <h5>{{ 'hospitalsTitle' | localize }}</h5>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <div class="grid grid-striped" data-testId="hospital-list">
        <div class="row" *ngFor="let hospital of hospitals">
          <div class="col-11" data-testid='hospital'>{{hospital.name}}</div>
          <div class="col-1">
            <a
              [attr.data-testid]="'edit-button-' + hospital.name"
              class="btn btn-sm btn-secondary"
              [title]="'hospitalsEditButton' | localize"
              (click)="edit(hospital)">
              <i class="fa fa-edit"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
