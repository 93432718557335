import {Injectable} from "@angular/core";
import {ApiClient} from "../../../tools/api-client";
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";
import {ExternalFindings} from "./external-findings";

@Injectable()
export class DownloadExternalFindingsService {
  constructor(private readonly apiClient: ApiClient) {
  }

  getExternalFindings(measurementId: number): Observable<ExternalFindings> {
    return this.apiClient
      .get<ExternalFindings>(`/api/measurements/${measurementId}/analysis/generator/findings`)
      .pipe(map(recursiveNullToUndefined));
  }
}


