import {LanguageCode} from "./i18n-model";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {ActivatedRoute} from "@angular/router";

export abstract class LanguageProvider {
  abstract getLanguage(overrideReportLanguage: boolean): LanguageCode;
  abstract getLanguage(): LanguageCode;
}

@Injectable()
export class UserLanguageProvider extends LanguageProvider {

  constructor(private readonly activatedRoute: ActivatedRoute) {
    super();
  }

  getLanguage(overrideReportLanguage: boolean = false): LanguageCode {
    const reportLanguage = this.activatedRoute.snapshot?.queryParams?.reportLanguage;

    if (reportLanguage !== undefined && overrideReportLanguage === false) {
      return toLanguageCode(reportLanguage);
    }

    return toLanguageCode(environment.language);
  }
}

export function toLanguageCode(language: string): LanguageCode {
  switch (language) {
    case "fi":
      return LanguageCode.FI_FI;
    case "en":
      return LanguageCode.EN_US;
    case "sv":
      return LanguageCode.SV_SV;
    default:
      return environment.eforms ? LanguageCode.EN_US : LanguageCode.FI_FI;
  }
}
