import {OtpFormModel} from "../login-form-model";
import {FormFieldConfig, PropertyChangeListener} from "../../../tools/form/form-field-config";
import {FormConfigBase} from "../../../tools/form/form-config-base";


export class OtpConfig extends FormConfigBase<OtpFormModel> {
  constructor(
    model: OtpFormModel,
    propertyChangeListener: PropertyChangeListener<OtpFormModel>,
    validationProvider: (property: keyof OtpFormModel) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly otp: FormFieldConfig<OtpFormModel> = this.createField({
    property: "otp",
    type: "text",
    label: "loginFormLabelOtp",
    invalidLabel: "loginFormOtpInvalidLabel",
    alignLabelRight: true
  });
}
