<form class="row form-group align-items-center">
  <div class="col">
    <div class="row">
      <label class="col-form-label col-auto">{{"timeFilterFromLabel"  | localize}}</label>
      <div class="col-auto">
        <app-date-time-picker
          [dateTime]="from"
          (dateTimeChange)="OnFromDateTimeChange($event!)"
          [enableTimePicker]="false">
        </app-date-time-picker>
      </div>

      <label class="col-form-label col-auto">{{"timeFilterToLabel"  | localize}}</label>
      <div class="col-auto">
        <app-date-time-picker
          [dateTime]="to"
          (dateTimeChange)="OnToDateTimeChange($event!)"
          [enableTimePicker]="false">
        </app-date-time-picker>
      </div>

      <div class="col-auto">
        <action-button
          [buttonDisabled]="!!errorMessage"
          [testId]="'time-filter-search'"
          buttonLabel="timeFilterSearchButton"
          actionInProgressLabel="timeFilterLoadingLabel"
          [actionInProgress]="loadingInProgress"
          (buttonClick)="onSearchClick()">
        </action-button>
      </div>
    </div>
  </div>
</form>
<div class="form-row">
  <span *ngIf="!!errorMessage" class="alert alert-danger ml-2 col-sm">
        {{ errorMessage | localize }}
      </span>
</div>
