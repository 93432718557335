import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {localizationKey} from "../../../../../i18n/i18n-model";
import {AnalysisGenerationConfig, DoctorFindings} from "../../../analysis";
import {FormFieldConfig} from "../../../../../tools/form/form-field-config";
import {DownloadExternalFindingsService} from "../../download-external-findings.service";
import {generateFindings} from "./generate-findings";
import {SelectionGeneratedField} from "../widgets/selection-generator/selection-generated.field";
import {LanguageProvider} from "../../../../../i18n/language-provider";
import {joinSentences} from "../join-sentences";
import {areExternalFindingsEqual} from "./are-external-findings-equal";
import {areExternalFindingsMissingFields} from "./are-external-findings-missing-fields";
import {ExternalFindings} from "../../external-findings";


export interface ValueFindings {
  readonly prevailingRhythm: ValueGeneratorData;
  readonly averageHeartRate: ValueGeneratorData;
  readonly minimumHeartRate: ValueGeneratorData;
  readonly maximumHeartRate: ValueGeneratorData;
  readonly pausesOver2_5s: ValueGeneratorData;
  readonly ventricularBeats: ValueGeneratorData;
  readonly ventricularTachycardias: ValueGeneratorData;
  readonly commentVentricular: ValueGeneratorData;
  readonly atrialBeats: ValueGeneratorData;
  readonly atrialTachycardias: ValueGeneratorData;
  readonly commentAtrial: ValueGeneratorData;
  readonly symptomDiaryComment: ValueGeneratorData;
}

export interface SelectionFindings {
  avConduction: SelectionGeneratedField;
  deltaWave: SelectionGeneratedField;
  qrsWidth: SelectionGeneratedField;
  qtTime: SelectionGeneratedField;
  stSegment: SelectionGeneratedField;
}

interface ValueGeneratorData {
  value: string | undefined;
  text: string | undefined;
}

function createBlankValueGeneratorData(): ValueGeneratorData {
  return {
    value: "",
    text: ""
  };
}

@Component({
  selector: "app-findings-generator",
  templateUrl: "./findings-generator.component.html",
})
export class FindingsGeneratorComponent implements OnInit {

  @Input()
    analysisGenerationConfig?: AnalysisGenerationConfig;

  @Input()
    config?: FormFieldConfig<DoctorFindings>;

  @Input()
    measurementId?: number;

  @Output()
  readonly onGenerate = new EventEmitter<string>();

  @Input()
    isInitiallyManualMode = false;

  isManualMode = false;

  areValuesOutdated = false;

  isWaitingForRegenerateConfirmation = false;

  isWaitingForOutdatedConfirm = false;

  areExternalFindingsMissingFields = false;

  valueFindings: ValueFindings = {
    prevailingRhythm: createBlankValueGeneratorData(),
    averageHeartRate: createBlankValueGeneratorData(),
    minimumHeartRate: createBlankValueGeneratorData(),
    maximumHeartRate: createBlankValueGeneratorData(),
    pausesOver2_5s: createBlankValueGeneratorData(),
    ventricularBeats: createBlankValueGeneratorData(),
    ventricularTachycardias: createBlankValueGeneratorData(),
    atrialBeats: createBlankValueGeneratorData(),
    atrialTachycardias: createBlankValueGeneratorData(),
    commentVentricular: createBlankValueGeneratorData(),
    commentAtrial: createBlankValueGeneratorData(),
    symptomDiaryComment: createBlankValueGeneratorData(),
  };

  selectionFindings?: SelectionFindings;

  @ViewChild("templateConfirmPatientStatusCommentsRegenerate")
    templateConfirmPatientStatusCommentsRegenerate!: TemplateRef<any>;

  preview = "";

  private previousExternalFindings: ExternalFindings | undefined;

  constructor(
    private readonly downloadExternalFindingsService: DownloadExternalFindingsService,
    private readonly languageProvider: LanguageProvider
  ) {
  }

  ngOnInit(): void {
    this.isManualMode = this.isInitiallyManualMode;
    this.selectionFindings = this.createFindingsGeneratorOptions();
  }

  findingGenerated(findingType: keyof ValueFindings, generatedText: string) {
    this.valueFindings[findingType].text = generatedText;
    this.generatePreview();
  }

  private generatePreview(): void {

    const preview: (string | undefined)[] = [
      this.valueFindings.prevailingRhythm.text,
      this.valueFindings.averageHeartRate.text,
      this.valueFindings.minimumHeartRate.text,
      this.valueFindings.maximumHeartRate.text,
      this.valueFindings.pausesOver2_5s.text,
      this.valueFindings.ventricularBeats.text,
      this.valueFindings.ventricularTachycardias.text,
      this.valueFindings.commentVentricular.text,
      this.valueFindings.atrialBeats.text,
      this.valueFindings.atrialTachycardias.text,
      this.valueFindings.commentAtrial.text,
      this.selectionFindings!.avConduction.text,
      this.selectionFindings!.deltaWave.text,
      this.selectionFindings!.qrsWidth.text,
      this.selectionFindings!.qtTime.text,
      this.selectionFindings!.stSegment.text,
      this.valueFindings.symptomDiaryComment.text
    ];

    const content = joinSentences(preview);

    this.preview = content;
    this.onGenerate.emit(content);
  }

  regenerate() {
    this.isManualMode = false;
    this.generatePreview();
  }

  localizationKey = localizationKey;

  getExternalFindings() {
    this.downloadExternalFindingsService
      .getExternalFindings(this.measurementId!)
      .subscribe(externalFindings => {
        this.valueFindings = generateFindings({
          externalFindings,
          analysisGenerationConfig: this.analysisGenerationConfig!,
          previousFindings: this.valueFindings,
          locale: this.languageProvider.getLanguage()
        }
        );
        this.previousExternalFindings = externalFindings;
        this.areValuesOutdated = false;
        this.generatePreview();
        this.areExternalFindingsMissingFields = areExternalFindingsMissingFields(externalFindings);
      });
  }

  private createFindingsGeneratorOptions(): SelectionFindings {
    return {
      avConduction: new SelectionGeneratedField(
        [
          {
            optionLabel: "generator_findings_option_normal",
            generatedText: this.analysisGenerationConfig!.findings_avConduction_normal
          }
        ]),
      deltaWave: new SelectionGeneratedField([
        {
          optionLabel: "generator_findings_option_normal",
          generatedText: this.analysisGenerationConfig!.findings_deltaWave_normal
        }
      ]),
      qtTime: new SelectionGeneratedField([
        {
          optionLabel: "generator_findings_option_normal",
          generatedText: this.analysisGenerationConfig!.findings_qtTime_normal
        }
      ]),
      qrsWidth: new SelectionGeneratedField([
        {
          optionLabel: "generator_findings_option_normal",
          generatedText: this.analysisGenerationConfig!.findings_qrsWidth_normal
        }
      ]),
      stSegment: new SelectionGeneratedField([
        {
          optionLabel: "generator_findings_option_normal",
          generatedText: this.analysisGenerationConfig!.findings_stSegment_normal
        }
      ])
    };
  };

  selectionFindingGenerated(field: keyof SelectionFindings, newValue: string) {
    this.selectionFindings![field].text = newValue;
    this.generatePreview();
  }

  switchToManualMode() {
    this.isWaitingForOutdatedConfirm = true;
    this.downloadExternalFindingsService
      .getExternalFindings(this.measurementId!)
      .subscribe(externalFindings => {

        const valuesAreOutdated =
            this.previousExternalFindings !== undefined
            && !areExternalFindingsEqual(externalFindings, this.previousExternalFindings);

        this.isManualMode = !valuesAreOutdated;
        this.areValuesOutdated = valuesAreOutdated;
      },
      () => {
      },
      () => {
        this.isWaitingForOutdatedConfirm = false;
      });
  }
}

