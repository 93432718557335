export class FileChunker {
  private file: File;
  private chunkSize: number;
  private current = 0;

  constructor(file: File, chunkSize: number) {
    this.file = file;
    this.chunkSize = chunkSize;
  }

  hasNextChunk() {
    return this.current < this.file.size;
  }

  getNextChunk(): Blob {
    const end = (this.current + this.chunkSize) < this.file.size ? this.current + this.chunkSize : this.file.size;
    const chunk = this.file.slice(this.current, end);
    this.current = end;
    return chunk;
  }

  getProgress() {
    return (this.current / this.file.size * 100);
  }

  getStart() {
    return this.current;
  }
}
