import {UploadFormModel} from "./upload-form-model";
import {EcgSymptom, emptyPatientData, PatientData, UploadRequest, UploadInitiatedData} from "./upload-model";
import {MeasurementType} from "../measurement-models";

export function mapFormToData(uploadFormModel: UploadFormModel): UploadRequest {
  return {
    code: uploadFormModel.measurementCode,
    type: uploadFormModel.measurementType as MeasurementType,
    measurementDataFileName: uploadFormModel.measurementFileName,
    measurementStartTime: uploadFormModel.measurementStartTime,
    patientData: mapToPatientData(uploadFormModel, "REMOVE_EMPTY_AND_PARTIAL_SYMPTOMS"),
    sessionId: uploadFormModel.measurementSessionId,
    nurseComments: sanitizeNurseComments(uploadFormModel.measurementNurseComments),
    reportLanguage: uploadFormModel.reportLanguage
  };
}

export function mapFormToUploadInitiatedData(uploadFormModel: UploadFormModel, measurementId: number): UploadInitiatedData {
  return {
    measurementId: measurementId,
    fileName: uploadFormModel.measurementFileName,
    measurementStartTime: uploadFormModel.measurementStartTime,
    patientData: mapToPatientData(uploadFormModel, "REMOVE_EMPTY_AND_PARTIAL_SYMPTOMS"),
    sessionId: uploadFormModel.measurementSessionId,
    nurseComments: sanitizeNurseComments(uploadFormModel.measurementNurseComments),
    reportLanguage: uploadFormModel.reportLanguage
  };
}

export function mapToPatientData(source: UploadFormModel,
  ecgSymptomHandling: "REMOVE_EMPTY_SYMPTOMS" | "REMOVE_EMPTY_AND_PARTIAL_SYMPTOMS"): PatientData {

  const result = emptyPatientData();

  // @ts-ignore
  Object.keys(result).forEach((key) => (result[key] = source[key]));

  if (ecgSymptomHandling == "REMOVE_EMPTY_AND_PARTIAL_SYMPTOMS") {
    result.symptoms = removeEmptyAndPartialSymptoms(result.symptoms);
  }

  if (ecgSymptomHandling == "REMOVE_EMPTY_SYMPTOMS") {
    result.symptoms = removeEmptySymptoms(result.symptoms);
  }

  return result;
}

function removeEmptyAndPartialSymptoms(symptoms: EcgSymptom[]): EcgSymptom[] {
  return symptoms.filter((symptom) => !!symptom.description && !!symptom.time);
}

function removeEmptySymptoms(symptoms: EcgSymptom[]): EcgSymptom[] {
  return symptoms.filter((symptom) => !!symptom.description || !!symptom.time);
}


function sanitizeNurseComments(nurseComments?: string): string | undefined {
  if (nurseComments != undefined && nurseComments.trim().length > 0) {
    return nurseComments;
  }
  return undefined;
}
