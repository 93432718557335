import {Injectable} from "@angular/core";
import {DateTime} from "luxon";

@Injectable()
export class DateTimeSerializationService {

  serializeToUtcIsoString(from: DateTime) {
    return from.toUTC().toISO();
  }

  serializeWithoutOffset(dateTime: DateTime) {
    return dateTime.toISO({
      includeOffset: false,
      suppressMilliseconds: true
    });
  }

  serializeWithOffset(dateTime: DateTime): string {
    return dateTime.toISO()!;
  }

}
