import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { RouteNavigator } from "../../tools/navigation/route-navigator.service";
import { NavigationRoute } from "../../tools/navigation/navigation-route";
import { UserRole } from "./authenticated-user";
import { Notifications, NotificationType } from "../../tools/notifications/notifications";
import { localizationKey } from "../../i18n/i18n-model";
import { RouteData } from "../../app-routing.module";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly routeNavigator: RouteNavigator,
    private readonly authenticationService: AuthenticationService,
    private readonly notifications: Notifications
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.getCurrentAuthenticatedUser();

    if (!currentUser) {
      this.routeNavigator.navigateTo(NavigationRoute.LOGIN);
      return false;
    }

    const routeData: RouteData = route.data as RouteData;
    const allowedRoles: UserRole[] = routeData && routeData.allowedRoles ? routeData.allowedRoles : [];

    if (!allowedRoles.includes(currentUser.role)) {
      this.notifications.addNotification(NotificationType.ERROR, localizationKey("authGuardForbidden"));
      this.routeNavigator.navigateTo(NavigationRoute.HOME);
      return false;
    }

    return true;
  }
}
