import {HospitalInformation} from "./hospital-information";
import {isNonRequiredTextOfMaxLength} from "../../validations";
import {FormValidator} from "../../../tools/form/form-validator";

export class HospitalInformationValidator extends FormValidator<HospitalInformation> {

  constructor(hospitalInformation: HospitalInformation) {
    super(hospitalInformation, new Map<keyof HospitalInformation, (value: any) => boolean>()
      .set("publicName", (value: string) => isNonRequiredTextOfMaxLength(100, value))
      .set("operatorAppointmentDescription", (value: string) => isNonRequiredTextOfMaxLength(1000, value))
      .set("address", (value: string) => isNonRequiredTextOfMaxLength(1000, value))
      .set("postalCode", (value: string) => isNonRequiredTextOfMaxLength(100, value))
      .set("city", (value: string) => isNonRequiredTextOfMaxLength(100, value)));
  }
}
