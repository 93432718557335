import { MeasurementType } from "../measurement-models";
import { BpAnalysisComponent } from "./blood-pressure/bp-analysis.component";
import { BpFindingsValidationResult, BpValidatedFindings } from "./blood-pressure/bp-findings-validator";
import { EcgAnalysisComponent } from "./ecg/ecg-analysis.component";
import { EcgFindingsValidationResult, EcgValidatedFindings } from "./ecg/ecg-findings-validator";

type ValidatedFindingsType<T extends EcgAnalysisComponent | BpAnalysisComponent> = T extends EcgAnalysisComponent
  ? EcgValidatedFindings
  : BpValidatedFindings;

type FindingsValidationResultType<T extends EcgAnalysisComponent | BpAnalysisComponent> = T extends EcgAnalysisComponent
  ? EcgFindingsValidationResult
  : BpFindingsValidationResult;

export abstract class FindingsValidator<T extends EcgAnalysisComponent | BpAnalysisComponent> {
  validate(validatedFindings: ValidatedFindingsType<T>, isFinishAnalysis: boolean): FindingsValidationResultType<T> {
    return isFinishAnalysis ? this.validateFinishAnalysis(validatedFindings) : this.validateSaveAnalysis(validatedFindings);
  }

  abstract validateFinishAnalysis(validatedFindings: ValidatedFindingsType<T>): FindingsValidationResultType<T>;

  abstract validateSaveAnalysis(validatedFindings: ValidatedFindingsType<T>): FindingsValidationResultType<T>;
}
export interface ValidatedFindings {
  measurementType: MeasurementType;
  measurementCode: string;
}

export interface ValidationResult {
  readonly simpleResult: boolean | undefined;
  readonly arrayResult: (boolean | undefined)[];
}

export function arrayValidationResult(arrayResult: (boolean | undefined)[]): ValidationResult {
  return { simpleResult: undefined, arrayResult };
}

export function simpleValidationResult(result: boolean | undefined): ValidationResult {
  return { simpleResult: result, arrayResult: [] };
}
