import { LocalizationStrings } from "../../../i18n/i18n-model";
import { FormConfigBase } from "../../../tools/form/form-config-base";
import { FormFieldConfig, PropertyChangeListener } from "../../../tools/form/form-field-config";
import {DoctorFindings} from "../analysis";


export class BpDoctorFindingsFormConfig extends FormConfigBase<DoctorFindings> {
  constructor(
    model: DoctorFindings,
    propertyChangeListener?: PropertyChangeListener<DoctorFindings>,
    validationProvider?: (property: keyof DoctorFindings) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly conclusion = this.createFindingField("conclusion", "analysisLabelConclusion");

  private createFindingField(property: keyof DoctorFindings, label: keyof LocalizationStrings): FormFieldConfig<DoctorFindings> {
    return this.createField({
      property,
      label,
      type: "textarea",
      verticalLayout: true,
      invalidLabel: "analysisFormInvalidLabelRequired",
    });
  }
}
