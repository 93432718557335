<div
  data-testid="eform-non-home-header"
  *ngIf="!isHomeMeasurement"
>
  <p>{{ "eFormHeaderText_paragraph1" | localize }}</p>
  <p>{{ "eFormHeaderText_paragraph2" | localize }}</p>
</div>

<div
  data-testid="eform-home-header"
  *ngIf="isHomeMeasurement"
>
  TODO!
</div>
