import {MeasurementType} from "../measurement-models";
import {Doctor} from "./doctor";
import {emptyPatientData, PatientData} from "../upload/upload-model";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {UserRole} from "../authentication/authenticated-user";

export interface FoundMeasurement {
  readonly id: number;
  readonly code: string | undefined;
  readonly uniqueId: string | undefined;
  readonly uploadTime: string | undefined;
  readonly type: MeasurementType;
  readonly status: MeasurementStatus;
  readonly hospitalName: string;
  readonly patientData: PatientData | undefined;
  readonly bmi: number | undefined;
  readonly essScore: number | undefined;
  readonly doctor: Doctor | undefined;
  readonly deliveryTime: string | undefined;
  readonly durationDays: number | undefined;
  readonly nurse: Nurse;
  readonly startTime: string | undefined;
  readonly nurseDownloadedReport: boolean;
  readonly deviceSerialNumber?: string;
  readonly initiateTime: string | undefined;

  readonly hasOperatorHospital: boolean;

  readonly asyncJobStatus?: AsyncJobStatus;

  readonly deleted: boolean;
  readonly commentCount?: number;

  readonly technician?: Doctor;

  readonly reportLanguage?: string;

  readonly fileName?: string;
  readonly dataSize?: number;

  readonly combinedReportExists: boolean;

  readonly chain: Chain | undefined;
  readonly isHome: boolean;
  readonly specifiedHomeHolterDuration: number | undefined;
}

export interface Nurse {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email: string;
}

export interface Chain {
  readonly id: number;
  readonly name: string;
}

export const ALL_MEASUREMENT_STATUSES = [
  "UPLOADED",
  "READY_FOR_ANALYSIS",
  "READY_FOR_POST_ANALYSIS",
  "DONE",
  "REJECTED_BY_DATA_OFFICER",
  "PRE_ANALYSED",
  "INITIATED",
] as const;
type MeasurementStatusTuple = typeof ALL_MEASUREMENT_STATUSES;
export type MeasurementStatus = MeasurementStatusTuple[number]; // union type


export const ALL_HOME_MEASUREMENT_STATUSES = [
  "WAITING_FOR_MAILING_INFO",
  "MAILING_TO_PATIENT"
] as const;
type HomeMeasurementStatusTuple = typeof ALL_HOME_MEASUREMENT_STATUSES;
export type HomeMeasurementStatus = HomeMeasurementStatusTuple[number]; // union type

export const measurementStatusLocalizationKeys: ReadonlyMap<MeasurementStatus, ReadonlyMap<UserRole, keyof LocalizationStrings>> = new Map<
MeasurementStatus,
ReadonlyMap<UserRole, keyof LocalizationStrings>
>()
  .set(
    "UPLOADED",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusUploaded")
      .set("NURSE", "nurseMeasurementStatusProcessing")
      .set("ORDERING_NURSE", "nurseMeasurementStatusProcessing")
  )
  .set(
    "READY_FOR_ANALYSIS",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusReadyForAnalysis")
      .set("NURSE", "nurseMeasurementStatusProcessing")
      .set("DOCTOR", "measurementStatusReadyForAnalysis")
      .set("ORDERING_NURSE", "nurseMeasurementStatusProcessing")
  )
  .set(
    "READY_FOR_POST_ANALYSIS",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusReadyForPostAnalysis")
      .set("NURSE", "nurseMeasurementStatusProcessing")
      .set("ORDERING_NURSE", "nurseMeasurementStatusProcessing")
  )
  .set(
    "DONE",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusDone")
      .set("NURSE", "nurseMeasurementStatusDone")
      .set("ORDERING_NURSE", "nurseMeasurementStatusDone")
      .set("DOCTOR", "measurementStatusDone")
  )
  .set(
    "REJECTED_BY_DATA_OFFICER",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusRejectedByDataOfficer")
      .set("NURSE", "nurseMeasurementStatusRejected")
      .set("ORDERING_NURSE", "nurseMeasurementStatusRejected")
  )
  .set(
    "PRE_ANALYSED",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusPreAnalysed")
      .set("NURSE", "nurseMeasurementStatusProcessing")
      .set("ORDERING_NURSE", "nurseMeasurementStatusProcessing")
  )
  .set(
    "INITIATED",
    new Map<UserRole, keyof LocalizationStrings>()
      .set("DATA_OFFICER", "measurementStatusInitiated")
      .set("NURSE", "measurementStatusInitiated")
      .set("ORDERING_NURSE", "measurementStatusInitiated")
  );

export type AsyncJobStatus = "PENDING" | "FAILED" | "SUCCESS";

export function emptyFoundMeasurement(): FoundMeasurement {
  return {
    id: 0,
    uniqueId: "",
    patientData: emptyPatientData(),
    hospitalName: "",
    code: "",
    uploadTime: undefined,
    type: "SLEEP",
    status: "INITIATED",
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {email: "", firstName: undefined, lastName: undefined},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: undefined,
    hasOperatorHospital: false,
    deleted: true,
    combinedReportExists: false,
    asyncJobStatus: undefined,
    commentCount: undefined,
    dataSize: undefined,
    deviceSerialNumber: undefined,
    fileName: undefined,
    reportLanguage: undefined,
    technician: undefined,
    chain: undefined,
    isHome: false,
    specifiedHomeHolterDuration: undefined
  };
}

export function formatMeasurementFileSize(size: number | undefined): string {
  if (size === undefined) {
    return "";
  }
  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
}
