import {Injectable} from "@angular/core";
import {ApiClient} from "../../../tools/api-client";
import {Observable} from "rxjs";
import { FoundMeasurement } from "../found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class DeleteMeasurementService {

  constructor(private apiClient: ApiClient) {
  }

  deleteInitiatedMeasurement(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient
      .delete<FoundMeasurement>(`/api/measurements/${measurementId}`)
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }

}
