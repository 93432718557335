import {Component, OnInit} from "@angular/core";
import {MeasurementIdProvider} from "../analize/measurement-id-provider";
import {ActivatedRoute} from "@angular/router";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {FormConfigBase} from "../../tools/form/form-config-base";
import {getTypeSelectionOptions} from "../measurement-type-select-options";
import {SelectionOptions} from "../../tools/form/form-field.component";
import {localizationKey, LocalizationStrings} from "../../i18n/i18n-model";
import {FormFieldConfig, PropertyChangeListener} from "../../tools/form/form-field-config";
import {isEmptyOrValidSSN, isExisting} from "../validations";
import {PdfDownloaderService} from "./pdf-downloader.service";
import {PdfFillerService} from "./pdf-filler.service";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {flatMap} from "rxjs/operators";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {PrintFormData, PrintFormService} from "./print-form.service";
import {InitiateMeasurementService} from "../initiate-measurement/initiate-measurement.service";
import {FormValidator} from "../../tools/form/form-validator";
import {NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";
import {ALL_MEASUREMENT_TYPES} from "../measurement-models";
import {getNurseMeasurementType} from "../get-nurse-measurement-type";


@Component({
  selector: "app-print-form",
  templateUrl: "./print-form.component.html"
})
export class PrintFormComponent implements OnInit, PropertyChangeListener<PrintFormModel> {
  printFormModel: PrintFormModel = {} as PrintFormModel;
  errorMessage?: string;
  private printFormValidator = new PrintFormValidator(this.printFormModel);
  formConfig: PrintFormConfig = new PrintFormConfig(
    this.printFormModel,
    this,
    (property) => this.printFormValidator.isValid(property),
    []
  );

  printFormData?: PrintFormData;

  private measurementId?: number;

  constructor(
    private readonly printFormService: PrintFormService,
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly route: ActivatedRoute,
    private readonly navigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly pdfDownloaderService: PdfDownloaderService,
    private readonly pdfFillerService: PdfFillerService,
    private readonly initiateMeasurementService: InitiateMeasurementService
  ) {
  }

  ngOnInit(): void {
    this.measurementId = this.measurementIdProvider.getId(this.route);
    this.printFormService
      .getPrintFormData({
        measurementId: this.measurementId!
      })
      .subscribe(
        (printFormData) => {
          this.printFormData = printFormData;
          this.printFormModel = {
            type: getNurseMeasurementType(
              printFormData.type,
              printFormData.isHome,
              {
                specifiedHolterDuration: printFormData.specifiedHomeHolterDuration
              }
            ),
            code: printFormData.code,
            initiatedTime: printFormData.initiatedTime,
            uniqueId: printFormData.uniqueId,
            language: undefined,
            eFormCode: printFormData.eFormCode
          } as PrintFormModel;
          this.printFormValidator = new PrintFormValidator(this.printFormModel);
          this.formConfig = new PrintFormConfig(
            this.printFormModel,
            this,
            (property) => this.printFormValidator.isValid(property),
            printFormData.availableLanguages
          );
        },
        () => {
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("printOrderFormErrorGettingData"));
        }
      );
  }

  onPropertyChange(property: keyof PrintFormModel, newValue: any): void {
    this.printFormModel[property] = newValue;
    this.printFormValidator.updateValidation(property);

    this.errorMessage = this.printFormValidator.isFormValid() ? "" : "printOrderFormValidationErrorDefault";
  }

  onPrintFormButtonClick() {
    this.printFormValidator.updateAllValidations();

    if (!this.printFormValidator.isFormValid()) {
      this.errorMessage = "printOrderFormValidationErrorDefault";
      return;
    }

    this.pdfDownloaderService
      .getPdfTemplate({
        type: this.printFormData!.type,
        language: this.printFormModel.language!,
        isHome: this.printFormData!.isHome
      })
      .pipe(flatMap((template) => this.pdfFillerService.fillPdf(template.data, this.printFormModel)))
      .subscribe(
        () => {
          this.initiateMeasurementService
            .setInitiatedMeasurementLanguage(this.measurementId!, this.printFormModel.language!)
            .subscribe(
              () => this.navigator.navigateTo(NavigationRoute.LIST),
              () => this.notifications.addNotification(NotificationType.WARNING,
                localizationKey("initiatedMeasurementEFormLanguageNotSaved")));
        },
        (errorResponse: ErrorResponse) => {
          const message = singleCodeFromResponse(errorResponse) === ErrorCode.PATIENT_FORM_TEMPLATE_NOT_FOUND
            ? localizationKey("printFormPatientTemplateNotFound") : localizationKey("printFormFetchError");

          this.notifications.addNotification(NotificationType.ERROR, message);
        });

  }
}

class PrintFormValidator extends FormValidator<PrintFormModel> {

  constructor(hospitalInformation: PrintFormModel) {
    super(hospitalInformation, new Map<keyof PrintFormModel, (value: any) => boolean>()
      .set("ssn", (value: string) => isEmptyOrValidSSN(value))
      .set("language", (value: string) => isExisting(value)));
  }

}


class PrintFormConfig extends FormConfigBase<PrintFormModel> {

  private readonly languageToLocalizationMap = new Map<string, keyof LocalizationStrings>()
    .set("fi", "finnishLang")
    .set("sv", "swedishLang")
    .set("ru", "russianLang")
    .set("en", "englishLang")
  ;

  constructor(model: PrintFormModel,
              propertyChangeListener: PropertyChangeListener<PrintFormModel>,
              validationProvider: (property: keyof PrintFormModel) => (boolean | undefined),
              languageOptions: string[]) {
    super(model, propertyChangeListener, validationProvider);

    this.language = this.createField({
      property: "language",
      label: "printFormLabelLanguage",
      type: "dropdown",
      required: true,
      invalidLabel: "printFormInvalidLabelLanguage",
      readOnly: false,
      selectionOptions: SelectionOptions.with<string>(...languageOptions
        .map(value => ({value: value, text: localizationKey(this.languageToLocalizationMap.get(value)!)})))
    });
  }

  readonly type = this.createField({
    property: "type",
    label: "printOrderFormType",
    type: "dropdown",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelType",
    readOnly: true,
    selectionOptions: getTypeSelectionOptions({
      types: [...ALL_MEASUREMENT_TYPES],
      homeTypes: [...ALL_MEASUREMENT_TYPES]
    })
  });

  readonly initiatedTime = this.createField({
    property: "initiatedTime",
    label: "printOrderFormLabelInitiatedTime",
    type: "localdate",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelInitiatedTime",
    readOnly: true
  });

  readonly name = this.createField({
    property: "name",
    label: "printOrderFormLabelName",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName"
  });

  readonly ssn = this.createField({
    property: "ssn",
    label: "printOrderFormLabelSsn",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelSsn"
  });

  readonly code = this.createField({
    property: "code",
    label: "printOrderFormLabelCode",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelCode",
    readOnly: true
  });

  readonly uniqueId = this.createField({
    property: "uniqueId",
    label: "printOrderFormLabelUniqueId",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelUniqueId",
    readOnly: true
  });

  readonly language: FormFieldConfig<PrintFormModel>;
}

export interface PrintFormModel {
  type: NurseMeasurementType;
  initiatedTime: string;
  name?: string;
  ssn?: string;
  code: string;
  uniqueId: string;
  language?: string;
  eFormCode?: string;
}
