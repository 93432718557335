import {Component, Input} from "@angular/core";
import {HospitalChain} from "../hospital.details";
import {localizationKey} from "../../../../i18n/i18n-model";
import {EditHospitalService} from "../edit-hospital.service";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {languageSelectionOptions} from "../../../../tools/languages";

@Component({
  selector: "app-chain-section[chains][hospitalId][hospitalChain]",
  templateUrl: "./chain-section.component.html",
})
export class ChainSectionComponent {

  selectedChainId: number | undefined = undefined;

  @Input()
  hospitalId!: number;

  @Input()
  chains!: HospitalChain[];

  @Input()
  hospitalChain!: HospitalChain | undefined;

  protected readonly localizationKey = localizationKey;

  constructor(
    private readonly editHospitalService: EditHospitalService,
    private readonly notifications: Notifications
  ) {
  }

  putHospitalInChain() {
    this.editHospitalService.putHospitalInChain({
      hospitalId: this.hospitalId,
      chainId: this.selectedChainId!,
    }).subscribe(
      () => {
        this.notifications.addNotification(NotificationType.OK, localizationKey("hospitalPutHospitalInChainSuccess"));
        this.hospitalChain = this.chains.find(hospital => hospital.id === this.selectedChainId)!;
      },
      () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalPutHospitalInChainFailure"))
    );
  }

  protected readonly languageSelectionOptions = languageSelectionOptions;

  canPutHospitalInChain() {
    return this.selectedChainId !== undefined && this.hospitalChain === undefined;
  }
}
