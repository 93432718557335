import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FoundMeasurement } from "../../found-measurement";
import { UploadFormConfig } from "../../../upload/upload-form-config";
import { PropertyChangeListener } from "../../../../tools/form/form-field-config";
import { emptyUploadFormModel, UploadFormModel } from "../../../upload/upload-form-model";
import { EditMeasurementDataService } from "./edit-measurement-data.service";
import { FormValidator } from "../../../../tools/form/form-validator";
import {
  createMeasurementFormValidationMap,
  uploadFormMutuallyDependentProperties
} from "../../../upload/create-measurement-form-validation-map";
import { Notifications, NotificationType } from "../../../../tools/notifications/notifications";
import { localizationKey } from "../../../../i18n/i18n-model";
import { addEmptyEcgSymptoms } from "../../../utils";
import { mapToPatientData } from "../../../upload/upload-data-mapper";
import { measurementTypeOptions } from "../../../measurement-type-select-options";

@Component({
  selector: "app-edit-measurement",
  templateUrl: "./edit-measurement.component.html"
})
export class EditMeasurementComponent implements OnInit, PropertyChangeListener<UploadFormModel> {

  @Input()
    measurement?: FoundMeasurement;

  @Output()
    onUpdateSuccess: EventEmitter<FoundMeasurement> = new EventEmitter<FoundMeasurement>();

  editMeasurementValidator?: EditMeasurementValidator;

  private uploadFormModel: UploadFormModel = emptyUploadFormModel();

  formConfig: UploadFormConfig = new UploadFormConfig(
    this.uploadFormModel,
    this,
    (property) => this.editMeasurementValidator!.isValid(property),
    false,
    false,
    measurementTypeOptions,
    true
  );

  constructor(
    private readonly updatePatientDataService: EditMeasurementDataService,
    private readonly notifications: Notifications
  ) {
  }

  ngOnInit(): void {
    this.fillFormModelWithExistingMeasurementData();
    this.createValidator();
  }

  private fillFormModelWithExistingMeasurementData(): void {
    const measurement = this.measurement!;
    Object.keys(measurement.patientData!).forEach(property => {
      // @ts-ignore
      this.uploadFormModel[property] = measurement.patientData[property];
    });
    this.uploadFormModel.measurementStartTime = measurement.startTime;
    this.uploadFormModel.measurementType = measurement.type;
    this.uploadFormModel.symptoms = this.uploadFormModel.symptoms.map((it) => ({...it}));
    addEmptyEcgSymptoms(this.uploadFormModel);
  }

  onPropertyChange(property: keyof UploadFormModel, newValue: any): void {
    (this.uploadFormModel as any)[property] = newValue;
    this.editMeasurementValidator!.updateValidation(property);

    if (uploadFormMutuallyDependentProperties.includes(property)) {
      uploadFormMutuallyDependentProperties.forEach((p) => this.editMeasurementValidator!.updateValidation(p));
    }
  }

  onSubmit() {
    this.editMeasurementValidator!.updateAllValidations();

    if (!this.editMeasurementValidator!.isFormValid()) {
      return;
    }

    this.updatePatientDataService
      .update(this.measurement!.id, {
        patientData: mapToPatientData(this.uploadFormModel, "REMOVE_EMPTY_SYMPTOMS"),
        measurementStartTime: this.uploadFormModel.measurementStartTime!
      })
      .subscribe(
        (updatedListItem) => {
          this.notifications.addNotification(NotificationType.OK, localizationKey("editMeasurementDataUpdateSuccessMessage"));
          this.onUpdateSuccess.emit(updatedListItem);
        },
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("editMeasurementDataUpdateFailureMessage"))
      );
  }

  private createValidator() {
    this.editMeasurementValidator = new EditMeasurementValidator(this.uploadFormModel);
  }

  protected readonly localizationKey = localizationKey;
}

class EditMeasurementValidator extends FormValidator<UploadFormModel> {

  constructor(formModel: UploadFormModel) {
    super(formModel, createMeasurementFormValidationMap(formModel));
  }
}
