import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NavigationRoute } from "./tools/navigation/navigation-route";
import { EFormLandingPageComponent } from "./features/e-forms/landing-page/e-form-landing-page.component";
import { EFormFormComponent } from "./features/e-forms/form/e-form-form.component";

const routes: Routes = [];
const tempRoutes: Routes = [
  {
    path: NavigationRoute.EFORM_LANDING_PAGE,
    component: EFormLandingPageComponent,
  },
  {
    path: `${NavigationRoute.EFORM_FORM_PAGE}/:eFormId`,
    component: EFormFormComponent,
  },
  {
    path: "**",
    redirectTo: `/${NavigationRoute.EFORM_LANDING_PAGE}`,
  },
];
routes.push(...tempRoutes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class EformRoutingModule {
}
