import { Doctor } from "../doctor";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiClient } from "../../../tools/api-client";

@Injectable()
export class DoctorsService {
  constructor(private readonly apiClient: ApiClient) {}

  getAllDoctors(): Observable<Doctor[]> {
    return this.apiClient.get<DoctorsResponse>("/api/doctors").pipe(map((response: DoctorsResponse) => response.doctors));
  }
}

interface DoctorsResponse {
  readonly doctors: Doctor[];
}
