<div class="row justify-content-center">
  <div class="col-6">
    <div class="row">
      <h5>{{ localizationKey('hospitalNurseSettingsTitle') | localize }}</h5>
    </div>

    <label class="w-100">
      <input
        type="checkbox"
        data-testid="nurse-2fa"
        [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.nurse2Fa"
      />
      {{ localizationKey('hospitalNurseSettings2FaCheckbox') | localize }}
    </label>
    <label class="w-100">
      <input
        type="checkbox"
        data-testid="nurse-combined-report"
        [(ngModel)]="nurseSettingsSectionStateService.nurseSettings!.combinedReportEnabled"
      />
      {{ localizationKey('hospitalNurseSettingsCombinedReportCheckbox') | localize }}
    </label>

    <div>
      <button class="btn btn-sm btn-primary" data-testid="submit-nurse-settings"
              (click)="submitNurseSettings()">
        {{ localizationKey('hospitalNurseSettingsUpdateButton') | localize }}
      </button>
    </div>
  </div>

</div>
