<div class="container-fluid container--available-viewport-height">
  <div class="row justify-content-md-center">
    <loading-indicator *ngIf="measurementLoadingInProgress$ | async" class="col-2"></loading-indicator>
  </div>

  <div *ngIf="bpAnalysis" class="row h-100">
    <!-- LEFT PANEL -->
    <div class="h-100 col-md-6 col-md overflow-auto">
      <!-- PATIENT ID -->
      <div class="w-100 pb-4">
        <div class="w-100">
          <span class="strong pl-4">{{ labelStudyId | localize }}</span>
          <span class="pl-2">{{ bpAnalysis.code }}</span>
        </div>
        <div class="w-100">
          <span class="strong pl-4">{{ localizationKey("labelHospitalName") | localize }}</span>
          <span class="pl-2">{{ bpAnalysis.hospitalName }}</span>
        </div>
        <div class="w-100">
          <span class="strong pl-4">{{ labelUniqueId | localize }}</span>
          <span class="pl-2">{{ bpAnalysis.uniqueId }}</span>
        </div>
      </div>

      <!-- FINDINGS -->
      <div class="w-100 pl-3">
        <label class="col-form-label col-10 pl-3">{{ "analysisLabelFindings" | localize }}</label>
        <!--        <table class="table table-sm table-borderless">-->
        <!--          <thead>-->
        <!--            <tr>-->
        <!--              <th scope="col"></th>-->
        <!--              <th scope="col">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsActiveTime" | localize }}</strong>-->
        <!--                </small>-->
        <!--              </th>-->
        <!--              <th scope="col">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsSleepTime" | localize }}</strong>-->
        <!--                </small>-->
        <!--              </th>-->
        <!--              <th scope="col">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsWholeDay" | localize }}</strong>-->
        <!--                </small>-->
        <!--              </th>-->
        <!--            </tr>-->
        <!--          </thead>-->
        <!--          <tbody>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsAverageBP" | localize }} (mmHg)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>110</small></td>-->
        <!--              <td><small>90</small></td>-->
        <!--              <td><small>100</small></td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsAverageSystolicRange" | localize }} (mmHg)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>100 - 140</small></td>-->
        <!--              <td><small>90 - 125</small></td>-->
        <!--              <td><small>90 - 140</small></td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsAverageDiastolicRange" | localize }} (mmHg)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>60 - 80</small></td>-->
        <!--              <td><small>50 - 65</small></td>-->
        <!--              <td><small>50 - 80</small></td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsSystolicBPload" | localize }} (%)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>20</small></td>-->
        <!--              <td><small>10</small></td>-->
        <!--              <td><small>15</small></td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsDiastolicBPload" | localize }} (%)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>20</small></td>-->
        <!--              <td><small>10</small></td>-->
        <!--              <td><small>15</small></td>-->
        <!--            </tr>-->
        <!--            <tr>-->
        <!--              <td scope="row">-->
        <!--                <small>-->
        <!--                  <strong>{{ "bpFindingsAveragePuls" | localize }} (bpm)</strong>-->
        <!--                </small>-->
        <!--              </td>-->
        <!--              <td><small>80</small></td>-->
        <!--              <td><small>60</small></td>-->
        <!--              <td><small>70</small></td>-->
        <!--            </tr>-->
        <!--          </tbody>-->
        <!--        </table>-->
        <app-blood-pressure-findings [findings]="bpFindingsModel"></app-blood-pressure-findings>
      </div>

      <hr/>

      <loading-indicator *ngIf="!bpChartImageBase64"></loading-indicator>
      <ng-container *ngIf="bpChartImageBase64">
        <!-- CONCLUSION -->
        <div class="w-100">
          <form-field [config]="bpFindingsFormConfig.conclusion"></form-field>
        </div>

        <!-- REPORT ACTIONS -->
        <div class="w-100" *ngIf="!isPreviewMode">
          <div class="pt-2">
            <button type="button" class="btn btn-link mt-2" (click)="previewReport()">
              <i class="fa fa-file mr-1"></i>{{ labelPreviewReport | localize }}
            </button>
          </div>

          <div id="saveAndFinish">
            <div class="pt-4">
              <button *ngIf="!isFinishMode" type="button" class="btn btn-primary mr-4" (click)="switchToFinishMode()">
                {{ labelButtonFinish | localize }}<i class="fa fa-chevron-right mx-1"></i>
              </button>

              <action-button
                *ngIf="!isFinishMode"
                class="mr-4"
                buttonClass="btn btn-outline-primary"
                [buttonLabel]="labelSave"
                [actionInProgressLabel]="labelInProgress"
                (buttonClick)="onSaveButtonClick()"
                [actionInProgress]="saveInProgress$ | async">
              </action-button>

              <action-button
                *ngIf="isFinishMode"
                class="mr-4"
                buttonClass="btn btn-primary"
                [buttonLabel]="labelButtonFinishConfirm"
                [actionInProgressLabel]="labelButtonFinishInProgress"
                (buttonClick)="onFinishAnalysisClick()"
                [actionInProgress]="finishAnalysisInProgress$ | async">
              </action-button>
              <button *ngIf="isFinishMode" type="button" class="btn btn-secondary" (click)="switchToSaveMode()">
                {{ labelButtonFinishCancel | localize }}
              </button>
              <span *ngIf="!!errorMessage" class="alert alert-danger ml-4">{{ errorMessage | localize }}</span>
            </div>
          </div>
        </div>
      </ng-container>

    </div>

    <!-- RIGHT PANEL -->
    <div class="h-100 col-md-6 col-md">

      <div class="h-95 overflow-auto">
        <!-- PATIENT DATA -->
        <div *ngIf="patientDataFields" class="col-12 small form-grid form-grid-striped">
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.reasonForStudy"
              [displayHorizontal]="false"
              class="col-12"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.measurementStartTime"
              [value]="bpAnalysis.startTime | appLocalDateTime"
              class="col-6"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.age"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.height"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.waistline"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.cholesterol"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.diabetes"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.coronaryArteryDisease"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.coronaryInsufficiency"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.cerebrovascularDisorders"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.kidneyDisease"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.parentsHeartAttackUnder60"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.parentsStrokeUnder75"></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.diagnosedHeartDisease"
              [displayHorizontal]="false"></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.currentMedication"
              [displayHorizontal]="false"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.diary"
              [displayHorizontal]="false"
              class="col-12"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.sleepMeasurementNight"
              [displayHorizontal]="false"
              class="col-12"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              class="col-6"
              [formFieldConfig]="patientDataFields.goToBedTime"
              [value]="bpAnalysis.patientData.goToBedTime | appLocalDateTime"></app-form-field-display>
            <app-form-field-display
              class="col-6"
              [formFieldConfig]="patientDataFields.wakeUpTime"
              [value]="bpAnalysis.patientData.wakeUpTime | appLocalDateTime"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.patientFeedback"
              [displayHorizontal]="false"
              class="col-12"></app-form-field-display>
          </div>
        </div>

        <hr/>

        <!-- CHART -->
        <div class="w-100 overflow-hidden">
          <loading-indicator *ngIf="bpDataLoadingInProgress$ | async"></loading-indicator>
          <app-blood-pressure-chart
            *ngIf="bpData"
            [bpData]="bpData"
            (bpChartImageChange)="onBpChartImageChange($event)">
          </app-blood-pressure-chart>
        </div>

        <hr/>

        <!-- TABLE -->
        <div class="w-100">
          <loading-indicator *ngIf="bpDataLoadingInProgress$ | async"></loading-indicator>
          <app-blood-pressure-table *ngIf="bpData" [bpData]="bpData"></app-blood-pressure-table>
        </div>
      </div>
      <div class="text-right mt-5">
        <button
          type="button"
          [ngClass]="'btn btn-sm mr-4 mb-1 ' + getButtonType()"
          data-testid="show-comments-button"
          (click)="onShowCommentsClick()">{{getCommentButtonLabel('measurementsGridButtonLabelShowComments' | localize, bpAnalysis.commentsCount!)}}</button>
      </div>
    </div>

  </div>
</div>

<ng-template #templateComments>
  <div class="modal-body text-center">
    <app-comments
      [measurementId]="bpAnalysis!.id"
      [code]="bpAnalysis!.code"
      [hospitalName]="bpAnalysis!.hospitalName"
      (commentsCountChanged)="onCommentsCountChanged($event)"
    >

    </app-comments>
    <button type="button" class="btn btn-primary" (click)="modalRefComments?.hide()">
      {{ "modalResponseClose" | localize }}
    </button>
  </div>
</ng-template>
