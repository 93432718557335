import { UploadFormModel } from "./upload-form-model";
import {
  isExisting,
  isLimitedPositiveInteger,
  isNonRequiredTextOfMaxLength,
  isRequiredTextOfMaxLength,
  isValidDateRange,
  isValidRange
} from "../validations";
import { MAX_CHARACTERS_IN_TEXT } from "../utils";
import { EcgSymptom, MAX_ECG_SYMPTOMS } from "./upload-model";
import { canParseDateTime } from "../../tools/date-time-utils";

export const uploadFormMutuallyDependentProperties: (keyof UploadFormModel)[] = ["goToBedTime", "wakeUpTime"];

export function createMeasurementFormValidationMap(uploadData: UploadFormModel) {
  return new Map<keyof UploadFormModel, (value: any) => boolean>()
    .set("goToBedTime", () => isNotBloodPressureMeasurement(uploadData) || isValidDateRange(uploadData.goToBedTime, uploadData.wakeUpTime))
    .set("wakeUpTime", () => isNotBloodPressureMeasurement(uploadData) || isValidDateRange(uploadData.goToBedTime, uploadData.wakeUpTime))
    .set("gender", () => isExisting(uploadData.gender))
    .set("age", () => isLimitedPositiveInteger(150, uploadData.age))
    .set("height", () => isLimitedPositiveInteger(300, uploadData.height))
    .set("weight", () => isLimitedPositiveInteger(300, uploadData.weight))
    .set("waistline", () => isNotBloodPressureMeasurement(uploadData) || uploadData.waistline == undefined || isValidRange(30, 300, uploadData.waistline))
    .set("cholesterol", () => isNotBloodPressureMeasurement(uploadData) || uploadData.cholesterol == undefined  || isValidRange(0.1, 20, uploadData.cholesterol))
    .set("profession", () => isNonRequiredTextOfMaxLength(40, uploadData.profession))
    .set("symptoms", () => areSymptomsValid(uploadData))
    .set("reasonForStudy", () => isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.reasonForStudy))
    .set("currentMedication", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.currentMedication))
    .set("sleepDiary", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.sleepDiary))
    .set("patientFeedback", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.patientFeedback))
    .set("measurementNurseComments", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.measurementNurseComments))
    .set("diary", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.diary))
    .set("diagnosedHeartDisease", () => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, uploadData.diagnosedHeartDisease))
    .set("measurementStartTime", () => isMeasurementTimeValid(uploadData));
}

function isNotBloodPressureMeasurement(uploadData: UploadFormModel) {
  return uploadData.measurementType !== "BLOOD_PRESSURE";
}

function areSymptomsValid(uploadData: UploadFormModel): boolean {
  if (uploadData.measurementType !== "SYMPTOM_HOLTER") {
    return true;
  }
  const symptoms = uploadData.symptoms || Array<EcgSymptom>();

  const validSymptomsCount = symptoms.filter((symptom) => !!symptom.time && !!symptom.description).length;
  const partiallyFilledSymptomsCount = symptoms.filter(
    (symptom) => [symptom.time, symptom.description].filter((p) => !!p).length === 1
  ).length;
  const countOfSymptomsWithTooLongDescription = symptoms.filter((symptom) => isSymptomDescriptionTooLong(symptom)).length;

  const allSymptomDatetimesValid = symptoms.every(symptom => symptom.time == undefined || canParseDateTime(symptom.time));

  return (
    allSymptomDatetimesValid &&
    validSymptomsCount >= 1 &&
    validSymptomsCount <= MAX_ECG_SYMPTOMS &&
    partiallyFilledSymptomsCount === 0 &&
    countOfSymptomsWithTooLongDescription === 0
  );
}

function isMeasurementTimeValid(uploadData: UploadFormModel): boolean {
  if (uploadData.measurementType === "SLEEP") {
    return true;
  }

  return isExisting(uploadData.measurementStartTime);
}

export function isSymptomDescriptionTooLong(symptom: EcgSymptom) {
  return symptom.description && symptom.description.length > 80;
}
