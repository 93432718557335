export interface AnchorValues { [key: string]: string | undefined }

export function replaceAnchors(
  anchoredString: string | undefined,
  anchorValues: AnchorValues,
  replaceMissingValuesWith?: string | null
): string | undefined {

  if (!anchoredString) {
    return anchoredString;
  }

  const matches = anchoredString.matchAll(/\${\s*(\w+)\s*}/gm);

  for (const match of matches) {
    const anchorName = match[1];
    const anchorString = match[0];

    const anchorValue = anchorValues[anchorName];
    if (anchorValue != null) {
      anchoredString = anchoredString.replace(anchorString, anchorValue);
    } else if (replaceMissingValuesWith != null) {
      anchoredString = anchoredString.replace(anchorString, replaceMissingValuesWith);
    }
  }

  return anchoredString;
}
