<div
  *ngIf="!deviceOrdered"
  data-testid="eform-mailing-info"
>
  <form-field [config]="formConfig.name"></form-field>
  <form-field [config]="formConfig.mailingAddress"></form-field>
  <form-field [config]="formConfig.phoneNumber"></form-field>
  <form-field [config]="formConfig.emailAddress"></form-field>
  <form-field [config]="formConfig.permission"></form-field>
  <button
    data-testid="order-device-button"
    class="btn btn-primary"
    [disabled]="!orderingEnabled"
    (click)="orderDevice()"
  >{{ "mailingInfoFormOrderButtonLabel" | localize }}
  </button>
</div>

<div
  *ngIf="deviceOrdered"
>
  {{"mailingInfoFormDeviceOrderThankYouMessage"| localize}}
</div>
