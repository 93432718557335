import { Injectable } from "@angular/core";
import {isNonRequiredTextOfMaxLength, isRequiredTextOfMaxLength} from "../../validations";
import { DoctorFindings } from "../analysis";
import { FindingsValidator, simpleValidationResult, ValidatedFindings, ValidationResult } from "../findings-validator";
import { BpAnalysisComponent } from "./bp-analysis.component";
import {MAX_CHARACTERS_IN_TEXT} from "../../utils";

@Injectable()
export class BpFindingsValidator extends FindingsValidator<BpAnalysisComponent> {
  validateFinishAnalysis(validatedFindings: BpValidatedFindings): BpFindingsValidationResult {
    return {
      conclusion: simpleValidationResult(isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.conclusion)),
    };
  }

  validateSaveAnalysis(validatedFindings: BpValidatedFindings): BpFindingsValidationResult {
    return {
      conclusion: simpleValidationResult(isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.conclusion)),
    };
  }
}

export interface BpValidatedFindings extends ValidatedFindings {
  ecgFindings: DoctorFindings;
}

export interface BpFindingsValidationResult {
  conclusion: ValidationResult;
}

export function initialBpFindingsValidationResult(): BpFindingsValidationResult {
  return {
    ...emptyBpFindingsValidationResult(),
  };
}

export function emptyBpFindingsValidationResult(): BpFindingsValidationResult {
  return {
    conclusion: simpleValidationResult(undefined),
  };
}
