<div data-testid="form-HOLTER_ECG">

  <form-field [config]="formConfig.measurementStartTime"></form-field>

  <hr *ngIf="!responsiveLayout"/>

  <form-field [config]="formConfig.reasonForStudy"></form-field>
  <form-field [config]="formConfig.gender"></form-field>
  <form-field [config]="formConfig.age"></form-field>
  <form-field [config]="formConfig.height"></form-field>
  <form-field [config]="formConfig.weight"></form-field>

  <hr/>

  <form-field [config]="formConfig.smoking"></form-field>

  <hr/>

  <form-header [label]="formConfig.frequencyHeaderLabel"
               [columnLabels]="formConfig.frequencyHeaderColumnLabels"></form-header>

  <form-field [config]="formConfig.abnormalHeartbeats"></form-field>
  <form-field [config]="formConfig.slowPulse"></form-field>
  <form-field [config]="formConfig.fastPulse"></form-field>
  <form-field [config]="formConfig.palpitation"></form-field>
  <form-field [config]="formConfig.irregularPulse"></form-field>
  <form-field [config]="formConfig.dizziness"></form-field>
  <form-field [config]="formConfig.lossOfConsciousness"></form-field>
  <form-field [config]="formConfig.blackouts"></form-field>
  <form-field [config]="formConfig.chestPain"></form-field>
  <form-field [config]="formConfig.shortnessOfBreath"></form-field>

  <hr/>

  <form-field [config]="formConfig.diagnosedHeartDisease"></form-field>

  <hr/>

  <form-field [config]="formConfig.currentMedication"></form-field>

  <div
    data-testid="post-measurement-form-HOLTER_ECG"
    *ngIf="!hidePostMeasurementFields"
  >

    <hr/>

    <form-field [config]="formConfig.diary"></form-field>
    <form-field [config]="formConfig.patientFeedback"></form-field>

    <hr/>

    <form-field [config]="formConfig.nurseComments"></form-field>

  </div>
</div>
