<div class="container" data-testId="new-change-password-form">
  <div class="form-striped">
    <form-field [config]="formConfig.oldPassword" data-testId="oldPassword-form-field"></form-field>
    <form-field [config]="formConfig.newPassword" data-testId="newPassword-form-field"></form-field>
    <form-field [config]="formConfig.retypedPassword" data-testId="retypedPassword-form-field"></form-field>
  </div>
  <action-button
    (buttonClick)="submit()"
    buttonLabel="changePasswordLabel"
    [actionInProgress]="changeInProgress$ | async"
    [actionInProgressLabel]="'changePasswordLabel' | localize"
    [buttonClass]="'btn btn-primary'"
    [testId]="'change-password-button'"
  >
  </action-button>
</div>



