import {UserCredentialsFormModel} from "../login-form-model";
import {FormFieldConfig, PropertyChangeListener} from "../../../tools/form/form-field-config";
import {FormConfigBase} from "../../../tools/form/form-config-base";


export class UserCredentialsConfig extends FormConfigBase<UserCredentialsFormModel> {
  constructor(
    model: UserCredentialsFormModel,
    propertyChangeListener: PropertyChangeListener<UserCredentialsFormModel>,
    validationProvider: (property: keyof UserCredentialsFormModel) => boolean | undefined
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly username: FormFieldConfig<UserCredentialsFormModel> = this.createField({
    property: "username",
    type: "text",
    label: "loginFormLabelUsername",
    invalidLabel: "loginFormUsernameInvalidLabel",
    alignLabelRight: true
  });

  readonly password: FormFieldConfig<UserCredentialsFormModel> = this.createField({
    property: "password",
    type: "password",
    label: "loginFormLabelPassword",
    invalidLabel: "loginFormPasswordInvalidLabel",
    alignLabelRight: true
  });
}
