import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TODO} from "./tools/test/todo";
import {NavigationEnd, Router} from "@angular/router";
import {filter, map, skip} from "rxjs/operators";

@Injectable()
export class RouteListener {

  constructor(private router: Router) {
  }

  onRoute(): Observable<void> {
    return this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .pipe(skip(1))
      .pipe(map((event) => void 0));
  }

}
