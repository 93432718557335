import {Injectable} from "@angular/core";

@Injectable()
export class ReloadApp {

  reload() {
    location.reload();
  }

}
