import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {Hospital} from "../new-users/new-users";

@Injectable()
export class OperatorHospitalsService {

  constructor(private apiClient: ApiClient) {
  }

  getOperatorHospitals(): Observable<Hospital[]> {
    return this.apiClient.get<Hospital[]>("/api/hospitals/operator");
  }

}
