import {Component, OnInit} from "@angular/core";
import {FormConfigBase} from "../../tools/form/form-config-base";
import {FormFieldConfig, PropertyChangeListener} from "../../tools/form/form-field-config";
import {InitiateMeasurementService} from "./initiate-measurement.service";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {InitiateMeasurementValidator} from "./initiate-measurement-validator.service";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {Observable, Subject} from "rxjs";
import {UploadService} from "../upload/upload.service";
import {debounceTime, filter, finalize, switchMap} from "rxjs/operators";
import {getTypeSelectionOptions, measurementTypeOptions} from "../measurement-type-select-options";
import {AuthenticationService} from "../authentication/authentication.service";
import {SelectionOption, SelectionOptions} from "../../tools/form/form-field.component";
import {OperatorHospitalsService} from "./operator-hospitals.service";
import {OrderMeasurementService} from "./order-measurement.service";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {localizationKey} from "../../i18n/i18n-model";
import {
  InitiatedMeasurementResponse,
  InitiateMeasurementFormModel,
  InitiateMeasurementRequest,
  NurseMeasurementType
} from "./initiate-measurement-data";
import {HospitalMeasurementTypesService} from "../hospitals/edit-hospital/hospital-measurement-types.service";
import {OrderMeasurementValidator} from "./order-measurement.validator";
import {FormValidator} from "../../tools/form/form-validator";
import {HomeType} from "./home-type";


@Component({
  selector: "initiate-measurement",
  templateUrl: "./initiate-measurement.component.html",
})
export class InitiateMeasurement implements PropertyChangeListener<InitiateMeasurementFormModel>, OnInit {

  public static readonly DEBOUNCE_MILLIS = 500;

  private initiateMeasurementFormModel: InitiateMeasurementFormModel = emptyInitializeAnalysisFormModel();
  private initiateMeasurementValidator: FormValidator<InitiateMeasurementFormModel>;

  private readonly checkCodeUniqueSubject = new Subject<string>();

  private readonly displayCodeExistsError = new Subject<boolean>();
  readonly displayCodeExists$: Observable<boolean> = this.displayCodeExistsError.asObservable();

  private readonly initiateInProgress = new Subject<boolean>();
  readonly initiateInProgress$: Observable<boolean> = this.initiateInProgress.asObservable();

  formConfig?: InitiateMeasurementFormConfig;
  errorMessage?: string;
  isMeasurementOrdered?: boolean;

  constructor(private readonly initiateMeasurementService: InitiateMeasurementService,
    private readonly navigator: RouteNavigator,
    private readonly uploadService: UploadService,
    private readonly authenticationService: AuthenticationService,
    private readonly operatorHospitalsService: OperatorHospitalsService,
    private readonly orderMeasurementService: OrderMeasurementService,
    private readonly notifications: Notifications,
    private readonly hospitalMeasurementTypesService: HospitalMeasurementTypesService
  ) {
    this.initiateMeasurementValidator = new InitiateMeasurementValidator(this.initiateMeasurementFormModel);
    this.checkCodeUniqueSubject.pipe(
      debounceTime(InitiateMeasurement.DEBOUNCE_MILLIS),
      filter(code => !!code),
      switchMap(code => this.uploadService.doesMeasurementExist(code))
    ).subscribe((measurementExistsResponse) => this.displayCodeExistsError.next(measurementExistsResponse.exists));
  }

  ngOnInit(): void {
    this.isMeasurementOrdered = this.authenticationService.getCurrentAuthenticatedUser()!.role === "ORDERING_NURSE";
    if (this.isMeasurementOrdered) {
      this.initiateMeasurementValidator = new OrderMeasurementValidator(this.initiateMeasurementFormModel);
      this.operatorHospitalsService.getOperatorHospitals().subscribe((hospitals) => {
        const options: SelectionOption<string>[] = hospitals.map((hospital) => ({
          value: hospital.id.toString(),
          text: hospital.name
        } as SelectionOption<string>));
        this.formConfig = new InitiateMeasurementFormConfig(
          this.initiateMeasurementFormModel,
          this,
          (property) => this.initiateMeasurementValidator.isValid(property)
        );
        this.formConfig.operatorHospital.selectionOptions = SelectionOptions.with<string>(...options);
      },
      () => {
        this.notifications.addNotification(NotificationType.ERROR, localizationKey("operatorHospitalsFailedGet"));
      });
    } else {
      this.hospitalMeasurementTypesService.getOwnHospitalMeasurementTypes()
        .subscribe((hospitalMeasurementTypes) => {
          this.formConfig = new InitiateMeasurementFormConfig(
            this.initiateMeasurementFormModel,
            this,
            (property) => this.initiateMeasurementValidator.isValid(property),
            getTypeSelectionOptions(hospitalMeasurementTypes)
          );
        },
        () => {
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("errorCannotGetHospitalTypes"));
        }
        );
    }
  }

  onPropertyChange(property: keyof InitiateMeasurementFormModel, newValue: any): void {
    this.initiateMeasurementFormModel[property] = newValue;
    this.initiateMeasurementValidator.updateValidation(property);

    this.errorMessage = this.initiateMeasurementValidator.isFormValid() ? "" : "uploadFormValidationErrorDefault";

    if (property === "code") {
      this.checkCodeUniqueSubject.next(newValue);
    }
  }

  onInitiateClick() {
    this.initiateMeasurementValidator.updateAllValidations();

    if (!this.initiateMeasurementValidator.isFormValid()) {
      this.errorMessage = "uploadFormValidationErrorDefault";
      return;
    }

    this.initiateInProgress.next(true);

    const measurementServiceObservable = this.isMeasurementOrdered
      ? this.orderMeasurementService.orderMeasurement(this.initiateMeasurementFormModel)
      : this.initiateMeasurementService.initiateMeasurement(toInitiateRequest(this.initiateMeasurementFormModel));

    measurementServiceObservable.pipe(finalize(() => this.initiateInProgress.next(false)))
      .subscribe(
        (measurement: InitiatedMeasurementResponse) => {
          if (this.isMeasurementOrdered) {
            return this.navigator.navigateToPath([NavigationRoute.PRINT_ORDER_FORM, measurement.measurementId.toString()]);
          } else {
            return this.navigator.navigateToPath([NavigationRoute.PRINT_FORM, measurement.measurementId.toString()]);
          }
        },
        (errorResponse: ErrorResponse) =>
          (this.errorMessage = singleCodeFromResponse(errorResponse) || ErrorCode.INITIATE_FAILED)
      );
  }
}


function toInitiateRequest(initiateMeasurementFormModel: InitiateMeasurementFormModel): InitiateMeasurementRequest {

  const homeType = new HomeType(initiateMeasurementFormModel.type!);

  return {
    code: initiateMeasurementFormModel.code,
    type: homeType.getMeasurementType(),
    isHome: homeType.isHomeType(),
    specifiedHomeHolterDuration: homeType.getSpecifiedHomeDuration(),
    reasonForStudy: initiateMeasurementFormModel.reasonForStudy!
  };
}

function emptyInitializeAnalysisFormModel(): InitiateMeasurementFormModel {
  return {
    code: "",
    type: undefined,
    reasonForStudy: undefined,
    operatorHospitalId: undefined,
  };
}

class InitiateMeasurementFormConfig extends FormConfigBase<InitiateMeasurementFormModel> {
  readonly code = this.createField({
    property: "code",
    label: "uploadFormLabelCode",
    type: "text",
    required: true,
    invalidLabel: "uploadFormInvalidLabelCode"
  });

  readonly type: FormFieldConfig<InitiateMeasurementFormModel>;

  readonly reasonForStudy = this.createField({
    property: "reasonForStudy",
    label: "uploadFormLabelReasonForStudy",
    type: "textarea",
    required: true,
    invalidLabel: "initiateMeasurementReasonForStudyInvalid"
  });

  readonly operatorHospital = this.createField({
    property: "operatorHospitalId",
    label: "orderFormLabelOperatorHospital",
    type: "dropdown",
    required: true,
    invalidLabel: "orderFormLabelOperatorHospitalInvalid",
  });

  constructor(
    model: InitiateMeasurementFormModel,
    propertyChangeListener?: PropertyChangeListener<InitiateMeasurementFormModel>,
    validationProvider?: (property: keyof InitiateMeasurementFormModel) => boolean | undefined,
    selectionOptions: SelectionOptions<NurseMeasurementType> = measurementTypeOptions,
  ) {
    super(model, propertyChangeListener, validationProvider);
    this.type = this.createField({
      property: "type",
      label: "uploadFormTypeFieldLabel",
      type: "dropdown",
      selectionOptions: selectionOptions,
      required: true,
      invalidLabel: "analysisFormInvalidLabelRequired"
    });
  }
}
