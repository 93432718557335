import { Injectable } from "@angular/core";

declare const grecaptcha: any;

@Injectable()
export class RecaptchaService {

  reset() {
    grecaptcha.reset();
  }

}
