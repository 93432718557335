import {Injectable} from "@angular/core";
import {environment} from "../environments/environment";

@Injectable()
export class VersionMismatch {

  private backendVersion: string | null = null;

  isMismatch() {
    return this.backendVersion !== null && this.backendVersion !== `${environment.version}`;
  }

  setBackendVersion(backendVersion: string[] | null) {
    if (backendVersion !== null && backendVersion.length > 0) {
      this.backendVersion = backendVersion[0];
    }
  }

}
