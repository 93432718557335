import { Content, TFontDictionary } from "pdfmake/interfaces";

export interface ReportValue {
  label: string;
  value: string;
  secondaryLabel?: string;
}

export interface PrintedReportValue {
  reportValue: ReportValue;
  labelPosition: "LEFT" | "TOP";
}

export interface ReportingDoctor {
  fullName: string;
  city: string;
  title: string;
}

export function getAssetUrl(path: string): string {
  return convertToFullUrl(`assets/${path}`);
}

export function convertToFullUrl(path: string): string {
  return `${location.protocol}//${location.host}/${path}`;
}

export function formatLabel(labelText: string): string {
  return `${labelText}: `;
}

export function createFonts(): TFontDictionary {
  const baseFontUrl = getAssetUrl("fonts");
  return {
    Roboto: {
      normal: `${baseFontUrl}/Roboto/Roboto-Regular.ttf`,
      bold: `${baseFontUrl}/Roboto/Roboto-Medium.ttf`,
      italics: `${baseFontUrl}/Roboto/Roboto-Italic.ttf`,
      bolditalics: `${baseFontUrl}/Roboto/Roboto-MediumItalic.ttf`,
    },
  };
}

export function createPatientValueGroupContent(reportValues: PrintedReportValue[]): Content {
  return reportValues.map((reportValue, i) => createPatientValueContent(reportValue, i === reportValues.length - 1));
}

export function createPatientValueContent(printedValue: PrintedReportValue, withMarginBottom: boolean): Content {
  const labelWidth = 180;
  const fontSize = 8;
  const margin: number | [number, number, number, number] = withMarginBottom ? [0, 0, 0, 10] : 0;

  if (printedValue.labelPosition === "LEFT") {
    return {
      columns: [
        { text: formatLabel(printedValue.reportValue.label), bold: true, width: labelWidth, fontSize },
        { text: printedValue.reportValue.value, fontSize },
      ],
      margin,
    };
  } else {
    return {
      text: [
        { text: `${formatLabel(printedValue.reportValue.label)}\n`, bold: true, fontSize },
        { text: printedValue.reportValue.value, fontSize },
      ],
      margin,
    };
  }
}
