import { Component, Input, OnInit } from "@angular/core";
import { BloodPressureData } from "../bp-analysis";

@Component({
  selector: "app-blood-pressure-table",
  templateUrl: "./blood-pressure-table.component.html",
})
export class BloodPressureTableComponent implements OnInit {
  @Input() bpData!: BloodPressureData;

  constructor() {}

  ngOnInit(): void {
    if (!this.bpData) {
      throw new Error("Missing BP data!");
    }
  }
}
