import {InitiateMeasurementFormModel} from "./initiate-measurement-data";
import {createInitiateMeasurementValidationFunctionMap} from "./initiate-measurement-validator.service";
import {isRequiredDigitsString} from "../validations";
import {FormValidator} from "../../tools/form/form-validator";

export class OrderMeasurementValidator extends FormValidator<InitiateMeasurementFormModel> {

  constructor(initiateMeasurementModel: InitiateMeasurementFormModel) {
    super(initiateMeasurementModel, createInitiateMeasurementValidationFunctionMap()
      .set("operatorHospitalId", (value: string) => isRequiredDigitsString(value)));
  }
}
