import {Component, EventEmitter, Input, Output} from "@angular/core";
import {DateTime} from "luxon";

@Component({
  selector: "app-time-filter",
  templateUrl: "./time-filter.component.html",
})
export class TimeFilterComponent {

  @Input()
    from?: DateTime;

  @Input()
    to?: DateTime;

  @Input()
    loadingInProgress: boolean | null = false;

  @Output()
    searchClick: EventEmitter<TimeFilterSearchClick> = new EventEmitter<TimeFilterSearchClick>();

  errorMessage?: string;

  constructor() {
  }

  onSearchClick() {
    this.searchClick.emit({from: this.from, to: this.to});
  }

  validate(): void {
    if (this.from == null || this.to == null || this.from.startOf("day") < this.to.endOf("day")) {
      this.errorMessage = undefined;
    } else {
      this.errorMessage = "timeFilterErrorMessage";
    }
  }

  OnFromDateTimeChange(from: DateTime) {
    this.from = from;
    this.validate();
  }

  OnToDateTimeChange(to: DateTime) {
    this.to = to;
    this.validate();
  }

}

export interface TimeFilterSearchClick {
  from?: DateTime;
  to?: DateTime;
}
