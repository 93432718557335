import { Injectable } from "@angular/core";
import {
  BYTES_IN_KB,
  BYTES_IN_MB,
  isValidFile,
  isRequiredTextOfMaxLength,
  isNonRequiredTextOfMaxLength
} from "../../validations";
import { DoctorFindings, EcgSamplesDocument } from "../analysis";
import {
  arrayValidationResult,
  FindingsValidator,
  simpleValidationResult,
  ValidatedFindings,
  ValidationResult,
} from "../findings-validator";
import { EcgAnalysisComponent } from "./ecg-analysis.component";
import {MAX_CHARACTERS_IN_TEXT} from "../../utils";

@Injectable()
export class EcgFindingsValidator extends FindingsValidator<EcgAnalysisComponent> {
  validateFinishAnalysis(validatedFindings: EcgValidatedFindings): EcgFindingsValidationResult {
    return {
      patientStatusComments: simpleValidationResult(isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.patientStatusComments)),
      findings: simpleValidationResult(isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.findings)),
      conclusion: simpleValidationResult(isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.conclusion)),
      symptomFindings: arrayValidationResult(validateSymptoms(validatedFindings)),
      ecgSampleFile: simpleValidationResult(isEcgSampleFileValid(validatedFindings, validatedFindings.ecgSamplesRequired)),
      diagnosis: simpleValidationResult(isDiagnosisValid(validatedFindings)),
    };
  }

  validateSaveAnalysis(validatedFindings: EcgValidatedFindings): EcgFindingsValidationResult {
    return {
      findings: simpleValidationResult(isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.findings)),
      conclusion: simpleValidationResult(isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.conclusion)),
      patientStatusComments: simpleValidationResult(isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedFindings.ecgFindings.patientStatusComments)),
      symptomFindings: arrayValidationResult(validatedFindings.ecgFindings.symptomFindings.map((symptomFinding) => isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, symptomFinding))),
      ecgSampleFile: simpleValidationResult(isEcgSampleFileValid(validatedFindings, false)),
      diagnosis: simpleValidationResult(isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT,validatedFindings.ecgFindings.diagnosis))
    };
  }
}

export interface EcgValidatedFindings extends ValidatedFindings {
  newEcgSamplesFile?: File;
  foundEcgSamplesDocument?: EcgSamplesDocument;
  ecgFindings: DoctorFindings;
  ecgSamplesRequired: boolean;
}

function isEcgSampleFileValid(validatedEcgFindings: EcgValidatedFindings, isRequired: boolean): boolean {
  return (!isRequired || isEcgSamplesFileSet(validatedEcgFindings)) && isNewEcgSamplesFileValid(validatedEcgFindings);
}

function isEcgSamplesFileSet(validatedEcgFindings: EcgValidatedFindings): boolean {
  return !!validatedEcgFindings.foundEcgSamplesDocument || !!validatedEcgFindings.newEcgSamplesFile;
}

function isNewEcgSamplesFileValid(validatedEcgFindings: EcgValidatedFindings): boolean {
  if (!validatedEcgFindings.newEcgSamplesFile) {
    return true;
  }

  return isValidFile(validatedEcgFindings.newEcgSamplesFile, {
    minFileSize: BYTES_IN_KB,
    maxFileSize: 10 * BYTES_IN_MB,
    requiredExtensions: ["pdf"],
    fileNameValidator: (name: string) => name.includes(validatedEcgFindings.measurementCode),
  });
}

function validateSymptoms(validatedEcgFindings: EcgValidatedFindings): boolean[] {
  if (validatedEcgFindings.measurementType !== "SYMPTOM_HOLTER") {
    return [];
  }

  return validatedEcgFindings.ecgFindings.symptomFindings.map((symptomFinding) => validateSymptom(symptomFinding));
}

function validateSymptom(symptomFinding?: string): boolean {
  return isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, symptomFinding);
}

function isDiagnosisValid(validatedEcgFindings: EcgValidatedFindings): boolean {
  if (validatedEcgFindings.measurementType !== "HOLTER_ECG") {
    return isNonRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedEcgFindings.ecgFindings.diagnosis);
  }

  return isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, validatedEcgFindings.ecgFindings.diagnosis);
}

export interface EcgFindingsValidationResult {
  conclusion: ValidationResult;
  findings: ValidationResult;
  patientStatusComments: ValidationResult;
  symptomFindings: ValidationResult;
  ecgSampleFile: ValidationResult;
  diagnosis: ValidationResult;
}

export function initialEcgFindingsValidationResult(ecgFindings: DoctorFindings): EcgFindingsValidationResult {
  return {
    ...emptyEcgFindingsValidationResult(),
    symptomFindings: arrayValidationResult(ecgFindings.symptomFindings.map((_) => undefined)),
  };
}

export function emptyEcgFindingsValidationResult(): EcgFindingsValidationResult {
  return {
    patientStatusComments: simpleValidationResult(undefined),
    findings: simpleValidationResult(undefined),
    conclusion: simpleValidationResult(undefined),
    symptomFindings: arrayValidationResult([]),
    ecgSampleFile: simpleValidationResult(undefined),
    diagnosis: simpleValidationResult(undefined),
  };
}
