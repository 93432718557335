import { Component, Input, OnInit } from "@angular/core";
import { LocalizationStrings } from "../../i18n/i18n-model";

@Component({
  selector: "loading-indicator",
  templateUrl: "./loading-indicator.component.html",
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() label: keyof LocalizationStrings = "loadingLabel";

  @Input() showAfterMillis = 200;

  show = false;

  ngOnInit(): void {
    window.setTimeout(() => {
      this.show = true;
    }, this.showAfterMillis);
  }
}
