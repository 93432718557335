import { Component } from "@angular/core";
import { NavigationRoute } from "../../tools/navigation/navigation-route";
import { RouteNavigator } from "../../tools/navigation/route-navigator.service";
import { AuthenticatedUser } from "../authentication/authenticated-user";
import { AuthenticationService } from "../authentication/authentication.service";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
})
export class UserMenuComponent {
  navigationRoute = NavigationRoute;

  currentUser: AuthenticatedUser | undefined;

  constructor(private routeNavigator: RouteNavigator, private authenticationService: AuthenticationService) {
    this.authenticationService.authenticatedUser$.subscribe((user) => (this.currentUser = user));
  }

  onLogoutClick(e: MouseEvent) {
    e.preventDefault();
    this.authenticationService.logout();
    this.routeNavigator.navigateTo(NavigationRoute.LOGIN);
  }
}
