import { Injectable } from "@angular/core";
import { ApiClient } from "../../tools/api-client";
import { Observable } from "rxjs";
import { EForm, EFormData } from "./e-form-model";
import { map } from "rxjs/operators";

import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";


@Injectable()
export class EFormService {
  constructor(private readonly apiClient: ApiClient) {
  }

  getEFormId(request: EFormIdRequest): Observable<EFormIdResponse> {
    return this.apiClient.get("/api/eform/check", {
      eFormCode: request.eFormCode,
      captchaToken: request.captchaToken
    });
  }

  getEForm(eFormId: String): Observable<EForm> {
    return this.apiClient
      .get<EForm>(`/api/eform/${eFormId}`)
      .pipe(map((eform) => recursiveNullToUndefined(eform)));
  }

  updateEForm(eFormId: String, eFormData: EFormData): Observable<void> {
    return this.apiClient.put(`/api/eform/${eFormId}`, eFormData);
  }
}

export interface EFormIdRequest {
  eFormCode: string;
  captchaToken: string;
}

export interface EFormIdResponse {
  id: string;
}
