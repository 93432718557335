import {Injectable} from "@angular/core";
import {Chart, ChartConfiguration, registerables} from "chart.js";

@Injectable()
export class ChartService {

  init() {
    Chart.register(...registerables);
  }

  createChart(nativeElement: any, chartConfig: ChartConfiguration) {
    new Chart(nativeElement, chartConfig);
  }

  takeChartSnapshot(chart: Chart) {
    return chart.toBase64Image();
  }
}
