export interface ExternalFindings extends Record<string, number | undefined> {
  readonly afibBurden: number | undefined;
  readonly afibTotalDuration: number | undefined;
  readonly averageHeartRate: number | undefined;
  readonly minimumHeartRate: number | undefined;
  readonly maximumHeartRate: number | undefined;
  readonly pausesOver2_5s: number | undefined;
  readonly pausesOver2_5sLongestDuration: number | undefined;
  readonly ventricularBeats: number | undefined;
  readonly ventricularBeatsBurden: number | undefined;
  readonly ventricularTachycardias: number | undefined;
  readonly ventricularTachycardiasLongestDuration: number | undefined;
  readonly ventricularTachycardiasLongestBeats: number | undefined;
  readonly ventricularTachycardiasLongestBeatsMaxHeartRate: number | undefined;
  readonly ventricularTachycardiasMaxHeartRate: number | undefined;
  readonly ventricularTachycardiasMaxHeartRateBeats: number | undefined;
  readonly ventricularTachycardiasMaxHeartRateDuration: number | undefined;
  readonly ventricularTachycardiasLongestDurationMaxHeartRate: number | undefined;
  readonly atrialBeats: number | undefined;
  readonly atrialBeatsBurden: number | undefined;
  readonly atrialTachycardias: number | undefined;
  readonly atrialTachycardiasLongestBeats: number | undefined;
  readonly atrialTachycardiasLongestBeatsMaximumHeartRate: number | undefined;
  readonly atrialTachycardiasLongestDuration: number | undefined;
  readonly atrialTachycardiasMaximumHeartRate: number | undefined;
  readonly atrialTachycardiasMaximumHeartRateDuration: number | undefined;
  readonly atrialTachycardiasMaximumHeartRateBeats: number | undefined;
  readonly atrialTachycardiasLongestDurationMaxHeartRate: number | undefined;
}

export const emptyExternalFindings: ExternalFindings = {
  afibBurden: undefined,
  afibTotalDuration: undefined,
  averageHeartRate: undefined,
  minimumHeartRate: undefined,
  maximumHeartRate: undefined,
  pausesOver2_5s: undefined,
  pausesOver2_5sLongestDuration: undefined,
  ventricularBeats: undefined,
  ventricularBeatsBurden: undefined,
  ventricularTachycardias: undefined,
  ventricularTachycardiasLongestDuration: undefined,
  ventricularTachycardiasLongestBeats: undefined,
  ventricularTachycardiasLongestBeatsMaxHeartRate: undefined,
  ventricularTachycardiasMaxHeartRate: undefined,
  ventricularTachycardiasMaxHeartRateBeats: undefined,
  ventricularTachycardiasMaxHeartRateDuration: undefined,
  ventricularTachycardiasLongestDurationMaxHeartRate: undefined,
  atrialBeats: undefined,
  atrialBeatsBurden: undefined,
  atrialTachycardias: undefined,
  atrialTachycardiasLongestBeats: undefined,
  atrialTachycardiasLongestBeatsMaximumHeartRate: undefined,
  atrialTachycardiasLongestDuration: undefined,
  atrialTachycardiasMaximumHeartRate: undefined,
  atrialTachycardiasMaximumHeartRateDuration: undefined,
  atrialTachycardiasMaximumHeartRateBeats: undefined,
  atrialTachycardiasLongestDurationMaxHeartRate: undefined,
};
