import { Component, Input } from "@angular/core";
import { UploadFormConfig } from "../upload-form-config";
import {environment} from "../../../../environments/environment";

@Component({
  selector: "app-blood-pressure-upload-form",
  templateUrl: "./blood-pressure-upload-form.component.html",
})
export class BloodPressureUploadFormComponent {
  private _formConfig?: UploadFormConfig;
  responsiveLayout = environment.eforms;

  @Input() hidePostMeasurementFields!: boolean;

  @Input()
  set formConfig(formConfig: UploadFormConfig) {
    this._formConfig = formConfig;
  }

  get formConfig(): UploadFormConfig {
    return this._formConfig!;
  }
}
