import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LocalizationStrings } from "../../i18n/i18n-model";
import { ErrorIdProvider } from "./error-id-provider.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
})
export class ErrorComponent implements OnInit {
  constructor(private readonly errorIdProvider: ErrorIdProvider, private readonly route: ActivatedRoute) {}

  private errorMap = new Map<string, keyof LocalizationStrings>().set("report-download-failed", "errors_downloadReportFailed");

  errorKey: keyof LocalizationStrings = "errors_default";

  ngOnInit() {
    const errorId = this.errorIdProvider.getId(this.route) || "";

    this.errorKey = this.errorMap.get(errorId) || "errors_default";
  }
}
