import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ChartConfiguration, ChartData, ChartDataset} from "chart.js";
import "chartjs-adapter-luxon"; // needed for time series charts
import {I18nService} from "../../../../i18n/i18n.service";
import {BloodPressureData} from "../bp-analysis";
import {createBpChartOptions} from "./blood-pressure-chart-config";
import {ChartService} from "./chart.service";
import {DateTime} from "luxon";

interface DataPoint {
  x: string;
  y: number;
}

@Component({
  selector: "app-blood-pressure-chart",
  templateUrl: "./blood-pressure-chart.component.html",
})
export class BloodPressureChartComponent implements OnInit, AfterViewInit {
  @ViewChild("chartCanvas") chartCanvas!: ElementRef;
  @ViewChild("chartSnapshotCanvas") chartSnapshotCanvas!: ElementRef;
  @Input() bpData!: BloodPressureData;
  @Output() bpChartImageChange = new EventEmitter<string>();

  bpChartImageChangeEmitted = false;
  readonly chartStyleDefault = {
    position: "relative",
    width: "100%",
    height: "300px",
    visibility: "visible",
  };
  // This style ensures consistent chart snapshot images, that don't rely on user's browser dimensions.
  readonly chartStyleSnapshot = {
    position: "relative",
    width: "1024px",
    height: "512px",
    visibility: "hidden"
  };

  private readonly bpChartOptions = createBpChartOptions(false);
  private readonly snapshotBpChartOptions = createBpChartOptions(true);

  private readonly colorDiastolic = "#007bff";
  private readonly colorSystolic = "#28a745";
  private readonly colorHeartRate = "red";
  private readonly colorReferentLine = "black";

  private readonly referentDiastolicBpDay = 85;
  private readonly referentDiastolicBpNight = 70;
  private readonly referentSystolicBpDay = 135;
  private readonly referentSystolicBpNight = 120;

  constructor(private readonly i18nService: I18nService,
    private readonly chartService: ChartService) {
    chartService.init();
  }

  ngOnInit(): void {
    if (!this.bpData) {
      throw new Error("Missing BP data!");
    }
  }

  ngAfterViewInit(): void {
    const chartData = this.createChartData();
    const config: ChartConfiguration = {
      type: "line",
      data: chartData,
      options: this.bpChartOptions,
    };
    const snapshotConfig: ChartConfiguration = {
      type: "line",
      data: chartData,
      options: this.snapshotBpChartOptions,
      plugins: [{
        id: "idBpChartAfterRender",
        afterRender: (chart, _, _1) => {
          if (!this.bpChartImageChangeEmitted) {
            const chartSnapshot = this.chartService.takeChartSnapshot(chart);
            this.bpChartImageChange.emit(chartSnapshot);
            this.bpChartImageChangeEmitted = true;
          }
        },
      }],
    };
    this.loadChart(config, snapshotConfig);
  }

  private loadChart(chartConfig: ChartConfiguration, chartSnapshotConfig: ChartConfiguration): void {
    this.chartService.createChart(this.chartCanvas.nativeElement, chartConfig);
    this.chartService.createChart(this.chartSnapshotCanvas.nativeElement, chartSnapshotConfig);
  }

  private createChartData(): ChartData {
    const data = this.bpData.data;
    const goToBedTime = this.bpData.goToBedTime;
    const wakeUpTime = this.bpData.wakeUpTime;
    const startTime = data[0].timestamp;
    const endTime = data[data.length - 1].timestamp;
    const diastolicData = data.map((d) => ({x: d.timestamp, y: d.diastolic}));
    const systolicData = data.map((d) => ({x: d.timestamp, y: d.systolic}));
    const heartRateData = data.map((d) => ({x: d.timestamp, y: d.heartRate}));
    const referenceLineDiastolicData = this.createReferenceLineData(
      startTime,
      endTime,
      goToBedTime,
      wakeUpTime,
      this.referentDiastolicBpDay,
      this.referentDiastolicBpNight
    );
    const referenceLineSystolicData = this.createReferenceLineData(
      startTime,
      endTime,
      goToBedTime,
      wakeUpTime,
      this.referentSystolicBpDay,
      this.referentSystolicBpNight
    );

    return {
      datasets: [
        this.createDataset(systolicData, this.colorSystolic, this.i18nService.getLocalizedString("analysisBpChartLegendSystolic")),
        this.createDataset(diastolicData, this.colorDiastolic, this.i18nService.getLocalizedString("analysisBpChartLegendDiastolic")),
        this.createDataset(
          heartRateData,
          this.colorHeartRate,
          this.i18nService.getLocalizedString("analysisBpChartLegendHeartRate"),
          undefined,
          true
        ),
        this.createDataset(referenceLineDiastolicData, this.colorReferentLine, undefined, 0),
        this.createDataset(referenceLineSystolicData, this.colorReferentLine, undefined, 0),
      ],
    };
  }

  private createReferenceLineData(
    startTime: string,
    endTime: string,
    goToBedTime: string,
    wakeUpTime: string,
    referentValueDay: number,
    referentValueNight: number
  ): DataPoint[] {
    return [
      {x: DateTime.fromISO(startTime) < DateTime.fromISO(goToBedTime) ? startTime : goToBedTime, y: referentValueDay},
      {x: goToBedTime, y: referentValueDay},
      {x: goToBedTime, y: referentValueNight},
      {x: wakeUpTime, y: referentValueNight},
      {x: wakeUpTime, y: referentValueDay},
      {x: endTime, y: referentValueDay}
    ];
  }

  private createDataset(data: any[], color: string, label?: string, radius?: number, dashed?: boolean): ChartDataset {

    return {
      data,
      label,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 2,
      radius: radius !== undefined ? radius : 0,
      borderDash: dashed ? [3, 3] : undefined,
    };
  }
}
