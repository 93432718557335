import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiClient} from "../../../tools/api-client";
import {FoundMeasurement} from "../found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class AssignTechnicianService {

  constructor(private readonly apiClient: ApiClient) {
  }

  assign(measurementId: number, technicianId: number, patientDataLines: string[]): Observable<FoundMeasurement> {
    return this.apiClient.put<FoundMeasurement>(
      `/api/measurements/${measurementId}/technician`,
      {
        technicianId: technicianId,
        patientDataLines: patientDataLines
      }
    )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
