import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";
import {
  UploadSession,
  CreateSessionRequest,
  MeasurementExistsResponse,
  UploadRequest,
  UploadInitiatedData
} from "./upload-model";
import { UploadContext } from "./upload.component";
import { map } from "rxjs/operators";

import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";

export interface UploadChunkRequest {
  sessionId: number;
  chunk: Blob;
  start: number;
  fileSize: number;
}

@Injectable()
export class UploadService {
  constructor(private apiClient: ApiClient) {
  }

  doesMeasurementExist(measurementCode: string): Observable<MeasurementExistsResponse> {
    return this.apiClient.get<MeasurementExistsResponse>(`/api/measurements/${measurementCode}/exists`);
  }

  upload(uploadData: UploadRequest): Observable<any> {
    return this.apiClient.post<any>("/api/measurements/upload", uploadData);
  }

  uploadInitiatedMeasurement(uploadInitiatedData: UploadInitiatedData): Observable<any> {
    return this.apiClient.put<any>("/api/measurements/initiated/upload", uploadInitiatedData);
  }

  createSession(fileUploadData: CreateSessionRequest): Observable<UploadSession> {
    return this.apiClient.post<any>("/api/measurements/upload/file", fileUploadData);
  }

  uploadChunk(uploadChunkRequest: UploadChunkRequest): Observable<void> {
    const formData = new FormData();
    formData.set("chunk", uploadChunkRequest.chunk);
    formData.set("start", uploadChunkRequest.start.toString());
    formData.set("fileSize", uploadChunkRequest.fileSize.toString());
    formData.set("sessionId", uploadChunkRequest.sessionId.toString());
    return this.apiClient.post<void>("/api/measurements/upload/file/chunk", formData);
  }

  getUploadContext(measurementId?: number): Observable<UploadContext> {
    return this.apiClient
      .get<UploadContext>(`/api/measurements/upload/context${measurementId ? `/${measurementId}` : ""}`)
      .pipe(map(uploadContext => recursiveNullToUndefined(uploadContext)));
  }
}
