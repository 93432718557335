import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs/internal/Observable";
import { Injectable } from "@angular/core";

export interface FoundComment {
  time: string;
  authorUsername: string;
  text: string;
}

export interface CommentRequest {
  text: string;
  measurementId: number;
}

@Injectable()
export class CommentsService {
  constructor(private apiClient: ApiClient) {

  }

  getComments(measurementId: number): Observable<FoundComment[]> {
    return this.apiClient.get<FoundComment[]>(`/api/measurements/comments/${measurementId}`);
  }

  addComment(comment: CommentRequest): Observable<void> {
    return this.apiClient.post<void>("/api/measurements/comments", comment);
  }
}
