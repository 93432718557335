import { Component, OnInit } from "@angular/core";
import { HospitalsService } from "./hospitals.service";
import { Hospital } from "../new-users/new-users";
import { NavigationRoute } from "../../tools/navigation/navigation-route";
import { RouteNavigator } from "../../tools/navigation/route-navigator.service";
import { Notifications, NotificationType } from "../../tools/notifications/notifications";
import { localizationKey } from "../../i18n/i18n-model";

@Component({
  selector: "app-hospitals",
  templateUrl: "./hospitals.component.html",
})
export class HospitalsComponent implements OnInit {
  hospitals?: Hospital[];

  constructor(private readonly hospitalsService: HospitalsService,
    private readonly routeNavigator: RouteNavigator,
    private readonly notifications: Notifications,

  ) {
  }

  ngOnInit(): void {
    this.hospitalsService.getHospitals()
      .subscribe((hospitals) => this.hospitals = hospitals,
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalsFailedToGet")));
  }

  edit(hospital: Hospital) {
    this.routeNavigator.navigateToPath([NavigationRoute.EDIT_HOSPITAL, hospital.id.toString()]);
  }
}
