import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {FoundMeasurement} from "./found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";

@Injectable()
export class CloseAnalysisService {

  constructor(private readonly apiClient: ApiClient) {
  }

  closeAnalysis(measurementId: number): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(
      `/api/measurements/${measurementId}/close-analysis`,
      null
    )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
