<div class="py-2">
  <div [ngClass]="{ row: optionsInRow }">
    <div [ngClass]="{ 'col-6': optionsInRow, 'row': optionsInRow }">
      <span [ngClass]="{ 'generator-title':true, 'col-6': optionsInRow, 'mr-auto': optionsInRow, 'mr-3':!optionsInRow }">{{ label | localize }}</span>
      <label data-testId="generation-option" *ngFor="let option of options" class="mr-2">
        <input
          type="radio"
          name="{{ name }}"
          (click)="radioButtonClicked(option)"
          [value]="option"
          [ngModel]="selectedOption"
          (ngModelChange)="selectOption(option)"
        />
        {{ option.optionLabel | localize }}
      </label>
    </div>
    <app-generator-text-field
      [ngClass]="{ 'col-6': optionsInRow, 'col-12': !optionsInRow, 'row': true }"
      [testId]="testId"
      [text]="generatedText"
      (onTextChange)="onGeneratedTextEdited($event)"
    >
    </app-generator-text-field>
  </div>
</div>
