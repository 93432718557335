import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FoundMeasurement} from "../found-measurement";
import {ApiClient} from "../../../tools/api-client";
import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

export interface ReassignDoctorRequest {
  doctorId: number;
}

@Injectable()
export class ReassignDoctorService {

  constructor(private readonly apiClient: ApiClient) {
  }

  reassign(measurementId: number, reassignDoctorRequest: ReassignDoctorRequest): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(
        `/api/measurements/${measurementId}/reassign`,
        reassignDoctorRequest
      )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
