import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LocalizationStrings} from "../../../../../../i18n/i18n-model";
import {stripNewlines} from "../strip-newlines";

export interface SelectionGeneratorOption {
  optionLabel: keyof LocalizationStrings;
  generatedText: string | undefined;
}

@Component({
  selector: "app-selection-generator",
  templateUrl: "./selection-generator.component.html",
})
export class SelectionGeneratorComponent {

  @Input()
    options?: SelectionGeneratorOption[];

  @Input()
    name?: string;

  @Input()
    label?: string;

  @Input("data-testId")
    testId?: string;

  @Input()
    optionsInRow?: boolean;

  @Output()
    onGenerate = new EventEmitter<string>();

  generatedText?: String = "";

  selectedOption?: SelectionGeneratorOption;

  selectOption(option?: SelectionGeneratorOption) {
    const generatedText = option ? option.generatedText : "";
    this.selectedOption = option;
    this.generatedText = generatedText;
    this.onGenerate.emit(generatedText);
  }

  radioButtonClicked(option: SelectionGeneratorOption) {
    if (this.selectedOption === option) {
      this.selectOption(undefined);
    }
  }

  onGeneratedTextEdited(editedText: string) {
    // noinspection JSPrimitiveTypeWrapperUsage: new String() is needed here so angular picks up the change
    this.generatedText = new String(stripNewlines(editedText));
    this.onGenerate.emit(this.generatedText.toString());
  }
}
