import {Component, Input, OnInit} from "@angular/core";
import {BloodPressureFindings} from "../bp-analysis";
import {BpFindingsModel} from "../../analysis";

@Component({
  selector: "app-blood-pressure-findings",
  templateUrl: "./blood-pressure-findings.component.html",
  styleUrls: ["./blood-pressure-findings.component.css"]
})
export class BloodPressureFindingsComponent implements OnInit {

  @Input() findings?: BpFindingsModel;

  constructor() { }

  ngOnInit(): void {
  }
}
