import { FormFieldConfig, FormFieldType, PropertyChangeListener } from "./form-field-config";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {FileOptions, SelectionOptions} from "./form-field.component";

export interface FormFieldConfigParams<T> {
  property: keyof T;
  type: FormFieldType;
  label: keyof LocalizationStrings;
  invalidLabel?: keyof LocalizationStrings;
  infoLabel?: keyof LocalizationStrings;
  required?: boolean;
  selectionOptions?: SelectionOptions<any>;
  fileOptions?: FileOptions;
  verticalLayout?: boolean;
  alignLabelRight?: boolean;
  readOnly?: boolean;
  filledByNurseOnly?: boolean;
  autoexpand?: boolean;
}

export class FormConfigBase<T> {
  constructor(
    private readonly model: T,
    private readonly propertyChangeListener?: PropertyChangeListener<T>,
    private readonly validationProvider?: (property: keyof T) => boolean | undefined
  ) {
  }

  protected createField(parameters: FormFieldConfigParams<T>): FormFieldConfig<T> {
    return {
      property: parameters.property,
      type: parameters.type,
      label: parameters.label,
      invalidLabel: parameters.invalidLabel,
      infoLabel: parameters.infoLabel,
      required: parameters.required,
      selectionOptions: parameters.selectionOptions,
      fileOptions: parameters.fileOptions,
      verticalLayout: parameters.verticalLayout,
      alignLabelRight: parameters.alignLabelRight,
      model: this.model,
      propertyChangeListener: this.propertyChangeListener,
      isValid: () => (this.validationProvider ? this.validationProvider(parameters.property) : true),
      readOnly: parameters.readOnly || false,
      filledByNurseOnly: parameters.filledByNurseOnly,
      autoexpand: parameters.autoexpand
    };
  }
}
