import {ClockService} from "./clock.service";
import {DateTime} from "luxon";

export class ClockServiceStub extends ClockService {

  stubTime?: DateTime;

  now(): DateTime {
    return this.stubTime || super.now();
  }
}
