import { LocalizationStrings } from "../../../i18n/i18n-model";
import { FormFieldConfig, translateFormValue } from "../../../tools/form/form-field-config";
import { getMeasurementTypeDisplayLocalizationKey, MeasurementType } from "../../measurement-models";
import { UploadFormModel } from "../../upload/upload-form-model";
import { createUploadFormConfig, Analysis } from "../analysis";
import { ReportValue } from "../report";
import { EcgReportModel } from "./ecg-report-model";

export class EcgReportModelFactory {
  constructor(
    private readonly ecgAnalysis: Analysis,
    private readonly reportDate: string,
    private readonly translate: (key: keyof LocalizationStrings) => string,
    private readonly dateTimeTransform: (date?: string) => string,
    private readonly userToken: string
  ) {}

  createEcgReportModel(): EcgReportModel {
    const config = createUploadFormConfig(this.ecgAnalysis);
    const disclaimer = this.createReportDisclaimer(this.ecgAnalysis.type);


    const symptomFindings: ReportValue[] = this.ecgAnalysis.patientData.symptoms.map((symptom, index) => ({
      label: `${this.translate("ecgReportLabelSymptom")} ${index + 1}: ${this.dateTimeTransform(symptom.time)}`,
      secondaryLabel: symptom.description || "",
      value: this.ecgAnalysis.doctorFindings.symptomFindings[index] || "",
    }));

    const patient = [this.createFormFieldValue(config.gender), this.createFormFieldValue(config.age)].filter((s) => s!).join(", ");

    return {
      uniqueId: this.reportValue("reportUniqueIdLabel", this.ecgAnalysis.uniqueId),
      measurementType: this.ecgAnalysis.type,
      measurementCode: this.reportValue("uploadFormLabelCode", this.ecgAnalysis.code),
      patient: this.reportValue("reportLabelPatient", patient),
      startTime: this.reportValue("analysisLabelStartTime", this.dateTimeTransform(this.ecgAnalysis.startTime)),
      patientStatusComments: this.reportValue("analysisLabelPatientStatusComment", this.ecgAnalysis.doctorFindings.patientStatusComments),
      findings: this.reportValue("analysisLabelFindings", this.ecgAnalysis.doctorFindings.findings),
      conclusion: this.reportValue("analysisLabelConclusion", this.ecgAnalysis.doctorFindings.conclusion),
      diagnosis: this.reportValue("analysisLabelDiagnosis", this.ecgAnalysis.doctorFindings.diagnosis),
      symptomFindings,
      reportDate: this.reportDate,
      doctor: {
        fullName: `${this.ecgAnalysis.doctor.firstName} ${this.ecgAnalysis.doctor.lastName}`.trim(),
        city: this.ecgAnalysis.doctor.city || "",
        title: this.ecgAnalysis.doctor.title || "",
      },
      patientData: {
        gender: this.patientDataReportValue(config.gender),
        age: this.patientDataReportValue(config.age),
        height: this.patientDataReportValue(config.height),
        weight: this.patientDataReportValue(config.weight),
        overweight: this.patientDataReportValue(config.overweight),
        smoking: this.patientDataReportValue(config.smoking),
        abnormalHeartbeats: this.patientDataReportValue(config.abnormalHeartbeats),
        slowPulse: this.patientDataReportValue(config.slowPulse),
        fastPulse: this.patientDataReportValue(config.fastPulse),
        palpitation: this.patientDataReportValue(config.palpitation),
        irregularPulse: this.patientDataReportValue(config.irregularPulse),
        dizziness: this.patientDataReportValue(config.dizziness),
        lossOfConsciousness: this.patientDataReportValue(config.lossOfConsciousness),
        blackouts: this.patientDataReportValue(config.blackouts),
        chestPain: this.patientDataReportValue(config.chestPain),
        shortnessOfBreath: this.patientDataReportValue(config.shortnessOfBreath),
        diagnosedHeartDisease: this.patientDataReportValue(config.diagnosedHeartDisease),
        currentMedication: this.patientDataReportValue(config.currentMedication),
        reasonForStudy: this.patientDataReportValue(config.reasonForStudy),
        patientFeedback: this.patientDataReportValue(config.patientFeedback),
        diary: this.patientDataReportValue(config.diary),
      },
      patientDataPageTitle: this.translate("reportPatientDataPageTitle"),
      findingsPageTitle: this.translate(getMeasurementTypeDisplayLocalizationKey({
        type: this.ecgAnalysis.type,
        isHome: false,
        actualDurationDays: this.ecgAnalysis.durationDays
      })),
      ecgAtrialFibrillationPageTitle: this.translate("ecgReportAtrialFibrillationTitle"),
      symptomFindingsTitle: this.translate("ecgReportSymptomFindingsTitle"),
      disclaimer,
      pageLabel: this.translate("reportPageLabel"),
      logoUrlPath: `api/measurements/${this.ecgAnalysis.id}/report/logo?token=${this.userToken}`,
      externalAnalysisUrlLabel: this.translate("externalAnalysisUrlLabel"),
      externalAnalysisUrl: this.ecgAnalysis.externalAnalysisUrl
    };
  }

  private createReportDisclaimer(type: MeasurementType): string {
    switch (type) {
      case "SYMPTOM_HOLTER":
        return this.translate("ecgReportSymptomHolterDisclaimer");
      case "ECG_ATRIAL_FIBRILLATION":
        return this.translate("ecgReportAtrialFibrillationDisclaimer");
      default:
        return "";
    }
  }

  private reportValue(label: keyof LocalizationStrings | undefined, value: string | undefined): ReportValue {
    return {
      label: label ? this.translate(label) : "",
      value: value || "",
    };
  }

  private patientDataReportValue(formFieldConfig: FormFieldConfig<UploadFormModel> | undefined): ReportValue {
    return this.reportValue(formFieldConfig?.label, this.createFormFieldValue(formFieldConfig));
  }

  private createFormFieldValue(formFieldConfig: FormFieldConfig<UploadFormModel> | undefined): string {
    if (formFieldConfig === undefined) {
      return "";
    }

    const formValue = translateFormValue(formFieldConfig, formFieldConfig.model[formFieldConfig.property], (key) =>
      this.translate(key));
    const infoLabelValue = formFieldConfig.infoLabel && !!formValue ? this.translate(formFieldConfig.infoLabel) : undefined;
    return [formValue, infoLabelValue].filter((s) => !!s).join(" ");
  }
}
