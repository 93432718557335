import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {LocalizationStrings} from "../../../../../../i18n/i18n-model";

@Component({
  selector: "app-value-generator",
  templateUrl: "./value-generator.component.html",
})
export class ValueGeneratorComponent implements OnInit {

  @Input()
    label?: keyof LocalizationStrings;

  @Input()
    value?: string;

  @Input()
    text?: string;

  @Output()
    onGenerate = new EventEmitter<string>();

  @Input("data-testId")
    testId?: string;

  constructor() { }

  ngOnInit(): void {
  }

  onGeneratedTextEdited(text: string) {
    this.text = text;
    this.onGenerate.emit(this.text);
  }
}
