import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {InitiatedMeasurementResponse, InitiateMeasurementFormModel} from "./initiate-measurement-data";

@Injectable()
export class OrderMeasurementService {

  constructor(private apiClient: ApiClient) {
  }


  orderMeasurement(initiateMeasurementModel: InitiateMeasurementFormModel): Observable<InitiatedMeasurementResponse> {
    return this.apiClient.post<InitiatedMeasurementResponse>("/api/measurements/order", initiateMeasurementModel);
  }

}
