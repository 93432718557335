<div
  [ngClass]="displayHorizontal ? 'row' : ''"
  [attr.data-testId]="formFieldConfig.property.toString() + '-display'"
>
  <div class="strong" [ngClass]="displayHorizontal ? 'col-6' : ''">{{ label || formFieldConfig.label | localize }}</div>
  <div class="multiline" [ngClass]="displayHorizontal ? 'col-4' : ''">
    <span>{{ getLocalizedFormValue() | localize }}</span>
    <span *ngIf="!!formFieldConfig.infoLabel" class="whitespace-prefix">{{ formFieldConfig.infoLabel | localize }}</span>
  </div>
</div>
