import { Injectable } from "@angular/core";
import { ApiClient } from "../../../tools/api-client";
import { Observable } from "rxjs";
import { FoundMeasurement } from "../found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../../tools/recursive-null-to-undefined";

@Injectable()
export class ReportService {
  constructor(private readonly apiClient: ApiClient) {}

  upload(measurementId: number, reportFile: File): Observable<FoundMeasurement> {
    return this.apiClient
      .put<FoundMeasurement>(
      `/api/measurements/${measurementId}/upload-report`,
      createMultipartRequest(reportFile)
    )
      .pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}

function createMultipartRequest(reportFile: File): FormData {
  const formData = new FormData();
  formData.append("reportFile", reportFile, reportFile.name);
  return formData;
}
