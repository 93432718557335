import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiClient } from "../../tools/api-client";
import { AddNursesResponse, Hospital } from "./new-users";

@Injectable()
export class NewUsersService {
  constructor(private apiClient: ApiClient) {}

  getHospitals(name: string): Observable<Hospital[]> {
    return this.apiClient.get<Hospital[]>("/api/hospitals", { name });
  }

  addNurses(hospital: string, emails: string[], orderingNurse: boolean = false): Observable<AddNursesResponse> {
    return this.apiClient.post<AddNursesResponse>("/api/admin/nurses", { hospital, emails, orderingNurse });
  }
}
