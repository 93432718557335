import {Injectable} from "@angular/core";

import { AllowedHospitalMeasurementTypes } from "../hospital.details";

@Injectable()
export class AllowedTypesSectionState {

  hospitalId: number | undefined;
  hospitalName: string | undefined;
  allowedHospitalMeasurementTypes: AllowedHospitalMeasurementTypes | undefined;
}

