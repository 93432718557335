import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiClient} from "../../tools/api-client";
import {FoundMeasurement} from "./found-measurement";
import {map} from "rxjs/operators";

import {recursiveNullToUndefined} from "../../tools/recursive-null-to-undefined";

@Injectable()
export class DoctorAssignmentService {
  constructor(private readonly apiClient: ApiClient) {
  }

  assignDoctor(
    measurementId: number,
    doctorId: number | undefined,
    patientDataLines: string[]
  ): Observable<FoundMeasurement> {
    return this.apiClient.put<FoundMeasurement>(
      `/api/measurements/${measurementId}/doctor`,
      {
        doctorId: doctorId,
        patientDataLines: patientDataLines
      }
    ).pipe(map(listItem => recursiveNullToUndefined(listItem)));
  }
}
