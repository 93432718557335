export function formatUploadPercentageStyleWidth(uploadedPercentage: number) {
  return formatUploadPercentage(uploadedPercentage);
}

export function formatUploadPercentageText(uploadedPercentage: number) {
  return formatUploadPercentage(uploadedPercentage);
}

function formatUploadPercentage(uploadedPercentage: number) {
  return uploadedPercentage.toFixed(0) + "%";
}
