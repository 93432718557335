export function formatDuration(seconds: number, locale: Intl.LocalesArgument) {

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  const remainingSeconds = (seconds % 60)
    .toLocaleString(
      locale,
      {maximumFractionDigits: seconds < 10 ? 1 : 0}
    );

  return [
    hours > 0 ? `${hours} h` : undefined,
    minutes > 0 || hours > 0 ? `${minutes} min` : undefined,
    `${remainingSeconds} s`
  ]
    .filter(segment => segment !== undefined)
    .join(" ");
}
